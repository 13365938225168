<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="false"  />
</template>

<script>
    import ArForm from "../../../../components/form/ArForm";

    export default {
      name: "qlrInfo_Edit",
      inject:["arguments"],
      components:{
        ArForm
      },
      data(){
          return{

          }
      },
      computed:{
        argument(){
          return  this.arguments.value;
        },
      },
      methods:{
        getParms(){
            let data = this.$refs.bill_form.getSource();
            return data;
          }
      }
    }
</script>

<style scoped>

</style>