/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class department_user_item extends Table{
    constructor() {
        super();
        this.name = "department_user_item";
        this.CNName = "部门用户信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("用户姓名","name",ColumnType.String,true).setTableView(),
            new Column("手机号","phone",ColumnType.String,true)
                .setTriggerOtherColumn(null,
                    null,
                    (formData,cols)=>{formData["loginAccount"] = formData["phone"]}),
            new Column("登录账号","loginAccount",ColumnType.String,false).setTableView(),
            new Column("性别","sex",ColumnType.String,true)
                .setEnum(["0","1"],
                    ["女","男"],
                    ["orange","blue"]),
            new Column("登录状态","loginState",ColumnType.String,true)
                .setEnum(["0","1"],
                    ["未登陆","登陆中"],
                    ["purple","green"]).setDefaultValue("0"),
            new Column("禁用状态","disableState",ColumnType.String,true)
                .setEnum(["0","1"],
                    ["禁用","启用"],
                    ["red","blue"]).setDefaultValue("1"),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(150)];
    }
}