<!--
* @description：家庭成员编辑弹框
* @author:rzl
* @date:2022/1/24 15:11
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
           >

    <a-form-item >
      <a-tag color="#2db7f5"  @click="openUserTable">查询用户数据</a-tag>
    </a-form-item>

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="flag"  />

    <template #footer>
      <a-button v-if="!flag" type="primary" key="back" @click="add">确定</a-button>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>

  </a-modal>

  <!-- 人员搜索：房地一体权利人；资格名录库 -->
  <user-table-tk ref="userTableTk" @event="userTk"/>
</template>

<script>
import ArForm from "../../../../../components/form/ArForm";

import userTableTk from "./userTableTk";
export default {
  name: "jtcyEditTk",
  components:{
    ArForm,userTableTk
  },
  data(){
    return{
      /**弹框家庭成员**/
      visible:false,
      modalTitle:null,
      argument:null,
      flag:false,
    }
  },
  methods:{
    openJtcyEdit(argument){
      this.visible = true;
      this.modalTitle = true;
      this.argument = argument;
    },
    add(){
      this.visible = false;
      this.$emit("event",this.$GetEmitData(this.$eventTags.add,this.argument));
    },
    openUserTable(){
      this.$refs.userTableTk.openUserTable();
    },
    userTk({type,arg}){
      switch (type){
        case this.$eventTags.user_zgmlk:{
          this.argument.formData = arg;
          this.argument.formData.qualificationId = arg.id;
          this.argument.formData.id = null;
          break;
        }
        case this.$eventTags.user_fdyt:{
          this.argument.formData ={
            obligeeId:arg.id,
            villageId:arg.villageId,
            villageName:arg.villageName,
            name:arg.qlrmc,
            idCard:arg.zjh,
            sex:arg.xb,
            age:null,
            phone:arg.dh,

          }
          break;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>