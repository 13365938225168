/**
 * @description：eventTags  用于标记Event事件的type，避免直接使用string 导致打错
 * @author:rzl
 * @date:2022/1/4 11:09
 **/
function EventTags(){
    this.add = "add";                       // 新增
    this.add_zjd = "add_zjd";               // 新增宅基地
    this.edit = "edit";                     // 编辑
    this.delete = "delete";                 // 删除
    this.search = "search";                 // 查询
    this.refresh = "refresh";               // 刷新
    this.refresh2 = "refresh2";               // 刷新2
    this.add_save = "add_save";             // 新增保存
    this.edit_save = "edit_save";           // 编辑保存
    this.approve = "approve";               // 通过
    this.reject = "reject";                 // 未通过
    this.downloads = "downloads";           // 下载
    this.batchImport = "batchImport";       // 批量导入
    this.revoked = "revoked";               // 撤销

    this.useData = "useData";               // 使用数据

    /** 地图模块 **/
    this.query_cl = "query_cl";             // 测量
    this.remove_cl = "remove_cl";           // 清除测量
    this.click_toop = "click_toop";         // 清除绘制、提示信息
    this.zd_cxfx = "zd_cxfx";               // 宗地查询分析
    this.remove_zd_cxfx = "remove_zd_cxfx"; // 宗地查询分析清除
    this.hgfx = "hgfx";                     // 合规分析
    this.shp_fx = "shp_fx";                 // 上传shp分析
    this.deleteChange = "deleteChange";     // 清除地图上添加元素
    this.show_zjd_info = "show_zjd_info";   // 展示宗地信息
    this.wkt_show = "wkt_show";             // wkt显示
    this.query_text = "query_text";         // 查询条件

    /** 用户模块 **/
    this.user_zgmlk = "user_zgmlk";         // 用户：资格名录库
    this.user_fdyt = "user_fdyt";           // 用户：房地一体
}

let tags = new EventTags();
export default tags;
