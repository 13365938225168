<!--
*@description：宅基地审核主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
<div class="main-css">
  <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
    <!-- 搜索栏 start -->
    <zjd-audit-tool-bar  ref="toolBar" @event="eventHandle" />
    <!-- 搜索栏 end -->

    <!-- table start -->
    <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 1500,y:650}">
      <template v-slot:action="{index,record}">
        <div style="justify-content: center;;display: flex;">
          <!-- 村委会 -->
          <div v-if="departmentType == '1.0'">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定受理吗?"
                okText="确定"
                cancelText="取消"
                @confirm="acceptDetail(index,record)"
                v-if="record.auditStatus == '1.0'"
            >
              <a-button type="link">
                <template #icon><Accept title="受理"/></template>
              </a-button>
            </a-popconfirm>

            <a-button type="link" @click="DocumentationTk_cwh(index,record)"
                      v-if="record.auditStatus != '2.2' && record.auditStatus != '3.2' &&  record.auditStatus != '5.2'">
              <template #icon><Documentation title="宅基地申请表"/></template>
            </a-button>

            <a-button type="link" @click="scFile(index,record)"
                      v-if="record.auditStatus == '2.0' || record.auditStatus == '3.0'">
              <template #icon><UploadFile title="上传文件"/></template>
            </a-button>

            <a-button type="link" @click="auditDetail(index,record)"
                      v-if="record.auditStatus == '3.0'">
              <template #icon><Read title="公示审核"/></template>
            </a-button>

            <a-button type="link" @click="auditDetail(index,record)"
                      v-if="record.auditStatus == '2.0'">
              <template #icon><Audit title="审核"/></template>
            </a-button>

          </div>

          <!-- 乡镇 -->
          <div v-if="departmentType == '2.0'">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定受理吗?"
                okText="确定"
                cancelText="取消"
                @confirm="acceptDetail(index,record)"
                v-if="record.auditStatus == '4.0'"
            >
              <a-button type="link">
                <template #icon><Accept title=" 受理"/></template>
              </a-button>
            </a-popconfirm>

            <a-button type="link" @click="DocumentationTk(index,record)"
                      v-if="record.auditStatus != '2.2' && record.auditStatus != '3.2' &&  record.auditStatus != '5.2'">
              <template #icon><Documentation title="生成文档"/></template>
            </a-button>

            <a-button type="link" @click="scFile(index,record)"
                      v-if="record.auditStatus == '5.0' || record.auditStatus == '5.1'">
              <template #icon><UploadFile title="上传文件"/></template>
            </a-button>


            <a-button type="link" @click="auditDetail(index,record)"
                      v-if="record.auditStatus == '5.0' ">
              <template #icon><Audit title="审核"/></template>
            </a-button>

            <a-button type="link" @click="auditXjDetail(index,record)">
              <template #icon><ClusterOutlined title="下级部门审核记录"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定完结吗?"
                okText="确定"
                cancelText="取消"
                @confirm="archive(index,record)"
                v-if="record.auditStatus == '5.1'"
            >
              <a-button type="link">
                <template #icon><CheckCircle title="完结"/></template>
              </a-button>
            </a-popconfirm>
          </div>

          <!-- 下级部门 -->
          <div v-if="departmentType != '1.0' && departmentType != '2.0'">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="auditDetail(index,record)"
                      v-if="record.auditStatus == '5.3' ">
              <template #icon><Audit title="审核"/></template>
            </a-button>
          </div>



        </div>
      </template>
    </ArBaseTable>
    <!-- table end -->
  </div>

  <!-- 编辑页面 -->
  <drawer-component ref="local_tabs" @event="eventHandle"/>

  <!-- 宅基地编辑页面 -->
  <drawer-component-zjd ref="local_tabs_zjd" @event="eventHandle"/>

  <!-- 审核信息 -->
  <audit-tk ref="audit_tk" @event="eventHandle"/>

  <!-- 下级审核信息 -->
  <xjsh-table ref="xjsh_table" @event="eventHandle"/>

  <!-- 上传文件 -->
  <file-edit-tk  ref="fielEditTk" />

  <!-- 生成文档 -->
  <documentation-tk ref="documentationTk"/>
</div>
</template>

<script>
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import drawerComponent from "./components/drawerComponent";
import zjdAuditToolBar from "./components/zjdAuditToolBar";
import auditTk from "../../common/point/auditTk";
import xjshTable from "../common/point/sh/xjshTable";
import documentationTk from "../common/point/wd/documentationTk";
import drawerComponentZjd from "./components/drawerComponentZjd";

import zjdAudit_api from "./config/zjdAudit_api";
import zjdApply_item from "../common/config/zjd/zjdApply_item";
import file_api from "../../common/config/file_api";
import wd_api from "../common/config/wd/wd_api";
import fileEditTk from "../common/point/file/fileEditTk";

export default {
  name: "zjdAudit_main",
  mixins:[Icons],
  components:{
    zjdAuditToolBar,ArBaseTable,drawerComponent,auditTk,xjshTable,fileEditTk,documentationTk,
    drawerComponentZjd
  },
  data(){
      return{
        argument:{
          dataSource:null,//提供table的DataSource
          tableColumns:new zjdApply_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
          loading:false,//table的loading状态
          currentIndex:null,
          formColumns:new zjdApply_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
          formData:{},
        },
        pageParam:{
          page: 1,
          limit: 10,
        },
        departmentType:null,
      }
  },
  created() {
    this.init()
  },

  methods:{
    init(){
      const userInfo = this.$store.getters.userInfo;
      this.departmentType = userInfo.departmentType;

      let parms = {};
      this.argument.loading = true;
      zjdAudit_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
          if(res.data.flag){
            this.argument.dataSource = res.data.data.resultList;
            this.$refs.pstable.setTotalSize(res.data.data.total);
          }
      });

    },
    eventHandle({type,arg}){
        switch (type) {
            case this.$eventTags.search:{
                this.argument.loading = true;
              zjdAudit_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:arg,formParam:this.pageParam},res=>{
                  this.argument.loading =false;
                  if(res.data.flag){
                    this.argument.dataSource = res.data.data.resultList;
                    this.$refs.pstable.setTotalSize(res.data.data.total);
                  }
                });
                break;
            }
            case this.$eventTags.refresh:{
              this.$refs.local_tabs.onClose();
              this.init();
              break;
            }
          case this.$eventTags.approve:{
            let parms = {
              auditOpinion:arg.auditOpinion,
              id:arg.id,
            }
            zjdAudit_api.approve.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                this.$message.success(res.data.msg);
                this.$refs.audit_tk.closeAudit();
                this.init();
              }
              else{
                this.$message.error(res.data.msg);
              }
            })
            break;
          }
          case this.$eventTags.reject:{
            if(arg.auditOpinion == null || this.auditOpinion == ''){
              this.$message.error('请输入审核意见');
              return;
            }
            let parms = {
              auditOpinion:arg.auditOpinion,
              id:arg.id,
            }
            zjdAudit_api.reject.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                this.$message.success(res.data.msg);
                this.$refs.audit_tk.closeAudit();
                this.init();
              }
              else{
                this.$message.error(res.data.msg);
              }
            })
            break;
          }
        }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      zjdAudit_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      if(arg.applyType == '1'){
        this.argument.formData = arg;
        this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
      }else if(arg.applyType == '2'){
        this.argument.formData = arg;
        this.$refs.local_tabs_zjd.openDrawer("查看信息","zjd_show",false, this.argument);
      }
    },
    acceptDetail(index,arg){
      let parms = {
        id:arg.id
      }
      zjdAudit_api.accept.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
        }else{
          this.$message.error(res.data.msg);
        }

      })
    },
    auditDetail(index,arg){
      this.$refs.audit_tk.openAudit(arg.id);
    },
    DocumentationTk_cwh(index,arg){
      let parms = {
        applyId:arg.id,
      };
      wd_api.applicationGenerate.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let data = {
            webPath:res.data.data.path
          };
          file_api.downloadByWebPath.fileDownload(this,data,'宅基地申请表');
        }

      });
    },
    DocumentationTk(index,arg){
      this.$refs.documentationTk.openDocumentationTk(arg);
    },
    archive(index,arg){
      let parms = {
        id:arg.id
      }
      zjdAudit_api.finish.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
        }else{
          this.$message.error(res.data.msg);
        }

      })
    },
    auditXjDetail(index,arg){
      this.$refs.xjsh_table.openXjshTable(arg.id);
    },
    scFile(index,arg){
      this.$refs.fielEditTk.openFile(arg.id,"zjd_apply",true);
    },
  },

}
</script>

<style scoped>

</style>