<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="false"  />
</template>

<script>
    import ArForm from "../../../../components/form/ArForm";

    export default {
      name: "dict_edit",
      inject:["arguments"],
      components:{
        ArForm
      },
      computed:{
        argument(){
          return  this.arguments.value;
        },
      },
      methods:{
        getParms(){
              let data = this.$refs.bill_form.getSource();
              data.userId = this.$store.getters.userInfo.id;
              return data;
          },
      }
    }
</script>

<style scoped>

</style>