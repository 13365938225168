/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class department_c_citem extends Table{
    constructor() {
        super();
        this.name = "department_c_citem";
        this.CNName = "子部门信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("父级部门类型","dectId",ColumnType.String,false).setVisible(false),
            new Column("部门pid","pid",ColumnType.String,false).setVisible(false),
            new Column("子部门名称","name",ColumnType.String,true).setTableView(),
            new Column("子部门类型","type",ColumnType.Dictionary_c,true).setTableView()
                .setDictionary_c("dectId"),
            new Column("备注","remarks",ColumnType.String,true),


        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}