/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../../assets/utils/APIObject";

const api={
    downloadPlanAnalyseByWkt:new APIObject("/api/zAnalyse/downloadPlanAnalyseByWkt.do"),// 宅基地统计分析-wkt合规分析报告下载（以wkt面直接进行合规分析）
    planAnalyseByFLandPlotsId:new APIObject("/api/zAnalyse/planAnalyseByFLandPlotsId.do"),// 宅基地统计分析-房地一体宗地合规分析
    planAnalyseByApplyId:new APIObject("/api/zAnalyse/planAnalyseByApplyId.do"),// 宅基地统计分析-宅基地申请合规分析
    downloadPlanAnalyseByFLandPlotsId:new APIObject("/api/zAnalyse/downloadPlanAnalyseByFLandPlotsId.do"),// 宅基地统计分析-房地一体宗地合规分析报告下载
    planAnalyseHomeByWkt:new APIObject("/api/zAnalyse/planAnalyseHomeByWkt.do"),// 宅基地统计分析-首页wkt合规分析（以wkt面查询出宅基地/房地一体后进行合规分析），地图范围分析
    planAnalyseByWkt:new APIObject("/api/zAnalyse/planAnalyseByWkt.do"),// 宅基地统计分析-wkt合规分析（以wkt面直接进行合规分析），单宗地分析
    downloadPlanAnalyseByZLandPlotsId:new APIObject("/api/zAnalyse/downloadPlanAnalyseByZLandPlotsId.do"),// 宅基地统计分析-宅基地宗地合规分析报告下载
    planAnalyseByZLandPlotsId:new APIObject("/api/zAnalyse/planAnalyseByZLandPlotsId.do"),// 宅基地统计分析-宅基地宗地合规分析
    downloadPlanAnalyseByApplyId:new APIObject("/api/zAnalyse/downloadPlanAnalyseByApplyId.do"),// 宅基地统计分析-宅基地申请合规分析报告下载

};

export default api;