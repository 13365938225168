/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class menu_item extends Table{
    constructor() {
        super();
        this.name = "menu_item";
        this.CNName = "菜单信息信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
           /* new Column("根节点","pid",ColumnType.String,true),*/
            new Column("菜单标题","title",ColumnType.String,true).setTableView(),
            new Column("模块标识","key",ColumnType.String,true).setTableView(),
            new Column("菜单所属权","permissions",ColumnType.String,true).setTableView(),
            new Column("图标icons","icons",ColumnType.String,true),
            new Column("组件标识","component",ColumnType.String,true),
            new Column("重定向","redirect",ColumnType.String,true),
            new Column("序号","indexNo",ColumnType.Number,true),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}