<!--
* @program: tyh-oa 
* @author: ly
* @description: typeString 
* @create: 2021-03-23 10:01
-->
<template>
    <div class="data-present">
        <a-textarea
                @input="columnTrigger"
                auto-size
                v-if="editable"
                v-model:value="record[column.dataIndex]"
        />
        <div v-else style="width: 100%;text-align: center;">
<!--            <OverSizeCol :record="record" :column="column" />-->
          {{presentValue}}
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";


    export default {
        name: "typeString",
        mixins:[typeBase],
    }
</script>

<style scoped>

</style>