<!--
*@description：用户主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div class="main-css">
      <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
        <!-- 搜索栏 start -->
        <user-tool-bar ref="toolBar" @event="eventHandle" />
        <!-- 搜索栏 end -->

        <!-- table start -->
        <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showData(index,record)">
                <template #icon><Show title="查看"/></template>
              </a-button>

              <a-button type="link" @click="editData(index,record)">
                <template #icon><Edit title="编辑"/></template>
              </a-button>

              <a-button type="link" @click="userRole(index,record)">
                <template #icon><Authorize title="授权角色"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">

                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ArBaseTable>
        <!-- table end -->
      </div>

      <!-- 编辑页面 -->
      <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>


  <!--角色授权 start-->
  <a-modal v-model:visible="visible_role"
           :title="modalTitle_role" width="35%" style="overflow-y: auto"
           :destroyOnClose="true">

    <div style="height: 500px;overflow-y: auto">
      <a-tree
          checkable
          :tree-data="treeData"
          :blockNode="true"
          :defaultExpandAll="true"
          v-model:checkedKeys="checkedKeys"
      />
    </div>


    <template #footer>
      <a-button key="back" @click="visible_role =false">取消</a-button>
      <a-button key="submit" type="primary" @click="submit" >提交</a-button>
    </template>
  </a-modal>
  <!--角色授权 end-->
</template>

<script>
import userToolBar from "./components/userToolBar";
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import drawerComponent from "./components/drawerComponent";

import user_item from "./config/user_item";
import user_api from "./config/user_api"

export default {
  name: "userMain",
  mixins:[Icons],
  components:{
    ArBaseTable,userToolBar,drawerComponent
  },
  data(){
      return{
        argument:{
          dataSource:null,//提供table的DataSource
          tableColumns:new user_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
          loading:false,//table的loading状态
          currentIndex:null,
          formColumns:new user_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
          formData:{},
        },
        pageParam:{
          page: 1,
          limit: 10,
        },

        treeData:[],
        userId:null,
        visible_role:false,
        modalTitle_role:null,
        checkedKeys:[],
        check_tree:[],
      }
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      /**树**/
      user_api.findRoleByUser.requestPOST(this,null,res=>{
        if(res.data.flag){
          let data = res.data.data;
          /**结果封装成树**/
          this.treeData = this.$common.convertToTreedata(data);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
      let info = this.$cookies.get("userInfo");
      let parms = {};
      this.argument.loading = true;
      user_api.queryPageByDepartment.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });

    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          let parms ={
            queryText:arg.queryText
          }
          user_api.queryPageByDepartment.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add :{
          this.argument.formData = {};
          this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
          break;
        }
      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      user_api.queryPageByDepartment.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      user_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    userRole(index,arg){
      this.checkedKeys = [];
      this.userId = arg.id

      user_api.queryAll_role_user.requestPOSTUrlParam(this,{userId:arg.id},res=>{
        this.visible_role = true;
        this.modalTitle_role = arg.name + ":授权角色"
        if(res.data.flag){
          let data = res.data.data;
          if(data != null && data.length > 0){
            for(let i=0;i<data.length;i++){
              this.checkedKeys.push(data[i].roleId)

            }
          }
        }

      });
    },
    submit(){
      if(this.checkedKeys == null || this.checkedKeys.length == 0){
        this.$message.error("请选择要授权的角色");
        return;
      }
      let userRoleRelationVOList = [];
      let checked_role = this.checkedKeys;
      for(let i=0;i<checked_role.length;i++){
        let data = {
          roleId:checked_role[i],
          userId:this.userId,
        }

        userRoleRelationVOList.push(data)
      }


      let parms = {
        userRoleRelationVOList:userRoleRelationVOList
      }
      user_api.batchBinding_role.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.visible_role = false;
          this.$message.success(res.data.msg);
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },

  },
}
</script>

<style scoped>

</style>