<!--
* @description：
* @author:rzl
* @date:2022/1/20 16:19
-->

<template>
  <div id="layerContent" class="layerContent" :style="layer_content_style">
       <span role="img" aria-label="close-circle" class="anticon anticon-close-circle" style="padding-left: 97%;">
          <a  @click="gb_zjd_content" title="关闭">
          <svg focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor"
               aria-hidden="true" viewBox="64 64 896 896"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512
               465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9
               5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5
               515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448
               448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372
               372-166.6 372-372 372z"></path>
          </svg></a>
        </span>

    <a-tabs v-model:activeKey="activeKey_content" tab-position="left" @change="zjd_info_find" tabBarStyle="color:#fafafa">

      <a-tab-pane key="1" tab="宅基地基本信息" class="tabClass" v-if="zjdInfo!==null" style="text-align: left;">
        <a-row >
          <a-col :span="6">
            <a-form-item label="所属村名" >
              {{zjdInfo.villageName}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="现宅基地面积" >
              {{zjdInfo.currentHomesteadArea}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="现建筑面积" >
              {{zjdInfo.currentBuildingArea}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="现权属证书号" >
              {{zjdInfo.currentCertificateNo}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item label="现宅基地处置情况" >
              {{zjdInfo.currentHomesteadHandleType}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="现宅基地保留面积" >
              {{zjdInfo.currentHomesteadKeepArea}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="拟申请宅基地面积" >
              {{zjdInfo.homesteadArea}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="拟申请宅基地占地面积" >
              {{zjdInfo.homesteadGroundArea}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item label="东至" >
              {{zjdInfo.east}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="西至" >
              {{zjdInfo.west}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="南至" >
              {{zjdInfo.south}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="北至" >
              {{zjdInfo.north}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item label="地类" >
              {{zjdInfo.category}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="建房类型" >
              {{zjdInfo.buildType}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="建筑面积" >
              {{zjdInfo.buildingArea}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="建筑层数" >
              {{zjdInfo.buildingFloor}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item label="建筑高度" >
              {{zjdInfo.buildingHeight}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="是否征求相邻权利人意见" >
              {{zjdInfo.enquireNeighbor}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="申请理由" >
              {{zjdInfo.applyReason}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item label="地址" >
              {{zjdInfo.address}}
            </a-form-item>
          </a-col>
        </a-row>

      </a-tab-pane>
      <a-tab-pane key="2" tab="户主信息" class="tabClass2" v-if="hz!==null" style="text-align: left;" >
        <a-row>
          <a-col :span="6">
            <a-form-item label="户主姓名" >
              {{hz.householderName}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="证件号码" >
              {{hz.idCard}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="性别" >
              {{hz.sex}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="年龄" >
              {{hz.age}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item label="权利比例" >
              {{hz.qlbl}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="共有方式" >
              {{hz.gyfs}}
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="共有情况" >
              {{hz.gyqk}}
            </a-form-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col>
            <a-form-item label="户口所在地" >
              {{hz.householdRegister}}
            </a-form-item>
          </a-col>
        </a-row>

      </a-tab-pane>
      <a-tab-pane key="3" tab="宅基地文件信息" class="tabClass3" >
        <a-tabs v-model:activeKey="activeKey_file" tab-position="top" tabBarStyle="color:#fafafa;margin-top:-15px" >

          <a-tab-pane key="1" tab="宅基地申请材料"  class="tabClass_file">
            <ar-base-table ref="pstable_sqcl" :page-size="pageParam.limit"  :argument="argument_sqcl" :view-model="true" :scroll="{ x: 700,y:400}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="downloadFile(index,record)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </template>
            </ar-base-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="不动产权籍调查成果"  class="tabClass_file">
            <ar-base-table ref="pstable_bdc" :page-size="pageParam.limit"  :argument="argument_bdc" :view-model="true" :scroll="{ x: 700,y:400}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="downloadFile(index,record)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </template>
            </ar-base-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="申请人身份证明"   class="tabClass_file">
            <ar-base-table ref="pstable_qlr" :page-size="pageParam.limit"  :argument="argument_qlr" :view-model="true" :scroll="{ x: 700,y:400}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="downloadFile(index,record)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </template>
            </ar-base-table>
          </a-tab-pane>
          <a-tab-pane key="4" tab="村级审批材料"   class="tabClass_file">
            <ar-base-table ref="pstable_cwh_sh" :page-size="pageParam.limit"  :argument="argument_cwh_sh" :view-model="true" :scroll="{ x: 700,y:400}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="downloadFile(index,record)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </template>
            </ar-base-table>
          </a-tab-pane>
          <a-tab-pane key="5" tab="乡镇审批材料"   class="tabClass_file">
            <ar-base-table ref="pstable_xz_sh" :page-size="pageParam.limit"  :argument="argument_xz_sh" :view-model="true" :scroll="{ x: 700,y:400}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="downloadFile(index,record)">
                    <template #icon><Download title="下载"/></template>
                  </a-button>
                </div>
              </template>
            </ar-base-table>
          </a-tab-pane>
        </a-tabs>


      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";

import file_api from "../../common/config/file_api";
import tdt_api from "../config/tdt_api";
import file_item from "../../common/config/file_item";
import dic_api from "../../sys/dictionary/config/dic_api";

export default {
  name: "zjdShow",
  mixins:[Icons],
  components:{
    ArBaseTable
  },
  data(){
    return{
      /** 宅基地单个信息 **/
      layer_content_style:{
        display:"none"
      },
      activeKey_content:'1',
dicName:null,
      /** 宅基地信息 **/
      zjdInfo:{
        villageName:null,//所属村名
        currentHomesteadArea:null,//现宅基地面积
        currentBuildingArea:null,//现建筑面积
        currentCertificateNo:null,//现权属证书号
        currentHomesteadHandleType:null,//现宅基地处置情况
        currentHomesteadKeepArea:null,//现宅基地保留面积
        homesteadArea:null,//拟申请宅基地面积
        homesteadGroundArea:null,//拟申请宅基地占地面积
        address:null,//地址
        east:null,//东至
        west:null,// 西至
        south:null,//南至
        north:null,//北至
        category:null,//地类
        buildType:null,//建房类型
        buildingArea:null,//建筑面积
        buildingFloor:null,//建筑层数
        buildingHeight:null,//建筑高度
        enquireNeighbor:null,//是否征求相邻权利人意见
        applyReason:null,//申请理由
      },

      /** 权利人信息 **/
      data_qlr:null,
      hz:{
        householderName:null,
        idCard:null,
        sex:null,
        age:null,
        qlbl:null,
        gyfs:null,
        gyqk:null,
        householdRegister:null,
      },

      /** 宅基地文件信息 **/
      activeKey_file:'1',
      argument_sqcl:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_bdc:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_qlr:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_cwh_sh:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_xz_sh:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      pageParam:{
        page: 1,
        limit: 10,
      },

    }
  },
  methods:{
    openZjdShow(zdId,applyId){
      this.zjdInfo = null;
      this.hz = null;
      this.activeKey_content = "1";
      this.layer_content_style = {
        "display":"block"
      }
      /* this.activeKey_content = '1';*/
      // 查询宗地基本信息
      tdt_api.get_zapply.requestPOSTUrlParam(this,{id:applyId},res=>{
        if(res.data.flag){
          this.zjdInfo = res.data.data.zApply;
          this.dicInfo(this.zjdInfo.currentHomesteadHandleType,"current_homestead_handle_type");
          this.dicInfo(this.zjdInfo.category,"homestead_category");
          this.dicInfo(this.zjdInfo.enquireNeighbor,"enquire_neighbor");
          let parms = {
            geom:res.data.data.zApply.geom,
          };
          // 定位到选中的宅基地
          let data = this.$GetEmitData(this.$eventTags.wkt_show,parms);
          this.$emit("event",data);
        }
      });

      // 户主信息
      tdt_api.get_zapply.requestPOSTUrlParam(this,{id:applyId},res=>{
        if(res.data.flag){
          this.hz = res.data.data.zApply;
          this.dicInfo(this.hz.sex,'XB');
          this.dicInfo(this.hz.gyfs,"GYFS");
        }
      });

      // 文件信息
      let file_data = {
        relateTable:'zjd_land_plots',
        relateId:zdId
      }
      file_api.queryGroup.requestPOSTUrlParam(this,file_data,res=>{
        if(res.data.flag){
          let info_list = res.data.data;
          if(info_list.length > 0){
            for(let i=0;i<info_list.length;i++){
              let info = info_list[i];
              if(info.title == '宅基地申请材料'){
                 this.argument_sqcl.dataSource = info.fileList;
              }
              if(info.title == '不动产权籍调查成果'){
                this.argument_bdc.dataSource = info.fileList;
              }
              if(info.title == '申请人身份证明'){
                this.argument_qlr.dataSource = info.fileList;
              }
              if(info.title == '村级审批材料'){
                this.argument_cwh_sh.dataSource = info.fileList;
              }
              if(info.title == '乡镇审批材料'){
                this.argument_xz_sh.dataSource = info.fileList;
              }
            }
          }
        }
      });

    },
    /** 关闭content **/
    gb_zjd_content(){
      this.layer_content_style = {
        "display":"none"
      }
    },
    /** 宅基地信息页签 **/
    zjd_info_find(){
      if(this.activeKey_content == null){
        return;
      }

      if(this.activeKey_content == '1'){
        // 宅基地基本信息
      }else if(this.activeKey_content == '2'){
        // 宅基地权利人
      }else if(this.activeKey_content == '3'){
        // 宅基地文件信息
      }

    },
    downloadFile(index,arg){
      let data = {
        webPath:arg.path
      };
      file_api.downloadByWebPath.fileDownload(this,data,arg.name);
    },
    async dicInfo(str,dicType){
      let dictName = "";
      let info = await dic_api.queryByNameCode.requestPOSTAsync({nameOrCode:dicType});
      if(info.flag){
        let dictItem = info.data.children;
        if(dictItem.length > 0){
          for(let i=0;i<dictItem.length;i++){
            if(str == dictItem[i].dictCode){
              dictName =  dictItem[i].dictName;
            }
          }
        }
      }
      else{
        this.$message.error(info.msg)
      }

      switch (dicType){
        case "current_homestead_handle_type":{
          this.zjdInfo.currentHomesteadHandleType = dictName;
          break;
        }
        case "homestead_category":{
          this.zjdInfo.category = dictName;
          break;
        }
        case "enquire_neighbor":{
          this.zjdInfo.enquireNeighbor = dictName;
          break;
        }
        case "XB":{
          this.hz.sex = dictName;
          break;
        }
        case "GYFS":{
          this.hz.gyfs = dictName;
          break;
        }
      }

    },
  },

}
</script>

<style scoped>
.layerContent{
  width: 90%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 1;
  position: absolute;
  bottom: 1px;
  height: 35%;

}
.tabClass{
  overflow-y: auto;
  height: 290px;
}
.tabClass2{
  overflow-y: auto;
  height: 290px;
  margin-top: -290px;
}

.tabClass3{
  overflow-y: auto;
  height: 290px;
  margin-top: -290px;
}
.tabClass_file{
  overflow-y: auto;
  height: 240px;
}
</style>