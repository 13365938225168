import { createApp } from 'vue';

import {message } from 'ant-design-vue';
import andt from 'ant-design-vue';
import App from "./App";
import 'ant-design-vue/dist/antd.css';
import "./assets/main.css"
import {createRouter,createWebHashHistory} from "vue-router"
import columnType from "./assets/tables/parts/column_type"
import {GetEmitData} from "./assets/utils/general";
import VueCookies from 'vue3-cookies'
import { createStore } from 'vuex'
import moment from "moment";
import config from '../package.json'

import ht_login from "./view/ht_login";
import ht_main from "./view/ht_main";
import eventTags from "./view/common/js/eventTags";
import common from "./view/common/js/common";
import eventDict from "./view/common/js/eventDict";
import checkUtil from "./view/common/js/checkUtil";
import universalUtil from "./view/common/js/universalUtil";


moment.locale("zh-CN");
moment.suppressDeprecationWarnings = true;
const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$message = message;
app.config.globalProperties.$dataType = columnType;
app.config.globalProperties.$GetEmitData = GetEmitData;
app.config.globalProperties.$version = config.version;
app.config.globalProperties.$eventTags = eventTags;
app.config.globalProperties.$common = common;
app.config.globalProperties.$eventDict = eventDict;
app.config.globalProperties.$checkUtil = checkUtil;
app.config.globalProperties.$universalUtil = universalUtil;

const routes = [
    { path: '/', component: ht_login },
    { path: '/main_manager', component: ht_main },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
});

const store = createStore({
    state () {
        return {
            userInfo: null
        }
    },
    mutations: {
        initUserInfo(state,userInfo){//存储登录角色信息
            state.userInfo = userInfo;
        }
    },
    getters: {
        userInfo: state => {
            return state.userInfo;
        },
    }
})

app.use(router);
app.use(andt);
app.use(VueCookies);
app.use(store);
app.mount("#app");
