<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="true"  />
  <div style="margin-bottom: 70px;margin-top: 20px;margin-left: 10%">
    <a-tag color="#2db7f5" style="float: left;" @click="showAudit">审核信息</a-tag>
  </div >

  <audit-info ref="auditInfo"/>
</template>

<script>
    import ArForm from "../../../../components/form/ArForm";
    import auditInfo from "../../../common/point/auditInfo";
    export default {
      name: "qlrAudit_Show",
      inject:["arguments","argument_qlr"],
      components:{
        ArForm,auditInfo
      },
      computed:{
        argument(){
          return  this.argument_qlr.value;
        },
      },
      methods:{
        showAudit(){
          this.$refs.auditInfo.openAuditShow(this.arguments.value);
        },
      },
    }
</script>

<style scoped>

</style>