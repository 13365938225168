<!--
*@description：框框：权利人信息table
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible_qlr"
           :maskClosable="false"
           :title="modalTitle_qlr"  width="65%"
           :destroyOnClose="true"
  >

    <ar-base-table ref="pstable" :argument="argument" :view-model="true" :scroll="{ x: 700,y:400}">
      <template v-slot:action="{index,record}">
        <div style="justify-content: center;;display: flex;">
          <a-button type="link" @click="showQlr(index,record)">
            <template #icon><Show title="查看"/></template>
          </a-button>
        </div>
      </template>
    </ar-base-table>

    <template #footer>
      <a-button key="back" @click="visible_qlr =false">取消</a-button>
    </template>
  </a-modal>

  <qlr-show ref="qlrShow"/>
</template>

<script>
import Icons from "../../../../../components/Icons";
import ArBaseTable from "../../../../../components/table/ArBaseTable";

import fdytzjd_api from "../../../fdytzjd/config/fdytzjd_api";
import qlrInfo_item from "../../config/qlrInfoTk_item";
import qlrShow from "./qlrShow";

export default {
  name: "qlrTableTk",
  mixins:[Icons],
  components:{
    ArBaseTable,qlrShow
  },
  data(){
    return{
      visible_qlr:false,
      modalTitle_qlr:null,

      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new qlrInfo_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new qlrInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },

    }
  },
  methods:{
    openQlr(zdId){
      fdytzjd_api.queryAllByFLandPlotsId.requestPOSTUrlParam(this,{fLandPlotsId:zdId},res=>{
        this.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data;
          this.visible_qlr = true;
          this.modalTitle_qlr = "权利人信息";
        }
      });
    },
    showQlr(index,arg){
      this.argument.formData = arg;
      this.$refs.qlrShow.openQlrShow(this.argument);
    },
  },
}
</script>

<style scoped>

</style>