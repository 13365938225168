<!--
* @description：
* @author:rzl
* @date:2022/1/20 16:19
-->

<template>
  <div id="layerContent_list" class="layerContent_list" :style="layer_content_style_list">

          <span role="img" aria-label="close-circle" class="anticon anticon-close-circle" style="padding-left: 95%;">
            <a @click="gb_zjd_list" title="关闭">
            <svg focusable="false" class="" data-icon="close-circle" width="1em" height="1em" fill="currentColor"
                 aria-hidden="true" viewBox="64 64 896 896"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512
                 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9
                 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5
                 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448
                 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372
                 372-166.6 372-372 372z"></path>
            </svg></a>
          </span>

    <a-tabs v-model:activeKey="activeKey_list" tab-position="top" id="query_list" tabBarStyle="color:#fafafa" style="margin-top: -7px;">

      <a-tab-pane key="1" tab="宅基地信息列表" class="tabClass" >
        <div v-if="zd_list">
          <a-collapse v-for="(zd,i) in zd_list" :key="i"   accordion style="background-color: #bfbfbf;">
            <a @click="show_zjd_info(zd.id,zd.applyId)">{{zd.villageName}}-{{zd.householderName}}</a>
          </a-collapse>

          <a-pagination v-model="current" :total="pagination.total" size="small" show-less-items style="margin: 8px 0px"/>
        </div>
        <div v-else>
          <h3>暂无数据</h3>
        </div>
      </a-tab-pane>

    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "zjdList",
  data(){
    return{
      pagination:{
        defaultPageSize: 8,
        showSizeChanger: false,
        pageSizeOptions: ['8', '15', '20'],
        onChange: (page) => {
          let parms = {
            page:page,
            pageSize:this.pagination.defaultPageSize
          }
          this.$emit("event", this.$GetEmitData(this.$eventTags.pageMethod,parms));
        },
        total:null
      },

      pageParam:{
        page: 1,
        limit: 10,
      },

      activeKey_list:'1',
      /** 宅基地list信息 **/
      layer_content_style_list:{
        display:"none"
      },
      zd_list:null,
    }
  },
  methods:{
    openZjdList(zdList,total){
      if(zdList.length == 0){
        this.$message.info("无数据！");
        return;
      }
      this.zd_list = zdList;
      this.pagination.total = total;
      this.layer_content_style_list= {
        "display":"block"
      };
    },
    setTotal(num){
      this.pagination.total = num;
    },
    getPageParam(){
      return this.pageParam;
    },
    /** 关闭list **/
    gb_zjd_list(){
      this.layer_content_style_list= {
        "display":"none"
      }
    },
    show_zjd_info(zdId,applyId){
      let parms = {
        zdId:zdId,
        applyId:applyId,
      };
      let data = this.$GetEmitData(this.$eventTags.show_zjd_info,parms);
      this.$emit("event",data);
    },
  },

}
</script>

<style scoped>

.layerContent_list{
  width: 15%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 1;
  position: absolute;
  margin-left: 3%;
  margin-top: 5%;
  height: 40%;
}

.tabClass{
  overflow-y: auto;
  height: 290px;
}

</style>