<!--
* @program: tyh-oa 
* @author: ly
* @description: welcome
* @create: 2021-03-22 18:15
-->
<template>
    <div>
        <div style="position: absolute;top: 40%;left: 30%; font-size: 60px" class="non-select">
            欢迎使用宅基地审批监管平台
        </div>
    </div>
</template>

<script>
    export default {
        name: "welcome",
    }
</script>

<style scoped>

</style>