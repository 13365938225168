<!--
* @description：宗地入库信息展示页面组件
* @author:rzl
* @date:2022/1/14 9:29
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="35%"
           :destroyOnClose="true"
  >

    <ar-form ref="bill_form" :argument="argument" :viewModel="true" style="overflow-y: auto;height: 500px" />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import ArForm from "../../../../../components/form/ArForm";
import fdytzjd_api from "../../../../fdyt/fdytzjd/config/fdytzjd_api";
import fdytzjd_item from "../../../../fdyt/fdytzjd/config/fdytzjd_item";

export default {
  name: "zdrkShow",
  components:{
    ArForm
  },
  data(){
    return{
      visible:null,
      modalTitle:null,
      argument:{
        formColumns:new fdytzjd_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
    }
  },
  methods:{
    /***
     * 房地一体id
     * @param id
     */
      openZdrk(id){
        if(this.$checkUtil.empty_str(id)){
          this.$message.info("数据为空！")
          return true;
        }
        fdytzjd_api.get.requestPOSTUrlParam(this,{id:id},res=>{
          if(res.data.flag){
            this.argument.formData = res.data.data;
          }
        });

        this.visible = true;
        this.modalTitle = "宗地入库数据信息";
      }
  },

}
</script>

<style scoped>

</style>