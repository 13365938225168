<!--
* 地图公用弹框控件
* @description：
* @author:rzl
* @date:2021/7/16 9:21
-->

<template>
  <a-modal v-model:visible="visible_zdt"
           :maskClosable="false"
           :title="modalTitle_zdt"  width="55%"
           :destroyOnClose="true"
  >

    <!--列表栏-->
    <div  style="height: 450px; width: 100%; ">

      <div id="map" ref="rootmap" class="divTdtMap">
        <!-- 鼠标位置 -->
        <div id="mouse-position"></div>
      </div>

    </div>

    <template #footer>
      <a-button key="back" @click="visible_zdt =false">取消</a-button>
    </template>
  </a-modal>

</template>

<script>
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
import {ScaleLine,MousePosition,OverviewMap} from 'ol/control'
import {fromLonLat,get as getProjection,Projection,transform} from 'ol/proj'
import {createStringXY} from 'ol/coordinate'
import {getWidth,getTopLeft,getCenter} from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import WKT from 'ol/format/WKT'

import tdt_api from "../config/tdt_api";
export default {
  name: "map_tk",
  inject:["wkt_geom"],
  data(){
    return{
      map:null,// 地图容器
      geom:null,
      wktGeom:null,

      /**弹框宗地图**/
      visible_zdt:false,
      modalTitle_zdt:null,
      dataSource_zdt: [],

    }
  },
  methods:{
    openMap(wktGeom){
      if(this.$checkUtil.empty_str(wktGeom)){
        this.$message.info("未上传宗地红线！");
        return true;
      }

      this.visible_zdt = true;
      this.modalTitle_zdt = "宗地地图"
      this.wktGeom = wktGeom;
      setTimeout(()=>{
        this.initMap();
      },100)
    },
    /** 初始化地图 **/
    initMap(){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }

      // 视图
      var view = new View({
        center: fromLonLat([106.7572,26.7782]),//106.6472,26.4332
        zoom:13
      });
      var TiandiMap_img = new TileLayer({
        name: "天地图影像图层",
        opacity:0.7,
        source:new WMTS({
          url:tdt_api.wmtsUrl_1,
          layer:'img',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });
      var TiandiMap_cia = new TileLayer({
        name: "天地图影像注记图层",
        source: new WMTS({
          url:tdt_api.wmtsUrl_2,
          layer:'cia',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });

      // 地图
      this.map = new Map({
        target:'map',
        layers:[
          TiandiMap_img,
          TiandiMap_cia
        ],
        /**
         * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
         * */
        loadTilesWhileAnimating: true,//将这个设置为true，默认为false
        view:view
      });

      //添加比例尺控件
      var scaleLineControl = new ScaleLine({
        units: 'metric',
        target: 'scalebar',
        className: 'ol-scale-line'
      });
      this.map.addControl(scaleLineControl);


      //实例化鼠标位置控件
      var mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),//坐标格式
        //地图投影坐标系
        projection: 'EPSG:4326',
        //className:'tip',
        target: document.getElementById('mouse-position'),//显示鼠标位置信息的目标容器
        undefinedHTML: '&nbsp;'//未定义坐标标记
      });

      //添加鼠标位置控件
      this.map.addControl(mousePositionControl);

      // 添加图层：乡镇、村、宅基地
      this.layers_list();
      this.wkt_show();

    },
    /** 添加图层 **/
    layers_list(){
      const userInfo = this.$store.getters.userInfo;
      // 区域权限id
      let area_id = userInfo.villageIds;
      var cql_cwh = '';// 村委会
      var cql_zjd = '';// 宅基地
      if(area_id.length > 0){
        cql_cwh = ' id in ( ' ;
        cql_zjd = ' villageid in ( ' ;
        for(let i=0;i<area_id.length;i++){
          let areaId = area_id[i];
          if(i == 0){
            cql_cwh += '\''+areaId+'\'';
            cql_zjd += '\''+areaId+'\'';
          }else{
            cql_cwh += ','+ '\''+areaId+'\'';
            cql_zjd += ','+ '\''+areaId+'\'';
          }
        }
        cql_cwh += ' )';
        cql_zjd += ' )';
      }
      // cql = ' id in (\'1\',\'792ad3aa-5b19-4e96-a044-80aad5cf2535\',\'b2963bfd-4020-4127-bd0a-5aa45ad33d1a\')' ;

      let leyer_village = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:village',// geoserver发布村服务
            'STYLES': '',
             'CQL_FILTER': cql_cwh,  // 图层属性参数绑定
          },
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(leyer_village);

    },

    /** wkt处理 **/
    wkt_show(){
        var source = new VectorSource();
        // 解析 wkt字符串
        var feature = new WKT().readFeature(this.wktGeom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        });
        source.addFeature(feature);
        var vectorLayer = new VectorLayer({source: source});
        this.map.addLayer(vectorLayer);
        let view = this.map.getView();
        view.fit(feature.getGeometry(),{
          duration:1000,
          maxZoom:18,
        });


    }

  },
}
</script>

<style scoped>
.divTdtMap {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.navigation{
  width: 100%;
  height: 10%;
  z-index: 999;
  border: 1px solid;
  border-radius: 0;
  cursor: pointer;
  padding: 0 10px;
  opacity: 0.8; /**透明度**/
  background-color: #bfbfbf;
}
#mouse-position{
  z-index: 999;
  left: 10%;
  bottom: 8%;
  position: absolute;
  color: white;
  width: 150px;
}
.xm_query{
  position: absolute;
  z-index: 1000;
 /* border: 1px solid #1e76d2;*/
  /*border-radius: 14px;*/

  margin-left: 3%;
  margin-top: 2%;
}
.layerContent_list{
  width: 15%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 0.9;
  position: absolute;
  margin-left: 3%;
  margin-top: 5%;
  height: 40%;
}

.layerContent{
  width: 90%;
  background-color: #bfbfbf;
  border-radius: 5px;
  z-index: 1000;
  color: #ffffff;
  padding: 0 10px 10px 10px;
  opacity: 0.9;
  position: absolute;
  bottom: 1px;
  height: 35%;

}
.tabClass{
  overflow-y: auto;
  height: 290px;
}
.tabClass2{
  overflow-y: auto;
  height: 290px;
  margin-top: -290px;
}
.tabClass_file{
  overflow-y: auto;
  height: 240px;
}

</style>


