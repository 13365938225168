<!--
*@description：审核记录时间树
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible_shjl"
           :maskClosable="false"
           :title="modalTitle_shjl"  width="30%"
           :destroyOnClose="true"
  >

    <div style="height: 500px;overflow-y: auto" >
    <a-timeline >
        <a-timeline-item v-for="(line,index) in time_list"  v-bind:key="index"  :color="getColor(line)"  style="width: 90%;padding-top: 35px;margin-top: 20px;margin-left: 45px;">
          <template #dot>
            <div :style="getStateStyle(line)">
              {{showState(line)}}
            </div>
          </template>
          <div v-if="line.finished" style="height: 200px;background-color:#f0f0f0;">
            <div class="ant-row">
              <div :style="getDepartStyle(line)">
                操作部门：{{line.auditDepartmentName}}
              </div>
            </div>
            <div class="ant-row">
              <div class="ant-col-8">
                <p>操作人：{{line.auditUserName}}</p>
              </div>
              <div class="ant-col-10">
                <p>操作时间：{{showTime(line.auditTime)}}</p>
              </div>
            </div>
            <div class="ant-row" style="height: 57%;background-color: #d9d9d9;overflow: auto;">
              <p>{{line.auditOpinion}}</p>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
    </div>

    <template #footer>
      <a-button key="back" @click="visible_shjl =false">取消</a-button>
    </template>
  </a-modal>



</template>

<script>
import zjdApply_api from "../../../zjdAudit/config/zjdAudit_api";
import moment from "moment";
import dic_api from "../../../../sys/dictionary/config/dic_api";

export default {
name: "form_timeline",
props:["viewModel"],
data(){
    return{
      dateFormat: 'YYYY-MM-DD',
      dictItem:[],

      /**审核记录**/
      visible_shjl:false,
      modalTitle_shjl:null,
      time_list:null,

    }
},
methods:{
  open_timeLine(id){
    if(this.$checkUtil.empty_str(id)){
      this.$message.info("数据为空！");
      return true;
    }
    zjdApply_api.queryAuditLine.requestPOSTUrlParam(this,{applyId:id},res=>{
      if(res.data.flag){
        this.time_list = res.data.data;
      }
    });

    this.queryState();

    this.visible_shjl = true;
    this.modalTitle_shjl = "审核记录"
  },

  showTime(time){
    if(this.$checkUtil.empty_str(time)){
      return "";
    }
    return moment(time).locale('zh-cn').format(this.dateFormat)
  },
  getColor(line){
    let color = "green";
    if(line.finished){
      let status = line.auditStatus;
      if(status == '2.1' || status == '3.1' || status == '5.1' || status == '6.0'){
        // 通过
        color = "green";
      }else if(status == '2.2' || status == '3.2' || status == '5.2'){
        // 未通过
        color = "red";
      }else{
        color = "green";
      }
    }else{
      color= 'gray';
    }

    return color;

  },
  showState(line){
    let stateName = "";
    let children = this.dictItem;
    if(children.length > 0){
      for(let i=0;i<children.length;i++){
        if(line.auditStatus == children[i].dictCode){
          stateName =  children[i].dictName;
        }
      }
    }
    return stateName;
  },
  // 查询审核状态
  queryState(){
    dic_api.queryByNameCode.requestPOSTUrlParam(this,{nameOrCode:"audit_status"},res=>{
      if(res.data.flag){
        let data = res.data.data;
        if(data != null){
          this.dictItem = data.children;
        }
      }
      else{
        this.$message.error(res.data.msg)
      }
    })
  },
  getStateStyle(line){
    let style = {
      color:'#FFFFFF',
      borderRadius:'50%',
      width:'100px',
      height:'40px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      fontSize:'10px',
    };
    if(line.finished){
      if(line.auditStatus == '0.0'){// 待提交
        style.backgroundColor = '#f5a5a0';
      }else if(line.auditStatus == '1.0'){// 待村委会受理
        style.backgroundColor = '#ead5ad'
      }else if(line.auditStatus == '2.0'){// 待村委会审核
        style.backgroundColor = '#eed39f'
      }else if(line.auditStatus == '2.1'){// 村委会审核通过
        style.backgroundColor = '#eebd60'
      }else if(line.auditStatus == '2.2'){// 村委会审核不通过
        style.backgroundColor = '#f14648'
      }else if(line.auditStatus == '3.0'){// 村级公示
        style.backgroundColor = '#eead33'
      }else if(line.auditStatus == '3.1'){// 村级公示通过
        style.backgroundColor = '#ec9d06'
      }else if(line.auditStatus == '3.2'){// 村级公示不通过
        style.backgroundColor = '#f14648'
      }else if(line.auditStatus == '4.0'){// 待乡镇受理
        style.backgroundColor = '#a7c1ee'
      }else if(line.auditStatus == '5.0'){// 待乡镇审核
        style.backgroundColor = '#6799e3'
      }else if(line.auditStatus == '5.1'){// 乡镇审核通过
        style.backgroundColor = '#2a74e3'
      }else if(line.auditStatus == '5.2'){// 乡镇审核不通过
        style.backgroundColor = '#f14648'
      }else if(line.auditStatus == '5.3'){// 子：待审核
        style.backgroundColor = '#b798f1'
      }else if(line.auditStatus == '5.4'){// 子：通过
        style.backgroundColor = '#8651ea'
      }else if(line.auditStatus == '5.5'){// 子：不通过
        style.backgroundColor = '#f14648'
      }else if(line.auditStatus == '6.0'){// 完结
        style.backgroundColor = '#2bc268'
      }else{
        style.backgroundColor = '#aaa'
      }
    }else{// 还未到改节点
      style.backgroundColor = '#aaa';
    }
    return style;
  },
  getDepartStyle(line){
    let style = {
      color: '#FFFFFF',
      width: '100%',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:'10px',
    };
    if(line.auditDepartmentType == '0.0'){
      style.backgroundColor = '#f5a5a0';
    }else if(line.auditDepartmentType == '1.0'){
      style.backgroundColor = '#eebd60';
    }else if(line.auditDepartmentType == '2.0'){
      style.backgroundColor = '#2a74e3';
    }
    return style;
  },
}
}
</script>

<style scoped>

</style>