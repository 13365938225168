<!--
*@description：文件信息弹框:展示
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <!--文件信息信息 start-->
  <a-modal v-model:visible="visible_wj"
           :maskClosable="false"
           :title="modalTitle_wj"  width="55%"
           :destroyOnClose="true"
  >

    <a-tabs v-model:activeKey="activeKey_file" tab-position="top" style="height: 50%" >
      <a-tab-pane key="1" tab="宅基地申请材料"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_sqcl" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="申请人身份证明"   class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_qlr" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="不动产权籍调查成果"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_bdc" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="4" tab="村级审批材料" :disabled="disabled_cwh"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_cwh_sh" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="5" tab="乡镇审批材料" :disabled="disabled_xz" class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_xz_sh" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
    </a-tabs>

    <template #footer>
      <a-button key="back" @click="visible_wj =false">取消</a-button>
    </template>
  </a-modal>
  <!--文件信息信息 end-->

  <!-- 在线预览 -->
  <online-preview ref="onlinePreview"/>
</template>

<script>
import Icons from "../../../../../components/Icons";

import ArBaseTable from "../../../../../components/table/ArBaseTable";
import file_api from "../../../../common/config/file_api";
import file_item from "../../../../common/config/file_item";
import onlinePreview from "../../../../common/components/onlinePreview";

export default {
name: "fileInfoTk",
mixins:[Icons],
components:{
  ArBaseTable,onlinePreview
},
data(){
  return{
    activeKey_file:'1',
    /**弹框文件信息**/
    visible_wj:false,
    modalTitle_wj:null,

    disabled_cwh:true,
    disabled_xz:true,

    argument_sqcl:{
      dataSource:null,//提供table的DataSource
      tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
    },
    argument_bdc:{
      dataSource:null,//提供table的DataSource
      tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
    },
    argument_qlr:{
      dataSource:null,//提供table的DataSource
      tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
    },
    argument_cwh_sh:{
      dataSource:null,//提供table的DataSource
      tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
    },
    argument_xz_sh:{
      dataSource:null,//提供table的DataSource
      tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
    },


  }
},
methods:{
  /***
   * 打开文件编辑
   * @param relateId      业务id
   * @param relateTable   上传文件所属类别：宅基地申请：zjd_apply；
   * @param saveLibrary   是否保存到数据库（为null时，默认不保存）（后端需要参数）
   */
  openFile(relateId,relateTable,saveLibrary){
    if(this.$checkUtil.empty_str(relateId)){
      this.$message.info("数据为空！");
      return true;
    }
    const userInfo = this.$store.getters.userInfo;
    let departmentType = userInfo.departmentType;
    if(departmentType == '0.0'){
      this.disabled_cwh = true;
      this.disabled_xz = true;
    }else if(departmentType == '1.0'){
      this.disabled_cwh = false;
      this.disabled_xz = true;
    }else if(departmentType == '2.0'){
      this.disabled_cwh = false;
      this.disabled_xz = false;
    }
    file_api.queryGroup.requestPOSTUrlParam(this,{relateId:relateId},res=>{
      this.loading =false;
      if(res.data.flag){
        let info_list = res.data.data;
        if(info_list.length > 0){
          for(let i=0;i<info_list.length;i++){
            let info = info_list[i];
            if(info.title == '宅基地申请材料'){
              this.argument_sqcl.dataSource = info.fileList;
            }
            if(info.title == '不动产权籍调查成果'){
              this.argument_bdc.dataSource = info.fileList;
            }
            if(info.title == '申请人身份证明'){
              this.argument_qlr.dataSource = info.fileList;
            }
            if(info.title == '村级审批材料'){
              this.argument_cwh_sh.dataSource = info.fileList;
            }
            if(info.title == '乡镇审批材料'){
              this.argument_xz_sh.dataSource = info.fileList;
            }
          }
        }
        this.visible_wj = true;
        this.modalTitle_wj = '文件信息';
      }
    });
  },
  downloadFile(type,arg){
    let data = {
      webPath:arg.path
    };
    file_api.downloadByWebPath.fileDownload(this,data,arg.name);
  },
  online_preview(type,arg){
    this.$refs.onlinePreview.setFileUrl(arg.path);
  },

},
}
</script>

<style scoped>

</style>