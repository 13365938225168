<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css ant-row">
    <div class="ant-col-6">
      <dic-tree ref="dicTree" @event="selectTree" />
    </div>
    <div class="ant-col-17" style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <dict-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>


</template>

<script>
import dictToolBar from "./components/dictToolBar";
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import dicTree from "./common/point/dicTree";
import drawerComponent from "./components/drawerComponent";

import dic_api from "./config/dic_api";
import dic_item from "./config/dic_item";


export default {
  name:"dict_main",
  mixins:[Icons],
  components:{
    dictToolBar,ArBaseTable,dicTree,drawerComponent
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new dic_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new dic_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
      pid:'',// a447cb66-5e7c-4281-8b09-beb2db9db19a
    };
  },

  created() {
    this.init();
  },
  methods:{
    init(){
      this.argument.loading = true;
      let parms = {
        pid:this.pid,
        dictName:null,
        dictCode:null,
      };
      this.pageParam.page = 1;
      this.pageParam.limit = 10;
      dic_api.queryPageDTO.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms ={
            pid:this.pid,
            dictName:arg.dictName,
            dictCode:arg.dictCode
          }
          this.pageParam.page = 1;
          this.pageParam.limit = 10;
          this.$refs.pstable.setCurrentPage(1);
          dic_api.queryPageDTO.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          /** 获取字典树 **/
          this.$refs.dicTree.getTreeData();
          this.$refs.local_tabs.onClose();
          this.$refs.pstable.setCurrentPage(1);
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.argument.formData.pid = this.pid;
          this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
          break;
        }

      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      parms.pid = this.pid;
      this.argument.loading = true;
      dic_api.queryPageDTO.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    selectTree({type,arg}){
      switch (type){
        case this.$eventTags.search:{
          this.pid = arg.id;
          this.$refs.toolBar.set_pidName(arg.label);
          this.init();
          break;
        }
      }
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      dic_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          /** 获取字典树 **/
          this.$refs.dicTree.getTreeData();
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    dictChild(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer(arg.dictName+":子项信息","dic_child_main",false,this.argument);
    },
    onSelect(selectedKeys,info){
        console.log(selectedKeys,info)
    },
  },
};
</script>
<style>

</style>