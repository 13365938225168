<!--
* @description：公告信息管理
* @author:rzl
* @date:2022/3/15 11:30
-->

<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <zgry-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)"  v-if="!record.publish">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定要发布?"
                okText="确定"
                cancelText="取消"
                @confirm="releaseData(index,record)"
                v-if="!record.publish"
            >

              <a-button type="link">
                <template #icon><release title="发布" style="color: #52c41a;"/></template>
              </a-button>
            </a-popconfirm>

            <a-popconfirm
                class="action-button"
                title="确定要撤回?"
                okText="确定"
                cancelText="取消"
                @confirm="revokeData(index,record)"
                v-if="record.publish"
            >

              <a-button type="link">
                <template #icon><revoke title="撤回" style="color: #faad14;"/></template>
              </a-button>
            </a-popconfirm>

            <a-popconfirm
                class="action-button"
                title="确定要删除?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>
</template>

<script>
import Icons from "../../components/Icons";
import ArBaseTable from "../../components/table/ArBaseTable";
import zgryToolBar from "./components/informationToolBar";
import drawerComponent from "./components/drawerComponent";
import information_item from "./config/information_item";
import information_api from "./config/information_api";

export default {
  name: "information_main",
  mixins:[Icons],
  components:{
    ArBaseTable,zgryToolBar,drawerComponent
  },
  data(){
    return{
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new information_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        formColumns:new information_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
    }
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      let parms = {};
      this.argument.loading = true;
      information_api.queryPageForManage.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.dataSource = [];
          let parms = this.$refs.toolBar.getParms();
          information_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add :{
          this.argument.formData = {};
          this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
          break;
        }
      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      information_api.queryPageForManage.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.argument.formData = {};
      information_api.get.requestPOSTUrlParam(this, {id:arg.id},res=>{
        if(res.data.flag){
          this.argument.formData = res.data.data;
          this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
        }else{
          this.$message.error(res.data.msg);
        }
      });

    },
    editData(index,arg){
      this.argument.formData = {};
      information_api.get.requestPOSTUrlParam(this, {id:arg.id},res=>{
        if(res.data.flag){
          this.argument.formData = res.data.data;
          this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },

    deleteData(index,arg){
      let data = {id:arg.id};
      information_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },

    releaseData(index,arg){
      let data = {id:arg.id};
      information_api.publish.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },

    revokeData(index,arg){
      let data = {id:arg.id};
      information_api.recall.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>