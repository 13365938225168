<!--
*@description：字典树
*@author:rzl
*@date:2022/02/17
-->
<template>
  <div class="dicTree" style="margin-top: 15px;background-color: #f0f0f0;" >
    <a-input style="margin-bottom: 8px; width: 70%;margin-top: 10px"  placeholder="输入关键字搜索" v-model:value="searchVal" @change="searchOnChange" @search="onSearch" />
    <div class="dicTree" style="height:700px;overflow: auto;" >
      <a-tree
        v-if="showData.length > 0"
        :expanded-keys="iExpandedKeys"
        :auto-expand-parent="autoExpandParent"
        default-expanded-keys=""
        :tree-data="showData"
        @expand="onExpand"
        @select="selectShow"
        :replace-fields="{children:'children', key:'id', value: 'id', title: 'label'}"
        style="margin-left: 20px;text-align: left;width: 100%"
      >
        <template #label="{label}">
          <span v-if="label.indexOf(searchValue) > -1">
            {{ label.substr(0, label.indexOf(searchValue)) }}
            <span style="color: #f50">{{ searchValue }}</span>
            {{ label.substr(label.indexOf(searchValue) + searchValue.length) }}
          </span>
          <span v-else>{{ label }}</span>
        </template>
      </a-tree>
    </div>
  </div>
</template>

<script>
import dic_api from "../../config/dic_api";

export default {
  name: 'dicTree',
  data () {
    return {
      showData: [],
      defaultData: [],
      expandedKeys:[],
      searchVal: "",
      searchValue: "",
      iExpandedKeys: [],
      autoExpandParent: true,
      tree_data: [],
    }
  },
  mounted () {
    this.getTreeData();
  },
  methods: {
    getTreeData() {
      this.showData = [];
      this.defaultData = [];
      this.tree_data = [];

      let parms = {
        dictName:null,
        dictCode:null,
      };
      dic_api.queryAll.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let level = 1;
          var tempObj = {
            id:'',
            label:'字典类别',
            level:level,
          };
         tempObj.children = this.$common.convertToTreedatas_dic(res.data.data,level);
          this.tree_data.push(tempObj);
          for (let i = 0; i < this.tree_data.length; i++) {
            let temp = this.tree_data[i];
            this.defaultData.push(JSON.parse(JSON.stringify(temp)))
            this.showData = [...this.defaultData];
            this.recursionData(this.defaultData);//将每一层数据都赋上title的slot,以高亮显示搜索字段
            this.setThisExpandedKeys(temp)
          }
        }
        else{
          this.$message.error(res.data.msg)
        }
      })

    },
    recursionData (node) {
      node.forEach(item => {
        item.scopedSlots = { title: 'label' }
        if (item.children && item.children.length) {
          this.recursionData(item.children)
        }
      })
        },
    setThisExpandedKeys(node) {
        //只展开一级目录
        if (node.children && node.children.length > 0) {
          this.iExpandedKeys.push(node.id)
          //下方代码放开注释则默认展开所有节点
          /* for (let a = 0; a < node.children.length; a++) {
             this.setThisExpandedKeys(node.children[a])
           }*/
        }
      },
      onExpand(expandedKeys) {
          this.iExpandedKeys = expandedKeys
          this.autoExpandParent = false
      },
      searchOnChange () {
        this.showData= [...this.defaultData];
        if (this.searchVal) {
          this.onSearch(this.searchVal);
        } else {
          this.searchValue = "";
          this.iExpandedKeys = [this.showData[0].id];
        }
      },
      onSearch(val){
        const value = val
        this.searchValue = value
        if (value != '') {
          let treeData = JSON.parse(JSON.stringify(this.showData));

          let listData = this.getkeyList(treeData, val);
          this.showData= [...listData];
          // 展开所有树数据
          this.expandAll(this.showData);
        } else {
          this.iExpandedKeys = [this.showData[0].id];
        }
      },
    //获取节点中含有value的所有key集合
    getkeyList(tree,val){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < tree.length; i++){
        let tempObj =[];
        let str = tree[i].label;
        if(str.indexOf(val) > -1){
          tempObj = tree[i];
          returnData.push(tempObj);
        }else{
          if(tree[i].children != null && tree[i].children.length > 0){
            let children = this.getkeyList(tree[i].children,val);
            if(children.length > 0){
              tempObj = tree[i];
              tempObj.children = children;
              returnData.push(tempObj);
            }
          }
        }
      }

      return returnData;
    },
      expandAll (node) {
        node.forEach(item => {
          if (item.children && item.children.length) {
            this.iExpandedKeys.push(item.id)
            this.expandAll(item.children)
          }
        })
      },
    selectShow(expandedKeys,info){
      let label = info.selectedNodes[0].props.label;
      let parms = {
        id:expandedKeys,
        label:label
      };
      this.$emit("event",this.$GetEmitData(this.$eventTags.search,parms));
    },
  }

}
</script>
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
