<!--
*@description：后台主页
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <a-spin style="padding: 25%" tip="正在登录....." :spinning="spinning" size="large"/>

    <a-layout v-if="!spinning" style="height: 100%">

        <a-layout-header class="header">
            <oa-header ref="oaHeader" @event="headMenuHandle" :userName="userName"/>
        </a-layout-header>
        <a-layout>
          <a-layout-sider  style="overflow: auto;">
<!--            <a-button type="primary" @click="toggleCollapsed" style="margin-bottom: 16px">
              <MenuUnfoldOutlined v-if="collapsed" />
              <MenuFoldOutlined v-else />
            </a-button>-->

            <a-menu
                theme="dark"
                mode="inline"
                :inlineCollapsed="true"
                @click="handleClick">
              <sub-group-menu v-for="group in menuLists" :groupMenus="group" :key="group.id" />
            </a-menu>
          </a-layout-sider>
            <a-layout-content>
                <keep-alive>
                    <div v-is="contentType" ref="menuContent" @event="contentHandle" />
                </keep-alive>

<!--              <a-layout-header class="footer">
                <oa-footer ref="oaFooter" />
              </a-layout-header>-->

            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>

    import {menuGenerate} from "../assets/system/menu"
    import {BaseMenu} from "../assets/system/menu";

    import menuContent from "../components/menu/menuContent";
    import oaHeader from "./ht_header";
    import oaFooter from "./oaFooter";
    import welcome from "./welcome";
    import api from "./common/config/login";

    /**系统维护**/
    import user_main from "./sys/user/user_main";
    import role_main from "./sys/role/role_main";
    import dic_main from "./sys/dictionary/dic_main";
    import department_main from "./sys/department/department_main";
    import menu_main from "./sys/menu/menu_main";

    /*** 房地一体 ****/
    import fdytqlr_main from "./fdyt/fdytqlr/fdytqlr_main";
    import fdytzjd_main from "./fdyt/fdytzjd/fdytzjd_main";

    /*** 权利人信息 ***/
    import qlrInfo_main from "./qlr/qlrInfo/qlrInfo_main";
    import qlrAudit_main from "./qlr/qlrAudit/qlrAudit_main";

    /*** 宅基地信息 ***/
    import zjdInfo_main from "./zjd/zjdInfo/zjdInfo_main";
    import zjdAudit_main from "./zjd/zjdAudit/zjdAudit_main";
    import zjdApply_main from "./zjd/zjdApply/zjdApply_main";
    import revoked_main from "./zjd/revoked/revoked_main";

    /** 首页地图 **/
    import home_main from "./homeMap/home_main";
    import test_main from "./homeMap/test_main";

    /** 资格名录库 **/
    import zgry_main from "./zgmlk/zgry_main";

    /** 公共信息管理 **/
    import information_main from "./info/information_main";

    export default {
        name: "mainPage",
        mixins:[menuContent],
        components:{
          oaHeader:oaHeader,
          oaFooter:oaFooter,
          welcome:welcome,
          user_main:user_main,
          role_main:role_main,
          dic_main:dic_main,
          department_main:department_main,
          menu_main:menu_main,
          fdytqlr_main:fdytqlr_main,
          fdytzjd_main:fdytzjd_main,
          qlrInfo_main:qlrInfo_main,
          qlrAudit_main:qlrAudit_main,
          zjdInfo_main:zjdInfo_main,
          zjdAudit_main:zjdAudit_main,
          zjdApply_main:zjdApply_main,
          home_main:home_main,
          zgry_main:zgry_main,
          test_main:test_main,
          revoked_main:revoked_main,
          information_main:information_main,
        },
        data(){
            return{
              menuObjects:new BaseMenu(),
              menuLists:[],

                contentType:"welcome",
                currentLeftMenu:[],
                spinning:true,
                userName:null,

              collapsed:false,


            }
        },
        provide(){
            return{
                menuVisible:true,
            }
        },
        created() {
              this.init();
        },
        methods:{
            init(){
              let phone = this.$cookies.get("phone");
              let password = this.$cookies.get("password");
              let data = {
                phone:phone,
                password:password
              };

              api.login.requestPOSTUrlParam(this,data,res=>{
                if(res.data.flag){
                  this.spinning=false;
                  this.$store.commit("initUserInfo",res.data.data);
                  this.$cookies.set("userInfo",res.data.data);
                  this.userName = res.data.data.name;
                  /** 获取登录用户拥有的菜单信息 **/
                  api.userMenuOpTree.requestPOST(this,{},res=>{
                    if(res.data.flag){
                      menuGenerate(this.menuObjects,res.data.data);
                      this.menuLists = this.menuObjects.children;
                    }
                    else{
                      this.$message.error(res.data.msg);
                    }
                  },this);

                }
                else{
                  this.$cookies.set("phone","");
                  this.$cookies.set("password","");
                  this.$router.push({ path: '/' });
                  this.$message.error(res.data.msg);
                }
              });
            },
            headMenuHandle(data){
                switch (data.type) {
                    case "logout":{
                        api.logout.requestPOST(this,null,res=>{
                            if(res.data.flag){
                                this.$cookies.set("userInfo","")
                                  this.$router.push({ path: '/' });
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                        break;
                    }
                    default:{
                        this.$emit("event",data);
                        break;
                    }
                }
            },
            contentHandle(data){
                switch (data.type) {
                    case "mounted":{//{type:type,arg:object}

                        break;
                    }
                    default:{
                        this.$emit("event",data);
                        break;
                    }
                }
            },
          toggleCollapsed(){
            this.collapsed = !this.collapsed;
          },

        },

    }
</script>

<style scoped>

</style>