/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class hgfx1_item extends Table{
    constructor() {
        super();
        this.name = "hgfx1_item";
        this.CNName = "地类信息";
        this.columnArray = [
            new Column("地类","name",ColumnType.String,false).setTableView(),
            new Column("面积（平方米）","area",ColumnType.String,false).setTableView(),
            new Column("图例","legendPic",ColumnType.Avatar,false).setTableView(),
        ];

    }
}