<!--
*@description：子项新增、编辑
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible"
           :title="modalTitle" width="35%"
           :destroyOnClose="true"
  >

    <ar-form  ref="bill_form" :argument="arguments"  :viewModel="sub_flag" style="overflow-y: auto;height: 500px" />

    <div style="margin-bottom: 30px;margin-top: 20px;margin-left: 5%">
      <a-tag color="#2db7f5" style="float: left;" @click="showFile">文件信息</a-tag>
      <a-tag color="#2db7f5" style="float: left;" @click="showZddt">宅基地地图</a-tag>
    </div >

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

  <!-- 文件信息 -->
  <file-info-tk :loading="loading" ref="fileInfoBut"/>

  <!--宗地图信息-->
  <map-tk :loading="loading" ref="mapTkBut"/>
</template>

<script>
    import ArForm from "../../../../../components/form/ArForm";
    import mapTk from "../../../../homeMap/common/map_tk";
    import fileInfoTk from "../../../../zjd/common/point/file/fileInfoTk";

    export default {
      name: "zjdInfo_show",
      inject:["arguments"],
      components:{
        ArForm,mapTk,fileInfoTk
      },
      computed:{
        arguments(){
          return  this.argument;
        },
      },
      data(){
        return{
          argument:null,
          visible:false,
          modalTitle:null,
          sub_flag:false,
        }
      },
      methods:{
        openDrawer(title,component,sub_flag,argument){
          this.visible = true;
          this.modalTitle = title;
          this.comp = component;
          this.sub_flag = sub_flag;
          this.argument = argument;
        },
        showFile(){
          this.$refs.fileInfoBut.openFile(this.arguments.formData.id);
        },
        showZddt(){
          this.$refs.mapTkBut.openMap(this.arguments.formData.geom);
        },
        submit(){
          switch (this.comp){
            case "add_c":{

              break;
            }
            case "edit_c":{

              break;
            }
          }
        },
      }
    }
</script>

<style scoped>

</style>