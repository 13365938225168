/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryAll:new APIObject("/api/fLandPlots/queryAll.do"),// 查询的宗地信息-查询符合条件的所有宗地
    queryForManage:new APIObject("/api/fLandPlots/queryForManage.do"),// 管理部门查询的宗地信息-分页
    get:new APIObject("/api/fLandPlots/get.do"),// 查询-单条
    queryAllByFLandPlotsId:new APIObject("/api/obligee/queryAllByFLandPlotsId.do"),//权利人管理-房地一体宗地关联的所有权利人
};

export default api;