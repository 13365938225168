/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryPage:new APIObject("/api/userInfo/queryPage.do"),//系统用户管理-分页查询
    saveOrUpd:new APIObject("/api/userInfo/saveOrUpd.do"),//系统用户管理-保存/修改
    batchDelete:new APIObject("/api/userInfo/batchDelete.do"),//系统用户管理-批量删除
    findUserByRole:new APIObject("/api/userInfo/findUserByRole.do"),//查询-根据角色id查询该角色下的所有用户
    delete:new APIObject("/api/userInfo/delete.do"),//系统用户管理-单条删除.
    queryOne:new APIObject("/api/userInfo/queryOne.do"),//系统用户管理-单条数据查询
    stateModify:new APIObject("/api/userInfo/stateModify.do"),//系统用户管理-单个用户启用禁用
    passWordModify:new APIObject("/api/userInfo/passWordModify.do"),//系统用户管理-用户密码修改（个人中心）
    queryAll:new APIObject("/api/userInfo/queryAll.do"),//系统用户管理-所有数据查询
    batchstateModify:new APIObject("/api/userInfo/batchstateModify.do"),//系统用户管理-批量用户启用禁用

    queryAll_role:new APIObject("/api/role/queryAll.do"),//
    queryAll_role_user:new APIObject("/api/userRoleRelation/queryAll.do"),
    batchBinding_role:new APIObject("/api/userRoleRelation/batchBinding.do"),

    findUserByDepart:new APIObject("/api/userInfo/findUserByDepart.do"),// 查询-根据部门id查询该部门下的所有用户
    queryPageByDepartment:new APIObject("/api/userInfo/queryPageByDepartment.do"),// 系统用户管理-分页查询部门用户
    findRoleByUser:new APIObject("/api/role/findRoleByUser.do"),//查询-全部不分页(根据userId查询用户所拥有的角色)

};

export default api;