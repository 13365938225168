/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"
import qlrInfo_api from "./qlrInfo_api";
export default class qlrInfo_item extends Table{
    constructor() {
        super();
        this.name = "qlrInfo_item";
        this.CNName = "权利人审核信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("权利人id","obligeeId",ColumnType.String,false).setVisible(false),
            new Column("属地","apanage",ColumnType.String,false).setTableView(),
            new Column("申请人","createUserName",ColumnType.String,true).setTableView(),
            new Column("权利人名称","obligeeName",ColumnType.String,true).setTableView(),
            new Column("理由","applyReason",ColumnType.String,true),

            new Column("审核状态","auditStatus",ColumnType.dictionary,true).setTableView(150)
                .setDictionary("audit_through"),
            new Column("审核意见","auditOpinion",ColumnType.String,true),
            new Column("审核人","auditUserName",ColumnType.String,true).setTableView(),
            new Column("审核时间","auditTime",ColumnType.Date,true).setTableView(),


        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(150)];
    }
}