<!--
* @program: TYH_office_automation 
* @author: ly
* @component:dataTypes 
* @description: 
* @create: 2021-12-06 14:17
-->
<template>
    <div>
        
    </div>
</template>

<script>
    
    import typeBooleanCheck from "./typeBooleanCheck";
    import typeDate from "./typeDate";
    import typeEnum from "./typeEnum";
    import typeString from "./typeString";
    import typeNumber from "./typeNumber";
    import typeNumberJs from "./typeNumberJs";
    import typeBoolean from "./typeBoolean";
    import typeMonth from "./typeMonth";
    import typeIndex from "./typeIndex";
    import typeIdReplace from "./typeIdReplace";
    import typeIdReplace2 from "./typeIdReplace2";
    import typeAvatar from "./typeAvatar";
    import typeSelector from "./typeSelector";
    import columnType from "../../assets/tables/parts/column_type";
    import typeObjectReplace from "./typeObjectReplace";
    import typeTextField from "./typeTextField";

    /**** 业务中添加type *****/
    import typeDictionary from "../../view/common/dataPresentType/typeDictionary";
    import typeDictionary_c from "../../view/common/dataPresentType/typeDictionary_c";
    import typeIdReplaceXmgd from "../../view/common/dataPresentType/typeIdReplaceXmgd";
    import typeQlrUse from "../../view/zjd/common/dataPresentType/typeQlrUse";

    export default {
        name: "dataTypes",
        components:{
          typeBooleanCheck, typeDate, typeEnum,
          typeString, typeNumber, typeBoolean,
          typeMonth, typeIndex, typeIdReplace,typeTextField,
          typeIdReplace2,typeAvatar,typeSelector, typeObjectReplace,

          typeDictionary,typeDictionary_c,typeNumberJs,typeIdReplaceXmgd,
          typeQlrUse

        },
        computed:{
            type() {
                switch (this.column.slots.customRender) {
                  case columnType.String:{return "typeString";}
                  case columnType.Number:{return "typeNumber";}
                  case columnType.NumberJs:{return "typeNumberJs";}
                  case columnType.Date:{return "typeDate"}
                  case columnType.BooleanCheck:{return "typeBooleanCheck"}
                  case columnType.Enum:{return "typeEnum"}
                  case columnType.Boolean:{return "typeBoolean"}
                  case columnType.Month:{return "typeMonth"}
                  case columnType.Index:{return "typeIndex"}
                  case columnType.IdReplace:{return "typeIdReplace"}
                  case columnType.IdReplace2:{return "typeIdReplace2"}
                  case columnType.Avatar:{return "typeAvatar"}
                  case columnType.Selector:{return "typeSelector"}
                  case columnType.ObjectReplace:{return "typeObjectReplace"}
                  case columnType.TextField:{return "typeTextField"}

                  case columnType.Dictionary:{return "typeDictionary"}
                  case columnType.Dictionary_c:{return "typeDictionary_c"}
                  case columnType.IdReplaceXmgd:{return "typeIdReplaceXmgd"}
                  case columnType.QlrUse:{return "typeQlrUse"}
                  default :{return "";}
                }
            },
        },
    }
</script>

<style scoped>

</style>