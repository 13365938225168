<!--
*@description：权利人审核主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <qlr-audit-tool-bar  ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button v-if="record.auditStatus == '1'" type="link" @click="auditData(index,record)" >
              <template #icon><Audit title="审核"/></template>
            </a-button>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>

    <!-- 审核信息 -->
    <audit-tk ref="audit_tk" @event="eventHandle"/>
  </div>

</template>

<script>
    import Icons from "../../../components/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import drawerComponent from "./components/drawerComponent";
    import qlrAuditToolBar from "./components/qlrAuditToolBar";

    import qlrInfo_api from "./config/qlrInfo_api";
    import qlrAudit_item from "./config/qlrAudit_item";
    import auditTk from "../../common/point/auditTk";
    import qlrInfo_item from "./config/qlrInfo_item";

    export default {
      name: "qlrAudit_main",
      mixins:[Icons],
      components:{
        qlrAuditToolBar,ArBaseTable,drawerComponent,auditTk
      },
      data(){
          return{
            argument:{
              dataSource:null,//提供table的DataSource
              tableColumns:new qlrAudit_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
              loading:false,//table的loading状态
              currentIndex:null,
              formColumns:new qlrAudit_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
              formData:{},
            },
            argument_qlr:{
              formColumns:new qlrInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
              formData:{},
            },
            pageParam:{
              page: 1,
              limit: 10,
            },
          }
      },
      created() {
        this.init()
      },

      methods:{
        init(){
          let parms = {};
          this.argument.loading = true;
          qlrInfo_api.queryPageForAudit.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
              if(res.data.flag){
                this.argument.dataSource = res.data.data.resultList;
                this.$refs.pstable.setTotalSize(res.data.data.total);
              }
          });

        },
        eventHandle({type,arg}){
            switch (type) {
                case this.$eventTags.search:{
                    this.argument.loading = true;
                    let parms ={
                      queryText:arg.queryText,
                    }
                  qlrInfo_api.queryPageForAudit.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
                      this.argument.loading =false;
                      if(res.data.flag){
                        this.argument.dataSource = res.data.data.resultList;
                        this.$refs.pstable.setTotalSize(res.data.data.total);
                      }
                    });
                    break;
                }
                case this.$eventTags.refresh:{
                  this.$refs.local_tabs.onClose();
                  this.init();
                  break;
                }
                case this.$eventTags.approve:{
                  qlrInfo_api.audit.requestPOSTUrlParam(this,arg,res=>{
                    if(res.data.flag){
                      this.$refs.audit_tk.closeAudit();
                      this.$message.success(res.data.msg);
                      this.init();
                    }
                    else{
                      this.$message.error(res.data.msg);
                    }
                  })
                  break;
                }
                case this.$eventTags.reject:{
                  if(arg.auditOpinion == null || this.auditOpinion == ''){
                    this.$message.error('请输入审核意见');
                    return;
                  }
                  qlrInfo_api.audit.requestPOSTUrlParam(this,arg,res=>{
                    if(res.data.flag){
                      this.$refs.audit_tk.closeAudit();
                      this.$message.success(res.data.msg);
                      this.init();
                    }
                    else{
                      this.$message.error(res.data.msg);
                    }
                  })
                  break;
                }
            }
        },
        pageChange(arg){
          this.pageParam.page = arg.page;
          this.pageParam.limit = arg.limit;
          let parms = this.$refs.toolBar.getParms();
          this.argument.loading = true;
          qlrInfo_api.queryPageForAudit.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
        },
        showData(index,arg){
          qlrInfo_api.getForManage.requestPOSTUrlParam(this,{id:arg.obligeeId},res=>{
            if(res.data.flag){
              this.argument_qlr.formData = res.data.data;
            }
          });
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument,this.argument_qlr);
        },
        auditData(index,arg){
          this.$refs.audit_tk.openAudit(arg.id);
        },

      },

    }
</script>

<style scoped>

</style>