<!--
*@description：宅基地申请主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <zjd-apply-tool-bar  ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 1500,y:650}">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <div v-if="record.auditStatus == '0.0' || record.auditStatus == '2.2' || record.auditStatus == '3.2' ||  record.auditStatus == '5.2'">
              <a-button
                  class="action-button" type="link"
                  @click="editData(index,record)"
              >
                <template #icon><Edit title="编辑"/></template>
              </a-button>

              <a-button
                  class="action-button" type="link"
                  @click="uploadZdhx(index,record)"
              >
                <template #icon><zdhx title="上传宗地红线"/></template>
              </a-button>

              <a-button
                  class="action-button" type="link"
                  @click="scFile(index,record)"
              >
                <template #icon><UploadFile title="上传文件"/></template>
              </a-button>


              <a-popconfirm
                  class="action-button"
                  title="确定提交审核吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="submitData(index,record)"
              >
                <a-button type="link">
                  <template #icon><CheckOutlined title="提交审核"/></template>
                </a-button>
              </a-popconfirm>

            </div>

            <a-button class="action-button" type="link"
                      @click="revokeData(index,record)"
                      v-if="record.auditStatus == '2.2' || record.auditStatus == '3.2' ||  record.auditStatus == '5.2'"
            >
              <template #icon><revoke title="撤销" style="color: #f5a70c"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)"
                v-if="record.auditStatus == '0.0' "
            >
              <a-button type="link">
                <template #icon><delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>

          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 房地一体编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>

    <!-- 宅基地编辑页面 -->
    <drawer-component-zjd ref="local_tabs_zjd" @event="eventHandle"/>

    <!-- 上传文件 -->
    <file-edit-tk  ref="fielEditTk" />

    <!-- 上传宗地红线 -->
    <sc_zdhx ref="scZdhx" @event="eventHandle"/>

    <!-- 撤销 -->
    <revoked-tk ref="revokedTk" @event="eventHandle"/>

  </div>

</template>

<script>
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import drawerComponent from "./components/drawerComponent";
import zjdApplyToolBar from "./components/zjdApplyToolBar";
import drawerComponentZjd from "./components/drawerComponentZjd";

import zjdApply_api from "./config/zjdApply_api";
import zjdApply_item from "../common/config/zjd/zjdApply_item";
import fileEditTk from "../common/point/file/fileEditTk";
import sc_zdhx from "./point/sc_zdhx";
import revokedTk from "./point/revokedTk";

export default {
name: "zjdApply_main",
mixins:[Icons],
components:{
  zjdApplyToolBar,ArBaseTable,drawerComponent,drawerComponentZjd,fileEditTk,sc_zdhx,revokedTk
},
data(){
    return{
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new zjdApply_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new zjdApply_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
    }
},
created() {
  this.init()
},
methods:{
  init(){
    let parms = {};
    this.argument.loading = true;
    zjdApply_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
      this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
    });

  },
  eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
            this.argument.loading = true;
            let parms ={
              queryText:arg.queryText,
              villageName:arg.villageName,
              auditStatus:arg.auditStatus,
            }
          zjdApply_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
              this.argument.loading =false;
              if(res.data.flag){
                this.argument.dataSource = res.data.data.resultList;
                this.$refs.pstable.setTotalSize(res.data.data.total);
              }
            });
            break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.$refs.local_tabs_zjd.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add_zjd :{
          this.argument.formData = {};
          this.$refs.local_tabs_zjd.openDrawer("宅基地新增","zjd_add",true,this.argument);
          break;
        }
        case this.$eventTags.add :{
            this.argument.formData = {};
            this.$refs.local_tabs.openDrawer("房地一体数据新增","add",true,this.argument);
            break;
          }
        case this.$eventTags.refresh2:{
          this.init();
          break;
        }
        case this.$eventTags.revoked:{
          zjdApply_api.rescind.requestPOSTUrlParam(this,arg,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$refs.revokedTk.closeRevoked();
              this.init();
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
      }
  },
  pageChange(arg){
    this.pageParam.page = arg.page;
    this.pageParam.limit = arg.limit;
    let parms = this.$refs.toolBar.getParms();
    this.argument.loading = true;
    zjdApply_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
      this.argument.loading =false;
      if(res.data.flag){
        this.argument.dataSource = res.data.data.resultList;
        this.$refs.pstable.setTotalSize(res.data.data.total);
      }
    });
  },
  showData(index,arg){
    if(arg.applyType == '1'){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
    }else if(arg.applyType == '2'){
      this.argument.formData = arg;
      this.$refs.local_tabs_zjd.openDrawer("查看信息","zjd_show",false, this.argument);
    }

  },
  editData(index,arg){
    if(arg.applyType == '1'){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
    }else if(arg.applyType == '2'){
      this.argument.formData = arg;
      this.$refs.local_tabs_zjd.openDrawer("编辑信息","zjd_edit",true,this.argument);
    }

  },
  deleteData(index,arg){
    let data = {id:arg.id};
    zjdApply_api.deleteWeb.requestPOSTUrlParam(this,data,res=>{
      if(res.data.flag){
        this.argument.dataSource.splice(index,1);
        this.$message.success(res.data.msg);
        this.init();
      }
      else{
        this.$message.error(res.data.msg)
      }
    })
  },
  submitData(index,arg){
    let ids = [];
    ids.push(arg.id);
    let data = {
      ids:ids
    };
    zjdApply_api.submit.requestPOSTUrlParam(this,data,res=>{
      if(res.data.flag){
        this.$message.success(res.data.msg);
        this.init();
      }
      else{
        this.$message.error(res.data.msg)
      }
    })
  },
  scFile(index,arg){
    this.$refs.fielEditTk.openFile(arg.id,"zjd_apply",true);
  },
  uploadZdhx(index,arg){
    this.argument.formData = arg;
    this.$refs.scZdhx.openFile(this.argument);
  },
  revokeData(index,arg){
    this.$refs.revokedTk.openRevoked(arg.id);
  },

},

}
</script>

<style scoped>

</style>