<!--
*@description：文件信息编辑弹框：申请、村审批、乡镇审批
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <!--文件信息信息 start-->
  <a-modal v-model:visible="visible_wj"
           :maskClosable="false"
           :title="modalTitle_wj"  width="55%"
           :destroyOnClose="true"
  >

    <a-form-item  label="文件类别：" style="margin-left: 16%;">
      <a-select
          v-model:value="parms.type"
          style="width:200px;margin-left: -60%"
          class="search-item"
      >

        <a-select-option v-for="(item) in search_file" :key="item.key">
          {{ item.value }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item  label="附件：" style="margin-left: 19%;">
      <div style="margin-left: -62%">
        <a-upload
            v-model:file-list="fileList"
            name="file"
            :data='parms'
            :action="actionUrl"
            @change="handleChange"
            :showUploadList="false"
            :accept="accept"
            withCredentials
            style="margin-left: -60%;"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            文件上传
          </a-button>
        </a-upload>
      </div>
    </a-form-item>

    <hr/>
    <a-tabs v-model:activeKey="activeKey_file" @change="changeTab" tab-position="top" style="height: 50%" >
      <a-tab-pane key="1" tab="宅基地申请材料"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_sqcl" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>

            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="申请人身份证明"   class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_qlr" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="不动产权籍调查成果"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_bdc" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="4" tab="村级审批材料" :disabled="disabled_cwh"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_cwh_sh" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
      <a-tab-pane key="5" tab="乡镇审批材料" :disabled="disabled_xz"  class="tabClass_file">
        <ar-base-table ref="pstable" :argument="argument_xz_sh" :view-model="true" :scroll="{ x: 700,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="downloadFile(index,record)">
                <template #icon><Download title="下载"/></template>
              </a-button>

              <a-button v-if="record.suffix == 'pdf'" type="link" @click="online_preview(index,record)">
                <template #icon><EyeTwoTone title="在线预览"/></template>
              </a-button>

              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)">
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ar-base-table>
      </a-tab-pane>
    </a-tabs>

    <template #footer>
      <a-button key="back" @click="visible_wj =false">取消</a-button>
    </template>
  </a-modal>
  <!--文件信息信息 end-->

  <!-- 遮罩层 -->
  <mask-layer ref="maskLayer"/>

  <!-- 在线预览 -->
  <online-preview ref="onlinePreview"/>
</template>

<script>
import Icons from "../../../../../components/Icons";

import ArBaseTable from "../../../../../components/table/ArBaseTable";
import file_api from "../../../../common/config/file_api";
import file_item from "../../../../common/config/file_item";
import maskLayer from "../../../../common/components/maskLayer";
import onlinePreview from "../../../../common/components/onlinePreview";

export default {
name: "fileInfoTk",
mixins:[Icons],
components:{
  ArBaseTable,maskLayer,onlinePreview
},
data(){
    return{
      activeKey_file:'1',
      /**弹框文件信息**/
      visible_wj:false,
      modalTitle_wj:null,

      disabled_cwh:true,
      disabled_xz:true,

      argument_sqcl:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_qlr:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_bdc:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_cwh_sh:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      argument_xz_sh:{
        dataSource:null,//提供table的DataSource
        tableColumns:new file_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      search_file:[],// 上传文件选项

      accept:".pdf",//Vimage/png,image/jpeg
      file:null,
      fileList:[],
      previewVisible:false,
      actionUrl:file_api.upload.url,
      baseImageUrl:"d:",

      parms:{
        type:null,
        relateId:null,
        relateTable:null,
        saveLibrary:null,
      }

    }
},
methods:{
  /***
   * 打开文件编辑
   * @param relateId      业务id
   * @param relateTable   上传文件所属类别：宅基地申请：zjd_apply；
   * @param saveLibrary   是否保存到数据库（为null时，默认不保存）（后端需要参数）
   */
  openFile(relateId,relateTable,saveLibrary){
    this.parms.relateId = relateId;
    this.parms.relateTable = relateTable;
    this.parms.saveLibrary = saveLibrary;
    this.activeKey_file = "1";
    this.search_file = [
      {key:'0.1',value:'申请表'},
      {key:'0.2',value:'承诺书'},
    ];
    this.parms.type = null;
    const userInfo = this.$store.getters.userInfo;
    let departmentType = userInfo.departmentType;
    if(departmentType == '0.0'){
      this.disabled_cwh = true;
      this.disabled_xz = true;
    }else if(departmentType == '1.0'){
      this.disabled_cwh = false;
      this.disabled_xz = true;
    }else if(departmentType == '2.0'){
      this.disabled_cwh = false;
      this.disabled_xz = false;
    }
    file_api.queryGroup.requestPOSTUrlParam(this,{relateId:relateId},res=>{
      this.loading =false;
      if(res.data.flag){
        let info_list = res.data.data;
        if(info_list.length > 0){
          for(let i=0;i<info_list.length;i++){
            let info = info_list[i];
            if(info.title == '宅基地申请材料'){
              this.argument_sqcl.dataSource = info.fileList;
            }
            if(info.title == '不动产权籍调查成果'){
              this.argument_bdc.dataSource = info.fileList;
            }
            if(info.title == '申请人身份证明'){
              this.argument_qlr.dataSource = info.fileList;
            }
            if(info.title == '村级审批材料'){
              this.argument_cwh_sh.dataSource = info.fileList;
            }
            if(info.title == '乡镇审批材料'){
              this.argument_xz_sh.dataSource = info.fileList;
            }
          }
        }
        this.visible_wj = true;
        this.modalTitle_wj = '文件信息';
      }
    });

  },
  changeTab(){
    this.parms.type = null;
    let activeKey = this.activeKey_file;
    if(activeKey == '1'){
      this.search_file = [
        {key:'0.1',value:'申请表'},
        {key:'0.2',value:'承诺书'},
      ];
    }else if(activeKey == '2'){
      this.search_file = [
        {key:'1.1',value:'户口本'},
        {key:'1.2',value:'申请人身份证明'},
      ];

    }else if(activeKey == '3'){
      this.search_file = [
        {key:'2.1',value:'不动产测量报告书'},
        {key:'2.2',value:'地籍调查表'},
        {key:'2.3',value:'房产分户图'},
        {key:'2.4',value:'房屋调查表'},
        {key:'2.5',value:'界址点成果表'},
        {key:'2.6',value:'实景照片'},
        {key:'2.7',value:'指界通知书'},
        {key:'2.8',value:'宗地图'},
      ];
    }else if(activeKey == '4'){
      this.search_file = [
        {key:'3.1',value:'会议记录'},
        {key:'3.2',value:'公示情况'},
      ];
    }else if(activeKey == '5'){
      this.search_file = [
        {key:'4.1',value:'审批表'},
        {key:'4.2',value:'规划许可证'},
        {key:'4.3',value:'宅基地批准书'},
        {key:'4.4',value:'验收意见表'},
        {key:'4.5',value:'会议纪要'},
        {key:'4.6',value:'农转建'},
      ];
    }
  },
  handleChange({file,fileList,event}){
    if(file.status === "uploading"){ //status: "uploading"
      this.$refs.maskLayer.openShow();
    }
    else if(file.status==="done"){//status: "done"
      let flag = file.response.flag;
      this.$refs.maskLayer.closeShow();
      if(flag){
        this.fileList = [];
        let data = {
          id:file.response.data.id,
          name:file.response.data.name,
          type:file.response.data.type,
          format:file.response.data.format,
          path:file.response.data.path,
          suffix:file.response.data.suffix,
        };
        if(this.activeKey_file == '1'){
          this.argument_sqcl.dataSource.push(data);
        }else if(this.activeKey_file == '2'){
          this.argument_qlr.dataSource.push(data);
        }else if(this.activeKey_file == '3'){
          this.argument_bdc.dataSource.push(data);
        }else if(this.activeKey_file == '4'){
          this.argument_cwh_sh.dataSource.push(data);
        }else if(this.activeKey_file == '5'){
          this.argument_xz_sh.dataSource.push(data);
        }
        this.$message.success(file.response.msg);
      }else{
        this.$message.error(file.response.msg);
      }

    }
    else {
      this.$refs.maskLayer.closeShow();
    }
  },
  downloadFile(type,arg){
    let data = {
      webPath:arg.path
    };
    file_api.downloadByWebPath.fileDownload(this,data,arg.name);
  },
  online_preview(type,arg){
    this.$refs.onlinePreview.setFileUrl(arg.path);
  },
  deleteData(index,arg){
    let parms = {
      id:arg.id
    }
    file_api.delete.requestPOSTUrlParam(this,parms,res=>{
      if(res.data.flag){
        this.$message.success(res.data.msg);
        if(this.activeKey_file == '1'){
          this.argument_sqcl.dataSource.splice(arg.index,1);
        }else if(this.activeKey_file == '2'){
          this.argument_qlr.dataSource.splice(arg.index,1);
        }else if(this.activeKey_file == '3'){
          this.argument_bdc.dataSource.splice(arg.index,1);
        }else if(this.activeKey_file == '4'){
          this.argument_cwh_sh.dataSource.splice(arg.index,1);
        }else if(this.activeKey_file == '5'){
          this.argument_xz_sh.dataSource.splice(arg.index,1);
        }
      }
      else{
        this.$message.error(res.data.msg);
      }
    })
  },
},
}
</script>

<style scoped>

</style>