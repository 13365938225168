<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="true"  />
  <a-form-item label="内容">
    <wangeditor-info-comp ref="wangeditorComp" />
  </a-form-item>
</template>

<script>
import ArForm from "../../../components/form/ArForm";
import wangeditorInfoComp from "../../common/components/wangeditorInfoComp";

export default {
  name: "informationShow",
  components:{
    ArForm,wangeditorInfoComp
  },
  inject:["arguments"],
  computed:{
    argument(){
      this.setHtmle();
      return  this.arguments.value;
    },
  },
  methods:{
    setHtmle(){
      setTimeout(()=>{
        this.$refs.wangeditorComp.setHtml(this.arguments.value.formData.content);
        this.$refs.wangeditorComp.disableHandler();
      },200)
    }
  },
}
</script>

<style scoped>

</style>