/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class zAcceptanceComments_item extends Table{
    constructor() {
        super();
        this.name = "zAcceptanceComments_item";
        this.CNName = "宅基地验收意见表";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("宅基地申请id","applyId",ColumnType.String,false).setVisible(false),
            new Column("开工日期","startDate",ColumnType.Date,true),
            new Column("竣工日期","completionDate",ColumnType.Date,true),
            new Column("实用宅基地面积","actuallyHomesteadArea",ColumnType.Number,true),
            new Column("实际房基占地面积","actuallyHomesteadGroundArea",ColumnType.Number,true),
            new Column("竣工层数","actuallyBuildingFloor",ColumnType.Number,true),
            new Column("竣工高度","actuallyBuildingHeight",ColumnType.Number,true),
            new Column("拆旧退还情况","actuallyHomesteadHandleType",ColumnType.dictionary,true)
                .setDictionary("actually_homestead_handle_type"),
            new Column("备注","remarks",ColumnType.TextField,true),
        ];

    }
}