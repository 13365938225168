/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../assets/tables/parts/table";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type"

export default class file_item extends Table{
    constructor() {
        super();
        this.name = "file_item";
        this.CNName = "文件信息";
        this.columnArray = [
            new Column("文件id","id",ColumnType.String,false).setVisible(false),
            new Column("文件名","name",ColumnType.String,true).setTableView(),
            new Column("文件类型","type",ColumnType.dictionary,true).setTableView()
            .setDictionary("file_type"),
            new Column("文件路径","path",ColumnType.String,false).setVisible(false),
            new Column("文件格式","format",ColumnType.String,true).setTableView(),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}