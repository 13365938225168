<!--
* @description：资格名录库
* @author:rzl
* @date:2022/3/15 11:30
-->

<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <zgry-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="jtcyMain(index,record)">
              <template #icon><Team title="家庭成员信息"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="删除户主，户主下的家庭成员会一并删除，确定要删除?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>

    <!-- 批量导入数据 start -->
    <a-modal v-model:visible="visible"
             :maskClosable="false"
             :title="modalTitle"  width="45%" :destroyOnClose="true">
      <div class="ant-row">
        <div class="ant-col-12">
          <a-form-item  label="年度：" style="margin-left: 18%;">
            <a-input v-model:value="year" />
          </a-form-item>
        </div>
        <div class="ant-col-12">
          <a-form-item  label="导入类型：" style="margin-left: 18%;">
            <a-select
                v-model:value="type"
                label-in-value
                :options="importType"
            >
            </a-select>
          </a-form-item>
        </div>
      </div>

      <div class="ant-row">
        <div class="ant-col-24">
          <!--辖区范围村 start-->
          <a-form-item  label="辖区范围村：" style="margin-left: 4%;">
            <a-tree-select
                v-model:value="villageIds"
                :tree-data="treeData"
                allow-clear
                :show-checked-strategy="SHOW_PARENT"
                placeholder="请选择"
                tree-checkable
            />
          </a-form-item>
          <!--辖区范围村 end-->

        </div>
      </div>

      <div class="ant-row">
        <div class="ant-col-24">
          <a-form-item  label="附件：" style="margin-left: 9%;">
            <div style="margin-left: -85%">
              <a-upload
                  v-model:file-list="fileList"
                  name="file"
                  :data='fileParms'
                  :action="actionUrl"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                  :showUploadList="false"
                  :accept="accept"
                  withCredentials
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  文件上传
                </a-button>
              </a-upload>
            </div>
      </a-form-item>
        </div>
      </div>

      <div class="ant-row" >
        <span style="margin-left: 9%;margin-top: 2%;color: red;">**选择“覆盖”的话，会将原资格名录全部清除，替换为上传数据</span>
      </div>

      <template #footer>
        <a-button key="back" @click="visible =false">取消</a-button>
      </template>
    </a-modal>
    <!-- 批量导入数据 end -->

  </div>
  <mask-layer ref="maskLayer"/>
</template>

<script>
import { TreeSelect } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;

import Icons from "../../components/Icons";
import ArBaseTable from "../../components/table/ArBaseTable";
import zgryToolBar from "./components/zgryToolBar";
import drawerComponent from "./components/drawerComponent";
import zgry_item from "./config/zgmlk_item";
import zgmlk_api from "./config/zgmlk_api";
import file_api from "../common/config/file_api";
import maskLayer from "../common/components/maskLayer";

export default {
  name: "zgry_main",
  mixins:[Icons],
  components:{
    ArBaseTable,zgryToolBar,drawerComponent,maskLayer
  },
  /**阶段**/
/*  setup() {
    const treeValue = ref();
    watch(treeValue, () => {
    });
    return {
      treeValue,
      SHOW_PARENT,
    };
  },*/
  data(){
    return{
      SHOW_PARENT,

      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new zgry_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        formColumns:new zgry_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },

      // 批量导入数据
      visible:false,
      modalTitle:null,

      // 部门
      treeData:[],

      // 导入类型
      importType:[
        {
          value: '覆盖',
          label: '覆盖',
        },
        {
          value: '增加',
          label: '增加',
        },
      ],

      /** 文件上传 start **/
      accept:".xlsx",//Vimage/png,image/jpeg
      file:null,
      fileList:[],
      previewVisible:false,
      actionUrl:zgmlk_api.dataImport.url,
      baseImageUrl:"d:",

      type:null,
      year:null,
      villageIds:null,

      fileParms:{
        type:null,
        year:null,
        villageIds:null,
      }
      /** 文件上传 end **/

    }
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      zgmlk_api.queryAll_village.requestPOST(this,null,res=>{
        if(res.data.flag){
          let data = res.data.data
          /**结果封装成树**/
          this.treeData = this.convertToTreedata(data);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })

      let parms = {};
      this.argument.loading = true;
      zgmlk_api.queryPageForHouseholder.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });

    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.dataSource = [];
          let parms ={
            queryText:arg.queryText
          }
          zgmlk_api.queryPageForHouseholder.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add :{
          this.argument.formData = {};
          this.$refs.local_tabs.openDrawer("新增户主信息","add",true,this.argument);
          break;
        }
        case this.$eventTags.downloads:{
          zgmlk_api.downloadTemplet.fileDownloads(this,'资格名录库模板');
          break;
        }
        case this.$eventTags.batchImport:{
          this.year = null;
          this.type = null;
          this.villageIds = null;
          this.visible = true;
          this.modalTitle = '批量导入数据';
          break;
        }
      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      zgmlk_api.queryPageForHouseholder.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看户主信息","show",false, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑户主信息","edit",true,this.argument);
    },
    jtcyMain(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer(arg.name+":家庭成员信息","jtcy_main",false,this.argument);
    },
    deleteData(index,arg){
      let data = {ids:arg.id};
      zgmlk_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    /**
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedata(data){
      var returnData = [];
      //遍历数据
      for(var i = 0; i < data.length; i++){
        var tempObj = {
          title : data[i].name,
          value : data[i].id,
          key : data[i].id
        };
        //push到数据数组中
        returnData.push(tempObj);
      }
      return returnData;
    },
    beforeUpload(){
      if(this.year == null || this.year == ''){
        this.$message.info("请输入年度！");
        return false;
      }
      if(this.type == null || this.type == ''){
        this.$message.info("请选择导入类型！");
        return false;
      }
      let villageIds = this.villageIds;
      if(villageIds == null || villageIds.length == 0){
        this.$message.info("请至少选择一个辖区范围村！");
        return false;
      }

      this.fileParms.type = this.type.key;
      this.fileParms.year = this.year;
      this.fileParms.villageIds = this.villageIds;
      return true;

    },
    handleChange({file,fileList,event}){
      if(file.status === "uploading"){ //status: "uploading"
        this.$refs.maskLayer.openShow();
      }
      else if(file.status==="done"){//status: "done"
        let flag = file.response.flag
        this.$refs.maskLayer.closeShow();
        this.visible = false;
        if(flag){
          this.fileList = [];
          let data = {
            id:file.response.data.id,
            name:file.response.data.name,
            type:file.response.data.type,
            format:file.response.data.format,
            path:file.response.data.path,
          };
          this.$message.success(file.response.msg);
          this.visible = false;
          this.init();
        }else{
          this.$message.error(file.response.msg);
          let code = file.response.code;
          if(code == '531'){
            let data = {
              webPath:file.response.data
            };
            file_api.downloadByWebPath.fileDownload(this,data,"资格名录库导入错误");
          }

        }

      }
      else {
        this.$refs.maskLayer.closeShow();
      }
    },
  },
}
</script>