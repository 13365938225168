<!--
*@description：角色主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <role-tool-bar  ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="menuRole(index,record)">
              <template #icon><Authorize title="授权菜单"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>

  <!--菜单授权 start-->
  <a-modal v-model:visible="visible_menu"
           :title="modalTitle_menu" width="35%" style="overflow-y: auto"
           :destroyOnClose="true"
  >

<div style="height: 500px;overflow-y: auto">
  <a-tree
      checkable
      :tree-data="treeData"
      :blockNode="true"
      :defaultExpandAll="true"
      v-model:checkedKeys="checkedKeys"
      @check="handleCheckMenu"
  />
</div>


    <template #footer>
      <a-button key="back" @click="visible_menu =false">取消</a-button>
      <a-button key="submit" type="primary" @click="submit" >提交</a-button>
    </template>
  </a-modal>
  <!--菜单授权 end-->
</template>

<script>
    import roleToolBar from "./components/roleToolBar";
    import Icons from "../../../components/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import drawerComponent from "./components/drawerComponent";

    import role_api from "./config/role_api";
    import role_item from "./config/role_item";

    export default {
      name: "role_main",
      mixins:[Icons],
      components:{
        roleToolBar,ArBaseTable,drawerComponent
      },
      data(){
          return{
            argument:{
              dataSource:null,//提供table的DataSource
              tableColumns:new role_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
              loading:false,//table的loading状态
              currentIndex:null,
              formColumns:new role_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
              formData:{},
            },
            pageParam:{
              page: 1,
              limit: 10,
            },

            roleId:null,
            visible_menu:false,
            modalTitle_menu:null,
            treeData:[],
            checkedKeys:[],
            check_tree:[],
          }
      },
      created() {
        this.init()
      },

      methods:{
        init(){
          this.checkedKeys = [];
          /**树**/
          role_api.queryTree_menu.requestPOST(this,null,res=>{
            if(res.data.flag){
              let data = res.data.data

              /**结果封装成树**/
              this.treeData = this.$common.convertToTreedatas2(data);

            }
            else{
              this.$message.error(res.data.msg);
            }
          })

          let parms = {};
          this.argument.loading = true;
          role_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
              if(res.data.flag){
                this.argument.dataSource = res.data.data.resultList;
                this.$refs.pstable.setTotalSize(res.data.data.total);
              }
          });

        },
        eventHandle({type,arg}){
            switch (type) {
                case this.$eventTags.search:{
                    this.argument.loading = true;
                    let parms ={
                      roleState:arg.roleState,
                      name:arg.name
                    }
                    role_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
                      this.argument.loading =false;
                      if(res.data.flag){
                        this.argument.dataSource = res.data.data.resultList;
                        this.$refs.pstable.setTotalSize(res.data.data.total);
                      }
                    });
                    break;
                }
                case this.$eventTags.refresh:{
                  this.$refs.local_tabs.onClose();
                  this.init();
                  break;
                }
                case this.$eventTags.add :{
                  this.argument.formData = {};
                  this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
                  break;
                }
            }
        },
        pageChange(arg){
          this.pageParam.page = arg.page;
          this.pageParam.limit = arg.limit;
          let parms = this.$refs.toolBar.getParms();
          this.argument.loading = true;
          role_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
        },
        showData(index,arg){
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
        },
        editData(index,arg){
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
        },
        deleteData(index,arg){
          let data = {id:arg.id};
            role_api.delete.requestPOSTUrlParam(this,data,res=>{
            if(res.data.flag){
              this.argument.dataSource.splice(index,1);
              this.$message.success(res.data.msg);
              this.init();
            }
            else{
              this.$message.error(res.data.msg)
            }
          })
        },
        menuRole(index,arg){
          this.checkedKeys = [];
          this.roleId = arg.id

          role_api.queryMenuByRole_menu.requestPOSTUrlParam(this,{roleId:arg.id},res=>{
            if(res.data.flag){
              let data = res.data.data;
              if(data != null && data.length > 0){
                for(let i=0;i<data.length;i++){
                  let children = data[i].children
                  if(children != null && children.length > 0){
                    for(let j=0;j<children.length;j++){
                      this.checkedKeys.push(children[j].menuId)
                    }
                  }else{
                    this.checkedKeys.push(data[i].menuId)
                  }

                }
              }

            }

              this.visible_menu = true;
              this.modalTitle_menu = arg.name+":菜单授权";

          });
        },
        handleCheckMenu(checkedKeys, e){
          const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
          this.check_tree = checkedKeysResult;
        },
        submit(){
            if(this.checkedKeys == null){
              this.$message.error("请选择要授权的菜单");
              return;
            }
            let data = {
              menuIds:this.check_tree,
              roleId:this.roleId,
            }

          role_api.authorization_save.requestPOSTUrlParam(this,data,res=>{
            if(res.data.flag){
              this.visible_menu = false;
              this.$message.success(res.data.msg);
            }
            else{
              this.$message.error(res.data.msg);
            }
          });
        },

      },

    }
</script>

<style scoped>

</style>