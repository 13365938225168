/**
 * 字典颜色配置
 * @description：
 * @author:rzl
 * @date:2021/9/1 11:04
 **/
const color = {
    colors:["cyan","orange","purple","pink","blue","green","#4169E1","#B0E0E6",
    "#AFEEEE","#40E0D0","#7FFFD4","#DAA520","#FFE4B5","#FFA500",
    "#CD853F","#FF6347","#F08080","#696969"],
}
export default color;