<!--
* @program: tyh-oa 
* @author: ly
* @description: typeNumber 
* @create: 2021-03-23 10:01
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-input-number v-if="editable" style="width:100%" v-model:value="record[column.dataIndex]" @change="columnTrigger" />
        <div style="text-align: center" v-else >{{record[column.dataIndex]}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeNumber",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.dataIndex]?this.record[this.column.dataIndex]:0;
            }
        },
    }
</script>

<style scoped>

</style>