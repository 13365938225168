<!--
*@description：宅基地申请
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div >
    <a-steps :current="current" :showInit="showInit">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    <div class="steps-content" style="margin-top: 15px">
      <div v-show="current == 0">
        <ar-form  ref="form_zjd" :argument="argument_zjd" :viewModel="true"/>
      </div>
      <div v-show="current == 1">
        <ar-form ref="form_qlr" :argument="argument_qlr" :viewModel="true"/>
      </div>
      <div v-show="current == 2">
        <ar-base-table ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 500,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showData(index,record)">
                <template #icon><Show title="查看"/></template>
              </a-button>
            </div>
          </template>+
        </ar-base-table>
      </div>

    </div>
    <div class="steps-action">
      <a-button v-show="current > 0" style="margin-left: 8px" @click="prev">
        上一步
      </a-button>
      <a-button v-show="current < steps.length - 1" type="primary" @click="next">
        下一步
      </a-button>

    </div>
  </div>

  <jtcy-show ref="jtcy_show"/>
</template>

<script>
import Icons from "../../../../components/Icons";
import ArForm from "../../../../components/form/ArForm";
import ArBaseTable from "../../../../components/table/ArBaseTable";

import zjdApply_item from "../../common/config/zjd/zjdApply_item";
import hzInfo_item from "../../common/config/user/hzInfo_item";
import zjdInfo_api from "../config/zjdInfo_api";
import jtcyShow from "../../../fdyt/common/point/qlr/jtcyShow";
import zjdInfo_item from "../config/zjdInfo_item";
import jtcy_item from "../../common/config/user/jtcy_item";

export default {
name: "zjd_show",
mixins:[Icons],
inject:["arguments"],
components:{
  ArForm,ArBaseTable,jtcyShow
},
data(){
  return{
    current: 0,
    steps: [
      {
        title: '宅基地申请信息',
      },
      {
        title: '户主信息',
      },
      {
        title: '家庭成员',
      },
    ],

    argument_zjd:{
      formColumns:new zjdApply_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    argument_qlr:{
      formColumns:new hzInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    argument:{
      dataSource:[],//提供table的DataSource
      tableColumns:new jtcy_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      loading:false,//table的loading状态
      currentIndex:null,
      formColumns:new jtcy_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    pageParam:{
      page: 1,
      limit: 10,
    },

  }
},
computed:{
  showInit(){
    this.init();
    return null;
  }
},
methods:{
  init(){
    this.argument.dataSource = [];
    zjdInfo_api.get_apply.requestPOSTUrlParam(this,{id:this.arguments.value.formData.id},res=>{
      if(res.data.flag){
        let info = res.data.data;
        this.argument_zjd.formData = info.zApply;
        this.argument_qlr.formData = info.zApply;
        this.argument_qlr.formData.name = info.zApply.householderName;

        this.argument.dataSource = info.familyMembers;
      }
      else{
        this.$message.error(res.data.msg);
      }
    })
  },
  next() {
    this.current++;
  },
  prev() {
    this.current--;
  },
  pageChange(arg){
    this.pageParam.page = arg.page;
    this.pageParam.limit = arg.limit;
    let parms = this.$refs.toolBar.getParms();
    this.argument.loading = true;
    zjdInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
      this.argument.loading =false;
      if(res.data.flag){
        this.argument.dataSource = res.data.data.resultList;
        this.$refs.pstable.setTotalSize(res.data.data.total);
      }
    });
  },
  showData(index,arg){
    this.argument.formData = arg;
    this.$refs.jtcy_show.openJtcyShow(this.argument);
  },
}
}
</script>

<style scoped>

</style>