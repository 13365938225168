<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="false"  />
  <a-form-item label="内容">
    <wangeditor-edit-comp ref="wangeditorComp"/>
  </a-form-item>

</template>

<script>
import ArForm from "../../../components/form/ArForm";
import wangeditorEditComp from "../../common/components/wangeditorEditComp";
export default {
  name: "informationEdit",
  inject:["arguments"],
  components:{
    ArForm,wangeditorEditComp
  },
  computed:{
    argument(){
      return  this.arguments.value;
    },
  },
  created() {
    setTimeout(()=>{
      this.$refs.wangeditorComp.setHtml(this.arguments.value.formData.content);
    },200)
  },
  methods:{
    getParms(){
      let wangeDitorText = this.$refs.wangeditorComp.getHtml();
      let data = this.$refs.bill_form.getSource();
      data.content = wangeDitorText;
      return data;
    },
  }
}
</script>

<style scoped>

</style>