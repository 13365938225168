/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class homesteadApprovalletter_item extends Table{
    constructor() {
        super();
        this.name = "homesteadApprovalletter_item";
        this.CNName = "宅基地批准书";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("宅基地申请id","applyId",ColumnType.String,false).setVisible(false),
           /* new Column("文号","docNumber",ColumnType.String,true),*/
            new Column("土地所有权人","landOwner",ColumnType.String,true),
            new Column("有效期起始年月","startDate",ColumnType.Date,true)
                .setDateFormat("YYYY/MM"),
            new Column("有效期结束年月","endDate",ColumnType.Date,true)
                .setDateFormat("YYYY/MM"),
            new Column("发证机关","licencedOrg",ColumnType.String,true),
            new Column("发证时间","licencedDate",ColumnType.Date,true),
            new Column("备注","remarks",ColumnType.TextField,true),
        ];

    }
}