/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class planLicence_item extends Table{
    constructor() {
        super();
        this.name = "planLicence_item";
        this.CNName = "宅基地规划许可证信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("宅基地申请id","applyId",ColumnType.String,false).setVisible(false),
           /* new Column("文号","docNumber",ColumnType.String,true),*/
            new Column("建设项目名称","projectName",ColumnType.String,true),
            new Column("建设规模","scale",ColumnType.String,true),
            new Column("发证机关","licencedOrg",ColumnType.String,true),
            new Column("发证日期","licencedDate",ColumnType.Date,true),
            new Column("备注","remarks",ColumnType.TextField,true),
        ];

    }
}