<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
  >

    <ar-form  ref="bill_form" :argument="argument"  :viewModel="true"  />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
    import ArForm from "../../../../../components/form/ArForm";

    export default {
      name: "qlrShow",
      components:{
        ArForm
      },
      data(){
        return{
          visible:false,
          modalTitle:null,
          argument:null,
        }
      },
      methods:{
        /**
         * 打开
         * @param argument  权利人信息
         */
        openQlrShow(argument){
            this.visible = true;
            this.modalTitle = "权利人信息";
            this.argument = argument;
        }
      },
    }
</script>

<style scoped>

</style>