<!--
* 一张图
* @description：
* @author:rzl
* @date:2021/7/16 9:21
-->

<template>
  <div id="map" ref="rootmap" class="divTdtMap">
    <!-- 鼠标位置 -->
    <div id="mouse-position"></div>
    <!-- 查询条件 -->
    <map-tool-bar ref="mapToolBar" @event="mapQuery" />

    <!-- 查询结果list -->
    <zjd-list ref="zjdList" @event="mapQuery"/>
    <!-- 查询结果list -->

    <!-- 查询结果展示 -->
    <zjd-show ref="zjdShow"  @event="mapQuery"/>
    <!-- 查询结果展示 -->

    <!--合规分析 start-->
    <hgfx-tk  ref="hgfxTk"/>
    <!--合规分析 end-->

  </div>
</template>

<script>
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
import {ScaleLine,MousePosition,OverviewMap} from 'ol/control'
import Feature from 'ol/Feature'
import {fromLonLat,get as getProjection,Projection,transform} from 'ol/proj'
import {createStringXY} from 'ol/coordinate'
import Draw from 'ol/interaction/Draw';
import {Style,Fill,Stroke,Circle as CircleStyle} from 'ol/style'
import Overlay from 'ol/Overlay'
import { LineString, Point, Polygon, MultiPolygon } from "ol/geom";
import {unByKey} from 'ol/Observable';
import {getWidth,getTopLeft,getCenter} from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import WKT from 'ol/format/WKT';


import {computed} from "vue";
import APIObject from "../../assets/utils/APIObject";
import ArBaseTable from "../../components/table/ArBaseTable";

import tdt_api from "./config/tdt_api";
import mapToolBar from "./components/mapToolBar";
import zjdList from "./point/zjdList";
import zjdShow from "./point/zjdShow";
import hgfxTk from "../zjd/common/point/hgfx/hgfxTk";

export default {
  name: "home_main",
  components:{
    ArBaseTable,mapToolBar,zjdList,zjdShow,hgfxTk
  },
  provide(){
    return{
      data_qlr:computed(()=>this.data_file_sqcl),//提供table的DataSource
    }
  },
  data(){
    return{
      map:null,// 地图容器
      leyer_town:null,// geoserver发布乡镇服务
      leyer_village:null,// geoserver发布村服务
      leyer_zjd_land_plots:null,// geoserver发布宅基地线服务

      geom:null,
      layer_list:[],// 图层

      /** 测量 **/
      vector_draw:null,
      draw:null,// 定义一个交互式绘图对象
      listener:null,//定义一个事件监听
      count:0, //定义一个控制鼠标点击次数的变量

      helpTooltipElement:null,//创建一个帮助提示框对象
      helpTooltip:null,//创建一个帮助提示信息对象
      measureTooltipElement:null,//创建一个测量提示框对象
      measureTooltip:null,//创建一个测量提示信息对象
      continuePolygonMsg:null,//继续绘制多边形的提示信息
      continueLineMsg:null,//继续绘制线段的提示信息
      /** 测量 **/

      pageParam:{
        page: 1,
        limit: 10,
      },
    }
  },
  mounted() {
    this.initMap()
  },
  methods:{
    /** 初始化地图 **/
    initMap(){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }

      // 视图
      var view = new View({
        center: fromLonLat([106.7572,26.7782]),//106.6472,26.4332
        zoom:13
      });
      var TiandiMap_img = new TileLayer({
        name: "天地图影像图层",
        opacity:0.7,
        source:new WMTS({
          url:tdt_api.wmtsUrl_1,
          layer:'img',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });
      var TiandiMap_cia = new TileLayer({
        name: "天地图影像注记图层",
        source: new WMTS({
          url:tdt_api.wmtsUrl_2,
          layer:'cia',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });

      // 地图
      this.map = new Map({
        target:'map',
        layers:[
          TiandiMap_img,
          TiandiMap_cia
        ],
        /**
         * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
         * */
        loadTilesWhileAnimating: true,//将这个设置为true，默认为false
        view:view
      });

      //添加比例尺控件
      this.add_scaleLine();

      //实例化鼠标位置控件
      this.add_mousePosition();

      // 添加图层：乡镇、村、宅基地
      this.layers_list();

      // 监听singleclick事件
      this.map.on('singleclick', e=> {
        var view = this.map.getView();
        var viewResolution = view.getResolution();
        var source = this.leyer_zjd_land_plots.getSource();
        var url = source.getFeatureInfoUrl(
            e.coordinate, viewResolution, view.getProjection(),
            {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50,format_options: 'callback: callback'});

        new APIObject(url).requestUrlParam(this,{},res=>{
          // let objData = JSON.parse(res);
          let features = res.data.features

          if(features != null && features.length > 0){
            let properties = features[0].properties
            let zdId = features[0].id;
            console.log(properties)
           // console.log(zdId.split('.')[1])
            this.show_zjd_info(zdId.split('.')[1],properties.applyid);
          }
        })


      })

    },

    /** 添加比例尺控件 **/
    add_scaleLine(){
      var scaleLineControl = new ScaleLine({
        units: 'metric',
        target: 'scalebar',
        className: 'ol-scale-line'
      });
      this.map.addControl(scaleLineControl);
    },
    /** 实例化鼠标位置控件 **/
    add_mousePosition(){
      //实例化鼠标位置控件
      var mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),//坐标格式
        //地图投影坐标系
        projection: 'EPSG:4326',
        //className:'tip',
        target: document.getElementById('mouse-position'),//显示鼠标位置信息的目标容器
        undefinedHTML: '&nbsp;'//未定义坐标标记
      });

      //添加鼠标位置控件
      this.map.addControl(mousePositionControl);
    },
    /** 定位 */
    dingwei(x,y){
      // 地图动画
      // fromLonLat将一个经纬度坐标转换成当前地图投影的坐标；
      var dw = fromLonLat([x,y])

      let view = this.map.getView();
      // 先进行缩放在平移
      view.animate(
          {zoom: 10},
          {center:dw,},
      );
    },

    /***************** 测量 start ****************/
    /** 绘制测量 **/
    clChange(){
      // 清除测量所用到的元素
      this.remove_measurement();

      //定义矢量数据源
      var source = new VectorSource();
      this.vector_draw = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });
      // 将矢量图层放入map
      this.map.addLayer(this.vector_draw);

      //创建一个当前要绘制的对象
      var sketch = new Feature();


      //创建一个交互式绘图对象
      this.draw = new Draw({
        //绘制的数据源
        source: source,
        //绘制类型
        type: this.$refs.mapToolBar.get_cl_type(),
        //样式
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      //将交互绘图对象添加到地图中
      this.map.addInteraction(this.draw);
      //创建测量提示框
      this.createMeasureTooltip();
      //创建帮助提示框
      this.createHelpTooltip();


      //绘制开始事件
      this.draw.on('drawstart',  (evt)=> {
        //The feature being drawn.
        sketch = evt.feature;
        //提示框的坐标
        var tooltipCoord = evt.coordinate;

        this.listener = sketch.getGeometry().on('change',  (evt) =>{
          //The event target.
          //获取绘制的几何对象
          var geom = evt.target;
          //定义一个输出对象，用于记录面积和长度
          var output;

          if (geom instanceof Polygon) {
            this.map.removeEventListener('singleclick');
            this.map.removeEventListener('dblclick');
            this.geom = geom;
            //输出多边形的面积
            output = this.formatArea(geom);
            //获取多变形内部点的坐标
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            //输出多线段的长度
            output = this.formatLength(geom);
            this.geom = geom;
            //获取多线段的最后一个点的坐标
            tooltipCoord = geom.getLastCoordinate();
          }

          //设置测量提示框的内标签为最终输出结果
          this.measureTooltipElement.innerHTML = output;
          //设置测量提示信息的位置坐标
          this.measureTooltip.setPosition(tooltipCoord);
        });

        //地图单击事件
        this.map.on('singleclick',  (evt)=> {

          //设置测量提示信息的位置坐标，用来确定鼠标点击后测量提示框的位置
          this.measureTooltip.setPosition(evt.coordinate);
          //根据鼠标点击位置生成一个点
          var point = new Point(evt.coordinate);
          //将该点要素添加到矢量数据源中
          source.addFeature(new Feature(point));
          //点击次数增加
          this.count++;
        });

        //地图双击事件
        this.map.on('dblclick',  (evt)=> {
          var point = new Point(evt.coordinate);
          source.addFeature(new Feature(point));
        });

      }, this);
      //绘制结束事件
      this.draw.on('drawend',  (evt)=> {
        // 绘制结束之后 绘制出的点线面数据
        evt.feature.getGeometry().getCoordinates()
        this.count = 0;
        //设置测量提示框的样式
        this.measureTooltipElement.className = 'tooltip tooltip-static';
        //设置偏移量
        this.measureTooltip.setOffset([0, -7]);
        //清空绘制要素
        sketch = null;
        //清空测量提示要素
        this.measureTooltipElement = null;
        //创建测量提示框
        this.createMeasureTooltip();
        //移除事件监听
        unByKey(this.listener);
        //移除地图单击事件
        this.map.removeEventListener('singleclick');
      }, this);

    },

    /** 创建帮助提示框 **/
    createHelpTooltip() {
      //如果已经存在帮助提示框则移除
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      }
      //创建帮助提示要素的div
      this.helpTooltipElement = document.createElement('div');
      //设置帮助提示要素的样式
      this.helpTooltipElement.className = 'tooltip hidden';
      //创建一个帮助提示的覆盖标注
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left'
      });
      //将帮助提示的覆盖标注添加到地图中
      this.map.addOverlay(this.helpTooltip);
    },
    /** 创建测量提示框 **/
    createMeasureTooltip() {
      //创建测量提示框的div
      this.measureTooltipElement = document.createElement('div');
      this.measureTooltipElement.setAttribute('id', 'lengthLabel');
      //设置测量提示要素的样式
      this.measureTooltipElement.className = 'tooltip tooltip-measure';
      //创建一个测量提示的覆盖标注
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center'
      });
      //将测量提示的覆盖标注添加到地图中
      this.map.addOverlay(this.measureTooltip);
    },
    /** 格式化测量长度 **/
    formatLength(line) {
      //定义长度变量，计算平面距离
      var length = Math.round(line.getLength() * 100) / 100;
      //定义输出变量
      var output;
      //如果长度大于1000，则使用km单位，否则使用m单位
      if (length > 1000) {
        output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
      } else {
        output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
      }
      return output;
    },
    /** 格式化测量面积 **/
    formatArea(polygon) {
      //定义面积变量，获取平面面积
      var area = polygon.getArea();
      //定义输出变量
      var output;
      //当面积大于10000时，转换为平方千米，否则为平方米
      if (area > 10000) {
        output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
      } else {
        output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
      }
      return output;
    },
    /*************** 测量 end *****************/

    /** 清除绘制所用到的元素 **/
    remove_measurement() {
      //移除之前的绘制对象
      if (this.draw != null) {
        this.map.removeInteraction(this.draw);
      }
      // 移除测量矢量图层
      if (this.vector_draw != null) {
        this.map.removeLayer(this.vector_draw);
      }

      if(this.layer_list.length > 0){
        for(let i=0;i<this.layer_list.length;i++){
          this.map.removeLayer(this.layer_list[i]);
        }
      }

      // 清除帮助提示信息对象
      if (this.helpTooltip != null) {
        this.helpTooltip = null;
        this.helpTooltipElement = null;
        // 清除测量提示信息对象
        this.measureTooltip = null;
        this.measureTooltipElement = null;
        this.map.getOverlays().clear();//关键点
      }

    },
    /** 清除绘制、提示信息 ***/

    /** 添加图层 **/
    layers_list(){
      const userInfo = this.$store.getters.userInfo;
      // 区域权限id
      let area_id = userInfo.villageIds;
      var cql_cwh = '';// 村委会
      var cql_zjd = '';// 宅基地
     // console.log(area_id);
      if(area_id.length > 0){
        cql_cwh = ' id in ( ' ;
        cql_zjd = ' villageid in ( ' ;
        for(let i=0;i<area_id.length;i++){
          let areaId = area_id[i];
          if(i == 0){
            cql_cwh += '\''+areaId+'\'';
            cql_zjd += '\''+areaId+'\'';
          }else{
            cql_cwh += ','+ '\''+areaId+'\'';
            cql_zjd += ','+ '\''+areaId+'\'';
          }
        }
        cql_cwh += ' )';
        cql_zjd += ' )';
      }
      // cql = ' id in (\'1\',\'792ad3aa-5b19-4e96-a044-80aad5cf2535\',\'b2963bfd-4020-4127-bd0a-5aa45ad33d1a\')' ;
      /*this.leyer_town = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:town',// geoserver发布乡镇服务
            'STYLES': '',
            // 'CQL_FILTER': filter_parms,  // 图层属性参数绑定
          },
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_town);*/
      var longText = new Array(205).join("1234567890");
      this.leyer_village = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:village',// geoserver发布村服务
            'STYLES': '',
             'CQL_FILTER': cql_cwh,  // 图层属性参数绑定
          },
          makeTheUrlLong: longText,
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_village);
      this.leyer_zjd_land_plots = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:zjd_land_plots',// geoserver发布宅基地线服务
            'STYLES': '',
             'CQL_FILTER': cql_zjd,  // 图层属性参数绑定
          },
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_zjd_land_plots);
    },

    /** wkt处理 **/
    wkt_show(wktGeom){
      var source = new VectorSource();
      // 解析 wkt字符串
      var feature = new WKT().readFeature(wktGeom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      source.addFeature(feature);
      // wkt加入图层
      let wkt_layer = new VectorLayer({source: source});
      this.layer_list.push(wkt_layer)
      this.map.addLayer(wkt_layer);
      let view = this.map.getView();
      view.fit(feature.getGeometry(),{
        duration:1000,
        maxZoom:20,
      });

    },

    /********** 输入查询 start **************/
    mapQuery({type,arg}){
      switch (type){
        case this.$eventTags.query_text:{
          tdt_api.queryPageForHome.requestPOSTBothParam(this,{urlParam:arg,formParam:this.pageParam},res=>{
            if(res.data.flag){
              let info = res.data.data;
              this.$refs.zjdList.openZjdList(info.resultList,info.total);
            }
          });
          break;
        }
        case this.$eventTags.query_cl:{
          this.clChange();
          break;
        }
        case this.$eventTags.remove_cl:{
          this.remove_measurement();
          break;
        }
        case this.$eventTags.click_toop:{
          this.remove_measurement();
          break;
        }
        case this.$eventTags.zd_cxfx:{
          this.cxfxChange();
          break;
        }
        case this.$eventTags.remove_zd_cxfx:{
          this.remove_measurement();
          break;
        }
        case this.$eventTags.hgfx:{
          this.hgfxChange();
          break;
        }
        case this.$eventTags.shp_fx:{
          this.wkt_show(arg);
          this.wkt_query_fx(arg);
          break;
        }
        case this.$eventTags.deleteChange:{
          this.deleteChange();
          break;
        }
        case this.$eventTags.show_zjd_info:{
          this.show_zjd_info(arg.zdId,arg.applyId);
          break;
        }
        case this.$eventTags.wkt_show:{
          // 定位到选中的宅基地
          this.wkt_show(arg.geom);
          break;
        }
      }
    },

    /** 显示宗地详细信息 **/
    show_zjd_info(zdId,applyId){
      this.$refs.zjdShow.openZjdShow(zdId,applyId)
    },

    /********** 输入查询 end ***************/



    /************** 宗地分析 start ***************/
    /** 绘制分析 **/
    cxfxChange(){
      // 清除测量所用到的元素
      this.remove_measurement();

      //定义矢量数据源
      var source = new VectorSource();
      this.vector_draw = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });
      // 将矢量图层放入map
      this.map.addLayer(this.vector_draw);

      //创建一个当前要绘制的对象
      var sketch = new Feature();


      //创建一个交互式绘图对象
      this.draw = new Draw({
        //绘制的数据源
        source: source,
        //绘制类型
        type: this.$refs.mapToolBar.get_cx_type(),
        //样式
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      //将交互绘图对象添加到地图中
      this.map.addInteraction(this.draw);

      //绘制开始事件
      this.draw.on('drawstart',  (evt)=> {

        sketch = evt.feature;
        //提示框的坐标
        var tooltipCoord = evt.coordinate;

        this.listener = sketch.getGeometry().on('change',  (evt) =>{
          //The event target.
          //获取绘制的几何对象
          var geom = evt.target;
          //定义一个输出对象，用于记录面积和长度
          var output;

          if (geom instanceof Polygon) {
            this.geom = geom;
            //输出多边形的面积
            output = this.formatArea(geom);
            //获取多变形内部点的坐标
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          }
        });

        //地图单击事件
        this.map.on('singleclick',  (evt)=> {
          //根据鼠标点击位置生成一个点
          var point = new Point(evt.coordinate);
          //将该点要素添加到矢量数据源中
          source.addFeature(new Feature(point));
          //点击次数增加
          this.count++;
        });

        //地图双击事件
        this.map.on('dblclick',  (evt)=> {
          var point = new Point(evt.coordinate);
          source.addFeature(new Feature(point));
        });

      }, this);
      //绘制结束事件
      this.draw.on('drawend',  (evt)=> {

        // 绘制结束后去获取绘制图形
        var polygon = evt.feature.getGeometry();
        // 绘制的是polygon, 此处使用 WKT().writeGeometry() 方法
        //文档有其他要素方法, 可以自己查一下
        var polygonWKT = new WKT().writeGeometry(polygon,{
          dataProjection : "EPSG:4326",
          featureProjection : "EPSG:3857"
        });
        this.wkt_query_fx(polygonWKT);

        this.count = 0;
        //清空绘制要素
        sketch = null;
        //移除事件监听
        unByKey(this.listener);
        //移除地图单击事件
        this.map.removeEventListener('singleclick');
        //移除之前的绘制对象
        this.map.removeInteraction(this.draw);
        this.map.removeEventListener('dblclick');
      }, this);


    },

    /** wkt数据查询分析 **/
    wkt_query_fx(polygonWKT){
      let parms = {
        wkt:polygonWKT
      };

      tdt_api.queryPageByWkt.requestPOSTBothParam(this,{urlParam:parms,formParam:this.$refs.zjdList.getPageParam()},res=>{
        if(res.data.flag){
          let info = res.data.data;
          this.$refs.zjdList.openZjdList(info.resultList,info.total);
        }
      });


    },
    /************** 宗地分析 end ***************/


    /*************** 合规分析 start ****************/
    /** 合规分析 **/
    hgfxChange(){
      // 清除测量所用到的元素
      this.remove_measurement();

      //定义矢量数据源
      var source = new VectorSource();
      this.vector_draw = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });
      // 将矢量图层放入map
      this.map.addLayer(this.vector_draw);

      //创建一个当前要绘制的对象
      var sketch = new Feature();


      //创建一个交互式绘图对象
      this.draw = new Draw({
        //绘制的数据源
        source: source,
        //绘制类型
        type: 'Polygon',
        //样式
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      //将交互绘图对象添加到地图中
      this.map.addInteraction(this.draw);

      //绘制开始事件
      this.draw.on('drawstart',  (evt)=> {

        sketch = evt.feature;
        //提示框的坐标
        var tooltipCoord = evt.coordinate;

        this.listener = sketch.getGeometry().on('change',  (evt) =>{
          //The event target.
          //获取绘制的几何对象
          var geom = evt.target;
          //定义一个输出对象，用于记录面积和长度
          var output;

          if (geom instanceof Polygon) {
            this.geom = geom;
            //输出多边形的面积
            output = this.formatArea(geom);
            //获取多变形内部点的坐标
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          }
        });

        //地图单击事件
        this.map.on('singleclick',  (evt)=> {
          //根据鼠标点击位置生成一个点
          var point = new Point(evt.coordinate);
          //将该点要素添加到矢量数据源中
          source.addFeature(new Feature(point));
          //点击次数增加
          this.count++;
        });

        //地图双击事件
        this.map.on('dblclick',  (evt)=> {
          var point = new Point(evt.coordinate);
          source.addFeature(new Feature(point));
        });

      }, this);
      //绘制结束事件
      this.draw.on('drawend',  (evt)=> {
        // 绘制结束之后 绘制出的点线面数据
        evt.feature.getGeometry().getCoordinates();

        // 绘制结束后去获取绘制图形
        var polygon = evt.feature.getGeometry();
        // 绘制的是polygon, 此处使用 WKT().writeGeometry() 方法
        //文档有其他要素方法, 可以自己查一下
        var polygonWKT = new WKT().writeGeometry(polygon,{
          dataProjection : "EPSG:4326",
          featureProjection : "EPSG:3857"
        });
        this.showHg(polygonWKT);

        this.count = 0;
        //清空绘制要素
        sketch = null;
        //移除事件监听
        unByKey(this.listener);
        //移除地图单击事件
        this.map.removeEventListener('singleclick');
        //移除之前的绘制对象
        this.map.removeInteraction(this.draw);
        this.map.removeEventListener('dblclick');
      }, this);

    },
    /** 合规分析页面 **/
    showHg(polygonWKT){
      this.$refs.hgfxTk.openHgfxTk_wkt(polygonWKT,'zjd_land_plots');
    },
    /*************** 合规分析 end ****************/



    /**************** 清除页面元素 start ***************/
    deleteChange(){
      // 关闭list
      this.$refs.zjdList.gb_zjd_list();
      // 关闭content
      this.$refs.zjdShow.gb_zjd_content();
      // 清除绘制
      this.remove_measurement();

    },
    /**************** 清除页面元素 end ***************/

  },
}
</script>

<style scoped>
.divTdtMap {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  z-index: 0;
}

#mouse-position{
  z-index: 999;
  left: 10%;
  bottom: 8%;
  position: absolute;
  color: white;
  width: 150px;
}

</style>


