/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../assets/utils/APIObject";

const api={
    queryAll:new APIObject("/api/zQualificationRight/queryAll.do"),//宅基地资格名录库-查询所有资格权名录（宅基地申请使用）
    queryAllHome:new APIObject("/api/zQualificationRight/queryAllHome.do"),//宅基地资格名录库-查询资格权名录家庭树（宅基地申请使用）
    queryPage:new APIObject("/api/zQualificationRight/queryPage.do"),// 宅基地资格名录库-分页查询资格权名录
    save:new APIObject("/api/zQualificationRight/save.do"),// 宅基地资格名录库-分页查询资格权名录
    downloadTemplet:new APIObject("/api/zQualificationRight/downloadTemplet.do"),// 宅基地资格名录库-下载宅基地资格名录库导入模板
    delete:new APIObject("/api/zQualificationRight/delete.do"),// 宅基地资格名录库-删除
    upd:new APIObject("/api/zQualificationRight/upd.do"),//宅基地资格名录库-修改
    queryPageForHouseholder:new APIObject("/api/zQualificationRight/queryPageForHouseholder.do"),//宅基地资格名录库-分页查询资格权名录(户主)
    queryPageForFamily:new APIObject("/api/zQualificationRight/queryPageForFamily.do"),// 宅基地资格名录库-分页查询资格权名录(家庭成员)

    queryAll_village:new APIObject("/api/village/queryAll.do"),// 系统村级行政区模块-查询所有村
    dataImport:new APIObject('/api/zQualificationRight/dataImport.do'),//宅基地资格名录库-数据批量导入（excel）
};

export default api;