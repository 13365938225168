/**
 * @program: tyh-oa
 * @author: ly
 * @description: file  文件操作模块
 * @create: 2021-04-16 13:55
 **/

import APIObject from "../../../assets/utils/APIObject";

const api={
    queryGroup:new APIObject("/api/fileInfo/queryGroup.do"),//系统文件管理-查询关联id的所有文件，并分组
    get:new APIObject("/api/fileInfo/get.do"),// 系统文件管理-查询单个
    upload:new APIObject("/api/fileInfo/upload.do"),// 系统文件管理-单个文件上传
    queryAllDomination:new APIObject("/api/fileInfo/queryAllDomination.do"),//系统文件管理-查询用户可操作的关联数据文件
    delete:new APIObject("/api/fileInfo/delete.do"),//系统文件管理-删除单个

    downloadByWebPath:new APIObject("/api/fileInfo/downloadByWebPath.do"),// 系统文件管理-通过webPath下载文件

    uploadRichTextImg:'http://localhost:9090/api/common/uploadRichTextImg.do',// 通用模块-上传富文本图片
    uploadImg:new APIObject("/api/common/uploadImg.do"),// 通用模块-上传图片
};
export default api;