<!--
*@description：footer
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div class="footer non-select" >
        Copyright © 2021 贵州图云慧空间信息技术有限公司
    </div>
</template>

<script>
    export default {
        name: "oaFooter"
    }
</script>

<style scoped>

</style>