/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../assets/tables/parts/table";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type"
import qlrInfo_api from "../../qlr/qlrInfo/config/qlrInfo_api";

export default class zgmlk_jtcy_item extends Table{
    constructor() {
        super();
        this.name = "zgmlk_jtcy_item";
        this.CNName = "资格名录库家庭成员信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("所属村","villageName",ColumnType.String,false).setVisible(false),
            new Column("所属村","villageId",ColumnType.IdReplaceXmgd,true)
                .setIdReplaceXmgdObject("villageName",{api:qlrInfo_api.area_findByUser,tag:"villageName",
                    options:["villageId"],keyValue:["id","name"]}),
            new Column("姓名","name",ColumnType.String,true).setTableView(120),
            new Column("身份证号","idCard",ColumnType.String,true).setTableView(),
            new Column("性别","sex",ColumnType.dictionary,true).setTableView(80)
                .setDictionary("XB"),
           /* new Column("是否为户主","householder",ColumnType.dictionary,true)
                .setDictionary("is_whether"),*/
            new Column("年龄","age",ColumnType.Number,true),
            new Column("联系电话","phone",ColumnType.String,true),
            new Column("户口所在地","householdRegister",ColumnType.String,true),
            new Column("与户主关系","familyRelation",ColumnType.dictionary,true)
                .setDictionary("household_relation"),
            new Column("认定年度","year",ColumnType.String,true),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}