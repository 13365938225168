<!--
* @description：三到场
* @author:rzl
* @date:2022/1/12 14:59
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="65%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-tabs v-model:activeKey="activeKey" tab-position="left" @change="tabsChange">

        <a-tab-pane key="1" tab="开工丈量放线" forceRender="true">
          <div class="ant-row" style="height: 250px">
            <ArBaseTable ref="pstable"  :argument="argument_1" :view-model="true" :pagination="false" style="width: 100%;" :scroll="{ x: 600,y:450}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="showData(index,record)">
                    <template #icon><Show title="查看"/></template>
                  </a-button>
                </div>
              </template>
            </ArBaseTable>
          </div>

          <a-divider orientation="left">开工丈量放线详情
            <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
              <template #icon><Reset /></template>
            </a-button>
          </a-divider>
          <div class="ant-row" style="height: 300px">
            <sdc-info ref="sdcInfo1"/>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2" tab="建设中巡查" forceRender="true">
          <div class="ant-row" style="height: 250px">
            <ArBaseTable ref="pstable"  :argument="argument_2" :view-model="true" :pagination="false" style="width: 100%;" :scroll="{ x: 600,y:450}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="showData(index,record)">
                    <template #icon><Show title="查看"/></template>
                  </a-button>
                </div>
              </template>
            </ArBaseTable>
          </div>
          <a-divider orientation="left">建设中巡查详情
            <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
              <template #icon><Reset /></template>
            </a-button>
          </a-divider>
          <div class="ant-row" style="height: 300px">
            <sdc-info ref="sdcInfo2"/>
          </div>
        </a-tab-pane>

        <a-tab-pane key="3" tab="竣工验收" forceRender="true">
          <div class="ant-row" style="height: 250px">
            <ArBaseTable ref="pstable"  :argument="argument_3" :view-model="true" :pagination="false" style="width: 100%;" :scroll="{ x: 600,y:450}">
              <template v-slot:action="{index,record}">
                <div style="justify-content: center;;display: flex;">
                  <a-button type="link" @click="showData(index,record)">
                    <template #icon><Show title="查看"/></template>
                  </a-button>
                </div>
              </template>
            </ArBaseTable>
          </div>
          <a-divider orientation="left">竣工验收详情
            <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
              <template #icon><Reset /></template>
            </a-button>
          </a-divider>
          <div class="ant-row" style="height: 300px">
            <sdc-info ref="sdcInfo3"/>
          </div>
        </a-tab-pane>

      </a-tabs>
    </div>

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

</template>

<script>
import Icons from "../../../../../components/Icons";
import ArForm from "../../../../../components/form/ArForm";
import ArBaseTable from "../../../../../components/table/ArBaseTable";
import sdc_item from "../../config/sdc/sdc_item";
import sdcInfo from "./sdcInfo";
import sdc_api from "../../config/sdc/sdc_api";
import {getUrl} from "/src/assets/utils/general";

export default {
  name: "documentationTk",
  mixins:[Icons],
  components:{
    ArForm,ArBaseTable,sdcInfo
  },
  data(){
    return{
      visible:false,
      modalTitle:null,
      labelCol:{ span: 6 },
      wrapperCol:{ span: 16 },
      activeKey:"1",

      applyId:null,// 宅基地申请id
      zjd_geom:null,// 宅基地红线
      type:null,
      argument_1:{
        dataSource:null,//提供table的DataSource
        tableColumns:new sdc_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        formData:{},
        formColumns:new sdc_item().getFormColumns(),
      },
      argument_2:{
        dataSource:null,//提供table的DataSource
        tableColumns:new sdc_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        formData:{},
        formColumns:new sdc_item().getFormColumns(),
      },
      argument_3:{
        dataSource:null,//提供table的DataSource
        tableColumns:new sdc_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        formData:{},
        formColumns:new sdc_item().getFormColumns(),
      },

    }
  },
  methods:{
    closeTk(){
      this.visible = false;
    },
    /**
    * @Description: 打开三到场
    * @params: applyId：宅基地申请id;zjd_geom:宅基地红线
    * @return:
    * @Author: rzl
    * @Date: 2022/4/27
    */
    openTk(applyId,zjd_geom){
      this.visible = true
      this.modalTitle = "三到场";
      this.activeKey = "1";
      this.type = "1";
      this.applyId = applyId;
      this.zjd_geom = zjd_geom;
      this.queryInfo("1");
    },
    tabsChange(){
      this.type = this.activeKey;
      this.queryInfo(this.activeKey);
    },
    /**
    * @Description: 查询三到场列表信息
    * @params:
    * @return:
    * @Author: rzl
    * @Date: 2022/4/27
    */
    queryInfo(type){
      let parms = {
        applyId:this.applyId,
        type:type,
      };
      sdc_api.queryAllByApplyId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          if(type == '1'){
            this.argument_1.dataSource = res.data.data;
          }else if(type == '2'){
            this.argument_2.dataSource = res.data.data;
          }else if(type == '3'){
            this.argument_3.dataSource = res.data.data;
          }
        }
      })
    },
    showData(index,arg){
      let type = this.type;
      let parms ={
        id:arg.id,
      };
      let file_list = [];
      sdc_api.queryAllFileById.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          let list = res.data.data;
          if(list.length > 0){
            for(let i=0;i<list.length;i++){
              file_list.push(getUrl(list[i].path));
            }
          }
          let explain = arg.explain;
          if(type == '1'){
            this.argument_1.formData = arg;
            this.$refs.sdcInfo1.showInfo(type,this.argument_1.formData,this.zjd_geom,file_list);
          }else if(type == '2'){
            this.argument_2.formData = arg;
            this.$refs.sdcInfo2.showInfo(type,this.argument_2.formData,this.zjd_geom,file_list);
          }else if(type == '3'){
            this.argument_3.formData = arg;
            this.$refs.sdcInfo3.showInfo(type,this.argument_3.formData,this.zjd_geom,file_list);
          }
        }
      })

    },
    refresh(){
      let type = this.type;
      switch (type){
        case "1":{
          this.$refs.sdcInfo1.closeShow();
          break;
        }
        case "2":{
          this.$refs.sdcInfo2.closeShow();
          break;
        }
        case "3":{
          this.$refs.sdcInfo3.closeShow();
          break;
        }
      }
    },
  },
}
</script>
