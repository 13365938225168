/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"
import qlrInfo_api from "../../../../qlr/qlrInfo/config/qlrInfo_api";

export default class zjdApply_item extends Table{
    constructor() {
        super();
        this.name = "zjdApply_item";
        this.CNName = "宅基地申请信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("房地一体宗地id","fLandPlotsId",ColumnType.String,false).setVisible(false),
            new Column("宅基地宗地id","zLandPlotsId",ColumnType.String,false).setVisible(false),
            new Column("户主名称","householderName",ColumnType.String,false).setTableView().setVisible(false),
            new Column("所属村","villageName",ColumnType.String,false).setTableView().setVisible(false),
            new Column("所属村","villageId",ColumnType.IdReplaceXmgd,true)
                .setIdReplaceXmgdObject("villageName",{api:qlrInfo_api.area_findByUser,tag:"villageName",
                    options:["villageId"],keyValue:["id","name"]}),
            new Column("审核状态","auditStatus",ColumnType.dictionary,true).setTableView(150).setVisible(false)
                .setDictionary("audit_status"),
            new Column("现宅基地面积","currentHomesteadArea",ColumnType.String,true),
            new Column("现建筑面积","currentBuildingArea",ColumnType.String,true),
            new Column("现权属证书号","currentCertificateNo",ColumnType.String,true),
            new Column("地类","category",ColumnType.dictionary,true)
                .setDictionary("homestead_category")
                .setTriggerOtherColumn('304',
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="otherCategory"})[0].setVisible(true);
                    },
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="otherCategory"})[0].setVisible(false);
                    }
                ),
            new Column("其他地类名称","otherCategory",ColumnType.String,true),
            new Column("现宅基地处置类型","currentHomesteadHandleType",ColumnType.dictionary,true).setTableView()
                .setDictionary("current_homestead_handle_type")
                .setTriggerOtherColumn("1",
                    (formData,cols)=>{
                        cols.filter(col=>{return col.dataIndex==="currentHomesteadKeepArea"})[0].setVisible(true);
                        cols.filter(col=>{return col.dataIndex==="currentHomesteadHandleOther"})[0].setVisible(false);
                    },
                    (formData,cols)=>{
                        if(formData["currentHomesteadHandleType"] === '3'){
                            cols.filter(col=>{return col.dataIndex==="currentHomesteadKeepArea"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="currentHomesteadHandleOther"})[0].setVisible(true);
                        }else{
                            cols.filter(col=>{return col.dataIndex==="currentHomesteadKeepArea"})[0].setVisible(false);
                            cols.filter(col=>{return col.dataIndex==="currentHomesteadHandleOther"})[0].setVisible(false);
                        }
                    }
                ),
            new Column("现宅基地保留面积","currentHomesteadKeepArea",ColumnType.String,true).setVisible(false),
            new Column("现宅基地其他处置方法","currentHomesteadHandleOther",ColumnType.String,true).setVisible(false),
            new Column("拟申请宅基地面积","homesteadArea",ColumnType.String,true).setTableView(),
            new Column("拟申请宅基地占地面积","homesteadGroundArea",ColumnType.String,true).setTableView(),
            new Column("地址","address",ColumnType.String,true),
            new Column("东至","east",ColumnType.String,true),
            new Column("西至","west",ColumnType.String,true),
            new Column("南至","south",ColumnType.String,true),
            new Column("北至","north",ColumnType.String,true),

            new Column("申请类别","applyType",ColumnType.dictionary,false).setTableView()
                .setDictionary("apply_type"),
            new Column("建房类型","buildType",ColumnType.dictionary,true).setTableView()
                .setDictionary("build_type"),
            new Column("建筑面积","buildingArea",ColumnType.String,true).setTableView(),
            new Column("建筑层数","buildingFloor",ColumnType.String,true).setTableView(),
            new Column("建筑高度","buildingHeight",ColumnType.String,true).setTableView(),
            new Column("是否征求相邻人意见","enquireNeighbor",ColumnType.String,true).setTableView()
                .setDictionary("enquire_neighbor"),
            new Column("申请理由","applyReason",ColumnType.TextField,true),




        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250).setPosition("right")];
    }
}