/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../assets/tables/parts/table";
import Column from "../../../assets/tables/parts/column";
import ColumnType from "../../../assets/tables/parts/column_type"
import qlrInfo_api from "../../qlr/qlrInfo/config/qlrInfo_api";

export default class information_item extends Table{
    constructor() {
        super();
        this.name = "information_item";
        this.CNName = "公共信息管理";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("封面图","coverPicture",ColumnType.Avatar,true).setTableView(),
            new Column("标题","title",ColumnType.String,true).setTableView(),
            new Column("公告类型","type",ColumnType.dictionary,true).setTableView(100)
                .setDictionary("announcement_type"),
            new Column("是否发布","publish",ColumnType.Boolean,true).setVisible(false).setTableView(100),
            new Column("备注","remarks",ColumnType.TextField,true),
            new Column("内容","content",ColumnType.String,false).setVisible(false),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(200)];
    }
}