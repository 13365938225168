<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- table start -->
      <ar-base-table ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" :scroll="{ x: 1500,y:650}">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>
          </div>
        </template>
      </ar-base-table>
      <!-- table end -->
    </div>
  </div>

  <zjdInfo_show ref="zjdInfo_show" />
</template>

<script>
import Icons from "../../../../../components/Icons";
import ArBaseTable from "../../../../../components/table/ArBaseTable";
import zjdInfo_show from "./zjdInfo_show";

import zjdInfo_api from "../../../fdytqlr/config/zjdInfo_api";
import zjdInfo_item from "../../../fdytqlr/config/zjdInfo_item";


export default {
  name:"zjdInfo_table",
  mixins:[Icons],
  inject:["arguments"],
  components:{
    ArBaseTable,zjdInfo_show
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new zjdInfo_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new zjdInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 12 ,
      },
    };
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      this.argument.loading = true;
      let parms = {
        obligeeId:this.arguments.value.formData.id,
      };

      zjdInfo_api.queryAllForManage.requestPOSTUrlParam(this,parms,res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data;
        }
      });
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.zjdInfo_show.openDrawer("查看信息","zjdInfo_show",true, this.argument);
    },

  },
};
</script>
<style>

</style>