<!--
*@description：header
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div class="header">
        <h1 class="title  non-select">宅基地审批监管平台</h1>
        <div v-if="userName" class="user non-select" style="right: 15px;">
          欢迎使用：{{userName}}
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              <UserOutlined />
            </a>
            <template #overlay>
              <a-menu @click="menuClick">
                <a-menu-item key="1">修改密码</a-menu-item>
                <a-menu-item key="2">退出登录</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

        </div>
<!--        <a-button v-if="menuVisible" type="link" class="logout-btn" shape="circle" @click="logout">
            <template #icon>
                <LogoutOutlined />
            </template>
        </a-button>-->
    </div>

  <!-- 修改密 -->
  <a-modal v-model:visible="visible_mi"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-form-item label="旧密码" style="margin-left: 27px;">
        <a-input-password v-model:value="oldPwd" placeholder="旧密码" />
      </a-form-item>

      <a-form-item label="新密码" style="margin-left: 27px;">
        <a-input-password v-model:value="newPwd" placeholder="新密码" />
      </a-form-item>

      <a-form-item label="确认新密码">
        <a-input-password v-model:value="confirmPwd" placeholder="确认新密码" />
      </a-form-item>
    </div>

    <template #footer>
      <a-button key="back" @click="visible_mi =false">取消</a-button>
      <a-button key="back" type="primary" @click="approve">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import {LogoutOutlined} from "@ant-design/icons-vue"
import Icons from "../components/Icons";
import api from "./common/config/login";
export default {
    name: "oaHeader",
    props:["userName"],
    mixins:[Icons],
    components:{
        LogoutOutlined,
    },
    data(){
        return{
            //menuObjects:[]
          visible_mi:false,
          modalTitle:"",

          oldPwd:null,
          newPwd:null,
          confirmPwd:null,
        }
    },
    inject:["menuVisible"],
    methods:{
      logout(){
          this.$emit("event",this.$GetEmitData("logout"));
      },
      menuClick(key){
        let type = key.key;
        switch (type){
          case "1":{
            this.oldPwd = null;
            this.newPwd = null;
            this.confirmPwd = null;
            this.visible_mi = true;
            this.modalTitle = "修改密码";
            break;
          }
          case "2":{
            this.logout();
            break;
          }
        }
      },
      approve(){
        let old_pwd = this.oldPwd;
        let new_pwd = this.newPwd;
        let confirm_pwd = this.confirmPwd;
        if(this.$checkUtil.empty_str(old_pwd)){
          this.$message.error("旧密码不能为空！");
          return;
        }
        if(this.$checkUtil.empty_str(new_pwd)){
          this.$message.error("新密码不能为空！");
          return;
        }

        if(this.$checkUtil.empty_str(confirm_pwd)){
          this.$message.error("确认新密码不能为空！");
          return;
        }

        if(new_pwd !== confirm_pwd){
          this.$message.error("新密码与确认新密码不一致！");
          return;
        }
        let parms = {
          oldPassWord:old_pwd,
          newPassWord:new_pwd,
        };
        api.passWordUpdOwn.requestPOSTUrlParam(this,parms,res=>{
          if(res.data.flag){
            this.visible_mi = false;
            this.$message.success("密码修改成功");
            this.$router.push({ path: '/' });
          }
          else{
            this.$message.error(res.data.msg);
          }
        });
      },
    },

}
</script>

<style scoped>
    .title{
        font-size: 40px;
        float: right;
        color: white;
        margin: 10px;
        font-weight: 900;
        font-family:"楷体","楷体_GB2312",serif;
    }
    .logout-btn{
        position: absolute;
        right: 0;
        margin: 20px;
        color: #ffa4a4;
        font-size: 24px;
    }
    .user{
        color: white;
        position: absolute;
        right: 100px;
        margin: 8px;
    }
</style>