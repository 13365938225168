/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    deleteWeb:new APIObject("/api/zApply/deleteWeb.do"),// 删除-单条
    updWeb:new APIObject("/api/zApply/updWeb.do"),//web端修改
    saveWeb:new APIObject("/api/zApply/saveWeb.do"),//web端新增
    queryPageForManage:new APIObject("/api/zApply/queryPageForManage.do"),// 宅基地申请管理-管理部门分页查询
    get:new APIObject("/api/zApply/get.do"),// 宅基地申请管理-单个查询
    submit:new APIObject("/api/zApply/submit.do"),// 宅基地申请管理-提交申请
    uploadGeom:new APIObject("/api/zApply/uploadGeom.do"),// 宅基地申请管理-上传宗地红线（重复上传，则更新几何）
    rescind:new APIObject("/api/zApply/rescind.do"),// 宅基地申请管理-撤销申请



    queryAll_qlr:new APIObject("/api/obligee/queryAll.do"),// 权利人管理-查询所有权利人
    getForManage:new APIObject("/api/obligee/getForManage.do"),// 权利人管理-管理部门单个查询


};

export default api;