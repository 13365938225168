<!--
* @description：地图查询条件
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
  <div class="xm_query">
    <a-input
        label-in-value
        v-model:value="query_value"
        style="width: 50%;"
        placeholder="请输入查询条件"
        showSearch
        :allowClear="true"
    >
    </a-input>
    <a-button type="primary" @click="query_text" >
          <span role="img" aria-label="search" class="anticon anticon-search">
            <svg focusable="false" class="" data-icon="search" width="1em" height="1em"
                 fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
              <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">

              </path></svg></span>
    </a-button>


    <!-- 测量 -->
    <a-popover v-model:visible="visible_cl" title="测量" placement="bottom" trigger="click">
      <template #content>
        <a-select
            v-model:value="cl_type"
            style="width: 80px"
            @focus="focus"
            ref="select"
            @change="clChange"
        >
          <a-select-option value="LineString">长度</a-select-option>
          <a-select-option value="Polygon">面积</a-select-option>
        </a-select>
        <a @click="remove_cl">清除绘制</a>
      </template>

      <img style="background: #de811d;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  z-index: 1;
                  cursor: pointer;
                  margin: 4px;" title="测量"
           @click="click_toop"
           src="../../../assets/image/measure.png">
    </a-popover>

    <!-- 宗地查询分析 -->
    <a-popover v-model:visible="visible_cxfx" title="宗地分析查询" placement="bottom" trigger="click">
      <template #content>
        <a-select
            v-model:value="cx_type"
            style="width: 140px"
            @focus="focus"
            ref="select"
            @change="cxfxChange"
        >
          <a-select-option value="Polygon">宅基地范围查询</a-select-option>
        </a-select>
        <a @click="remove_cxfx">清除绘制</a>
      </template>

      <span style="background: #ecd647;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  z-index: 1;
                  cursor: pointer;
                  margin: 4px;" title="宗地分析"  @click="click_toop"
            role="img" aria-label="edit" class="anticon anticon-edit">
        <svg style="margin-top: 8px" focusable="false" class="" data-icon="edit" width="1em" height="1em" fill="currentColor"
             aria-hidden="true" viewBox="64 64 896 896">
          <path d="M761.1 288.3L687.8 215 325.1 577.6l-15.6 89 88.9-15.7z" fill="#e6f7ff"></path>
          <path d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6
          8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96
          9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8
          5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7
          362.6-88.9 15.7 15.6-89z" fill="#1890ff"></path>
        </svg>
      </span>
    </a-popover>

    <!-- 合规查询分析 -->
    <span style="background: #f38e8c;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  z-index: 1;
                  cursor: pointer;
                  margin: 4px;" title="合规分析"  @click="hgfxChange"
          role="img" aria-label="pie-chart" class="anticon anticon-pie-chart">
        <svg style="margin-top: 8px" focusable="false" class="" data-icon="pie-chart" width="1em" height="1em"
             fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
          <path d="M316.2 920.5c-47.6-20.1-90.4-49-127.1-85.7a398.19 398.19 0 01-85.7-127.1A397.12
          397.12 0 0172 552.2v.2a398.57 398.57 0 00117 282.5c36.7 36.7 79.4 65.5 127 85.6A396.64 396.64
          0 00471.6 952c27 0 53.6-2.7 79.7-7.9-25.9 5.2-52.4 7.8-79.3 7.8-54 .1-106.4-10.5-155.8-31.4zM560
          472c-4.4 0-8-3.6-8-8V79.9c0-1.3.3-2.5.9-3.6-.9 1.3-1.5 2.9-1.5 4.6v383.7c0 4.4 3.6 8 8 8l383.6-1c1.6 0
          3.1-.5 4.4-1.3-1 .5-2.2.7-3.4.7l-384 1z" fill="#e6f7ff"></path><path d="M619.8 147.6v256.6l256.4-.7c-13-62.5-44.3-120.5-90-166.1a332.24
          332.24 0 00-166.4-89.8z" fill="#e6f7ff"></path>
          <path d="M438 221.7c-75.9 7.6-146.2 40.9-200.8 95.5C174.5 379.9 140 463.3 140 552s34.5 172.1
          97.2 234.8c62.3 62.3 145.1 96.8 233.2 97.2 88.2.4 172.7-34.1 235.3-96.2C761 733 794.6 662.3 802.3
          586H438V221.7z" fill="#e6f7ff"></path><path d="M864 518H506V160c0-4.4-3.6-8-8-8h-26a398.46 398.46 0 00-282.8
          117.1 398.19 398.19 0 00-85.7 127.1A397.61 397.61 0 0072 552v.2c0 53.9 10.6 106.2 31.4 155.5 20.1 47.6 49 90.4
          85.7 127.1 36.7 36.7 79.5 65.6 127.1 85.7A397.61 397.61 0 00472 952c26.9 0 53.4-2.6 79.3-7.8 26.1-5.3 51.7-13.1
          76.4-23.6 47.6-20.1 90.4-49 127.1-85.7 36.7-36.7 65.6-79.5 85.7-127.1A397.61 397.61 0 00872 552v-26c0-4.4-3.6-8-8-8zM705.7
          787.8A331.59 331.59 0 01470.4 884c-88.1-.4-170.9-34.9-233.2-97.2C174.5 724.1 140 640.7 140 552s34.5-172.1 97.2-234.8c54.6-54.6
          124.9-87.9 200.8-95.5V586h364.3c-7.7 76.3-41.3 147-96.6 201.8z" fill="#1890ff"></path>
          <path d="M952 462.4l-2.6-28.2c-8.5-92.1-49.4-179-115.2-244.6A399.4 399.4 0 00589 74.6L560.7 72c-3.4-.3-6.4 1.5-7.8 4.3a8.7 8.7 0
          00-.9 3.6V464c0 4.4 3.6 8 8 8l384-1c1.2 0 2.3-.3 3.4-.7a8.1 8.1 0 004.6-7.9zm-332.2-58.2V147.6a332.24 332.24 0 01166.4 89.8c45.7
          45.6 77 103.6 90 166.1l-256.4.7z" fill="#1890ff"></path>
        </svg>
      </span>

    <!-- shp上传分析 -->
    <span style="background: #bbb5b5;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  z-index: 1;
                  cursor: pointer;
                  margin: 4px;" title="shp上传分析"  @click="click_toop"
          role="img" aria-label="folder-open" class="anticon anticon-folder-open">
        <a-upload
            v-model:file-list="fileList"
            name="file"
            :action="actionUrl"
            @change="handleChange"
            :showUploadList="false"
            :accept="accept"
            withCredentials

        >
            <svg style="margin-top: 8px" focusable="false" class="" data-icon="folder-open"
                 width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
              <path d="M159 768h612.3l103.4-256H262.3z" fill="#e6f7ff"></path>
              <path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 00-5.5-2.2H96c-17.7
              0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12
              0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3
              512H159l103.3-256h612.4L771.3 768z" fill="#1890ff"></path>
            </svg>
          </a-upload>
      </span>

    <!-- 清除绘制元素 -->
    <span style="background: #955151;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  z-index: 1;
                  cursor: pointer;
                  margin: 4px;" title="清除"  @click="deleteChange"
          role="img" aria-label="pie-chart" class="anticon anticon-pie-chart ">
       <svg style="margin-top: 8px" viewBox="64 64 896 896" data-icon="delete" width="1em" height="1em" fill="currentColor"
            aria-hidden="true" focusable="false" class=""><path d="M292.7 840h438.6l24.2-512h-487z" fill="#e6f7ff"></path>
         <path fill="#1890ff" d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0
         4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6
         8-8v-32c0-17.7-14.3-32-32-32zm-504-72h304v72H360v-72zm371.3 656H292.7l-24.2-512h487l-24.2 512z"></path></svg>
      </span>

  </div>
  <mask-layer ref="maskLayer"/>
</template>

<script>
import tdt_api from "../config/tdt_api";
import maskLayer from "../../common/components/maskLayer";

export default {
  name: "mapToolBar",
  components:{
    maskLayer
  },
  data(){
    return{
      query_value:null,// 查询条件
      pageParam:{
        page: 1,
        limit: 10,
      },

      cl_type:null,// 测量选择值
      visible_cl: false,

      cx_type:null,// 宗地查询选择值
      visible_cxfx: false,

      /*** 上传shp分析 ***/
      accept:".zip",//Vimage/png,image/jpeg
      file:null,
      fileList:[],
      previewVisible:false,
      actionUrl:tdt_api.readShpGeometry.url,
      baseImageUrl:"d:",

      // 合规分析
      visible_hgfx: false,
    }
  },
  methods:{
    /**
     * 点击查询
     */
    query_text() {
      // 清除绘制元素
      this.click_toop();
      let parms = {
        queryText:this.query_value
      };
      let data = this.$GetEmitData(this.$eventTags.query_text,parms);
      this.$emit("event",data);
    },

    /** 测量 **/
    clChange(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.query_cl));
    },
    remove_cl(){
      this.cl_type = null;
      this.$emit("event",this.$GetEmitData(this.$eventTags.remove_cl));
    },
    get_cl_type(){
      return this.cl_type;
    },
    get_cx_type(){
      return this.cx_type;
    },
    /** 清除绘制、提示信息 ***/
    click_toop(){
      this.cx_type = null;
      this.cl_type = null;
      this.$emit("event",this.$GetEmitData(this.$eventTags.click_toop));
    },
    /** 宗地绘制分析 **/
    cxfxChange(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.zd_cxfx));
    },
    /** 清除宗地查询绘制 **/
    remove_cxfx(){
      this.cx_type = null;
      this.$emit("event",this.$GetEmitData(this.$eventTags.remove_zd_cxfx));
    },

    hgfxChange(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.hgfx));
    },
    /*************** 上传shp分析 start ****************/
    handleChange({file,fileList,event}){
      if(file.status === "uploading"){ //status: "uploading"
        this.$refs.maskLayer.openShow();
      }
      else if(file.status==="done"){//status: "done"
        let flag = file.response.flag;
        this.$refs.maskLayer.closeShow();
        if(flag){
          let data = this.$GetEmitData(this.$eventTags.shp_fx,file.response.data);
          this.$emit("event",data);

        }else{
          this.$message.error(file.response.msg);
        }

      }
      else {
        this.$refs.maskLayer.closeShow();
      }
    },
    /*************** 上传shp分析 end ****************/
    deleteChange(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.deleteChange));
    },
  }
}
</script>

<style scoped>
.xm_query{
  position: absolute;
  z-index: 1000;
  width: 25%;
  margin-left: 3%;
  margin-top: 2%;
}
</style>