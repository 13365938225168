<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <!--上级菜单 start-->
  <div style="margin-left: 14%;">
    <a-form-item  label="上级菜单：" >
      <div  style="text-align: center;margin-left: -22%;">{{sjcd_name}}</div>
    </a-form-item>
  </div>
  <!--上级菜单 end-->
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="true"  />
</template>

<script>
    import ArForm from "../../../../components/form/ArForm";
    import menu_api from "../config/menu_api";

    export default {
      name: "menu_show",
      inject:["arguments"],
      components:{
        ArForm
      },
      data(){
        return{
          sjcd_name:null,
        }
      },
      computed:{
        argument(){
          return  this.arguments.value;
        },
      },
      created() {
        if(this.arguments.value.formData.pid == '0'){
          this.sjcd_name = '系统根目录'
        }else{
          this.init();
        }

      },
      methods:{
        init(){
          menu_api.queryInfoId.requestPOSTUrlParam(this,{id:this.formData.value.pid},res=>{
            if(res.data.flag){
              this.sjcd_name = res.data.data.title
            }

          })
        },
      }

    }
</script>

<style scoped>

</style>