<!--
* @description：下级审核记录
* @author:rzl
* @date:2022/1/18 9:42
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="60%"
           :destroyOnClose="true"
  >

    <!-- table start -->
    <ArBaseTable ref="pstable" :argument="argument" :view-model="true" :scroll="{ x: 900,y:650}">

    </ArBaseTable>
    <!-- table end -->

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import xjsh_item from "../../config/sh/xjsh_item";
import zjdAudit_api from "../../../zjdAudit/config/zjdAudit_api";
import ArBaseTable from "../../../../../components/table/ArBaseTable";

export default {
  name: "xjshTable",
  components:{
    ArBaseTable
  },
  data(){
    return{
      visible:null,
      modalTitle:null,
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new xjsh_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formColumns:new xjsh_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },

    }
  },
  methods:{
    /**
     * 打开下级审核记录
     * @param applyId 申请id
     */
    openXjshTable(applyId){
      this.argument.dataSource = null;
      zjdAudit_api.queryAllAuditChildDepartment.requestPOSTUrlParam(this,{applyId:applyId},res=>{
        if(res.data.flag){
          this.argument.dataSource = res.data.data;
          this.visible = true;
          this.modalTitle = "下级部门审核情况";
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },

  },

}
</script>

<style scoped>

</style>