<!--
* @description：在线预览
* @author:rzl
* @date:2022/5/6 13:36
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="55%"
           :destroyOnClose="true"
  >
    <embed style="width: 100%;height: 500px;text-align: center" :src="fileUrl" />
    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import APIObject from "../../../assets/utils/APIObject";

export default {
  name: "onlinePreview",
  data(){
    return{
      visible:false,
      modalTitle:"在线预览",
      fileUrl:null,
    }
  },
  methods:{
    setFileUrl(url){
      this.visible = true;
      let api = new APIObject();
      this.fileUrl = api.getUrl(url);
    },
  }
}
</script>

<style scoped>

</style>