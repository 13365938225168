<!--
*@description：
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <ar-form  ref="bill_form" :argument="argument"  :viewModel="true"  />
</template>

<script>
import ArForm from "../../../../components/form/ArForm";

export default {
  name: "userShow",
  components:{
    ArForm
  },
  inject:["arguments"],
  computed:{
    argument(){
      return  this.arguments.value;
    },
  },
}
</script>

<style scoped>

</style>