<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <dict-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="dictChild(index,record)">
              <template #icon><DatabaseTwoTone title="子菜单"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>
</template>

<script>
import dictToolBar from "./components/menuToolBar";
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import drawerComponent from "./components/drawerComponent";

import menu_api from "./config/menu_api";
import menu_item from "./config/menu_item";


export default {
  name:"menu_main",
  mixins:[Icons],
  components:{
    dictToolBar,ArBaseTable,drawerComponent
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new menu_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new menu_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },
    };
  },

  created() {
    this.init();
  },
  methods:{
    init(){
      this.argument.loading = true;
      let parms = {};
      menu_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms ={
            title:arg.title,
          }
          menu_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
          break;
        }

      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      menu_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      menu_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    dictChild(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer(arg.title+":子项信息","dic_child_main",false,this.argument);
    },
  },
};
</script>
<style>

</style>