<!--
*@description：子项新增、编辑
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-modal v-model:visible="visible"
           :title="modalTitle" width="35%" style="overflow-y: auto"
           :destroyOnClose="true"
  >

    <ar-form  ref="bill_form" :argument="arguments"  :viewModel="sub_flag"  />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-button v-if="!sub_flag" key="submit" type="primary" @click="submit">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import ArForm from "../../../../components/form/ArForm";
import user_api from "../../user/config/user_api";

export default {
  name: "department_user",
  components:{
    ArForm
  },
  computed:{
    arguments(){
      return  this.argument;
    },
  },
  data(){
    return{
      argument:null,
      visible:false,
      modalTitle:null,
      sub_flag:false,
      formData_depart:{},
    }
  },
  methods:{
    openDrawer(title,component,sub_flag,argument,formData_depart){
      this.visible = true;
      this.modalTitle = title;
      this.comp = component;
      this.sub_flag = sub_flag;
      this.argument = argument;
      this.formData_depart = formData_depart;
    },
    submit(){
      switch (this.comp){
        case "add_user":{
          let  parms  = this.$refs.bill_form.getSource();
          parms.departmentId = this.formData_depart.id;
          user_api.saveOrUpd.requestPOST(this,parms,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.visible = false;
              this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
        case "edit_user":{
          let  parms  = this.$refs.bill_form.getSource();
          parms.departmentId = this.formData_depart.id;
          user_api.saveOrUpd.requestPOST(this,parms,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.visible = false;
              this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>