/**
 * @description：
 * @author:rzl
 * @date:2021/8/25 10:26
 **/

import APIObject from "../../../assets/utils/APIObject";

let webKey = '4ed50da3a61d11e0e3b0a23eec4e276d';
const api={
    wmtsUrl_1:'http://t0.tianditu.gov.cn/img_c/wmts?tk=' + webKey, //矢量底图
    wmtsUrl_2:'http://t0.tianditu.gov.cn/cia_c/wmts?tk=' + webKey, //矢量注记

    geoserver_url:'../geoserver/zhaijidi/wms',
    //geoserver_url:'http://localhost:8083/geoserver/zhaijidi/wms',

    queryPageForHome:new APIObject('/api/zLandPlots/queryPageForHome.do'),// 查询-首页数据搜索查询
    getZd:new APIObject("/api/zLandPlots/get.do"),// 查询-单条
    queryAllByFLandPlotsId:new APIObject("/api/obligee/queryAllByFLandPlotsId.do"),// 权利人管理-房地一体宗地关联的所有权利人
    get_zapply:new APIObject("/api/zApply/get.do"),// 宅基地申请管理-单个查询
    queryPageByWkt:new APIObject("/api/zLandPlots/queryPageByWkt.do"),// 查询-分页查询范围内的宗地信息
    readShpGeometry:new APIObject("/api/common/readShpGeometry.do"),// 通用模块-读取上传的shp图形

};
export default api;