<!--
* @description：用户查询并使用查询数据：房地一体权利人；资格名录库
* @author:rzl
* @date:2022/1/18 16:45
-->
<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="60%"
           :destroyOnClose="true"
  >

    <!-- table start -->
    <a-input class="search-item" v-model:value="queryText" style="width:300px" placeholder="姓名/身份证" />
    <a-button type="primary" style="border-radius: inherit;margin-left: 15px;" @click="doSearch_zg">
      <template #icon><Search /></template>
      查询资格名录库
    </a-button>
    <a-button type="primary" style="border-radius: inherit;background-color: #ff7875;margin-left: 15px;" @click="doSearch_fdyt">
      <template #icon><Search /></template>
      查询房地一体权利人
    </a-button>
    <ar-base-table ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 600,y:650}">
      <template v-slot:action="{index,record}">
        <div style="justify-content: center;;display: flex;">
          <a-button type="link" @click="showData(index,record)">
            <template #icon><Show title="查看"/></template>
          </a-button>
          <a-button type="link" @click="useData(index,record)">
            <template #icon><CheckCircle title="使用数据申请"/></template>
          </a-button>
        </div>
      </template>
    </ar-base-table>
    <!-- table end -->

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

  <!-- 查看 -->
  <info-show ref="infoShow"/>

</template>

<script>
import Icons from "../../../../../components/Icons";
import ArBaseTable from "../../../../../components/table/ArBaseTable";
import infoShow from "../infoShow";

import qlrInfo_item from "../../config/fdyt/qlrInfo_item";
import qlrInfo_api from "../../../../fdyt/fdytqlr/config/qlrInfo_api";
import zgmlk_api from "../../../../zgmlk/config/zgmlk_api";
import zgmlk_item from "../../../../zgmlk/config/zgmlk_item";
import zjdApply_item from "../../config/zjd/zjdApply_item";

export default {
  name: "userTableTk",
  mixins:[Icons],
  components:{
    ArBaseTable,infoShow
  },
  data(){
    return{
      visible:null,
      modalTitle:null,

      argument:{
        dataSource:[],
        tableColumns:new zgmlk_item().getTableColumns(),
        formColumns:new zgmlk_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData: {},
      },
      pageParam:{
        page: 1,
        limit: 12 ,
      },

      queryText:null,
      queryType:null,

    }
  },
  methods:{
    /**
     * 打开用户数据查询
     */
    openUserTable(){
      this.visible = true;
      this.modalTitle = "用户数据";
    },
    doSearch_zg(){
      this.argument = {
        dataSource:[],
        tableColumns:new zgmlk_item().getTableColumns(),
        formColumns: new zgmlk_item().getFormColumns(),
        formData: {},
      }
      this.queryType = this.$eventTags.user_zgmlk;
      let parms = {
        queryText:this.queryText
      };
      this.$refs.pstable.setCurrentPage(1);
      zgmlk_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    doSearch_fdyt(){
      this.argument = {
        dataSource:[],
        tableColumns:new qlrInfo_item().getTableColumns(),
        formColumns: new qlrInfo_item().getFormColumns(),
        formData: {},
      }
      this.queryType = this.$eventTags.user_fdyt;
      let parms = {
        queryText:this.queryText
      };
      this.$refs.pstable.setCurrentPage(1);
      qlrInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },

    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = {
        queryText:this.queryText
      };
      if( this.queryType == this.$eventTags.user_zgmlk){
        zgmlk_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
          if(res.data.flag){
            this.argument.dataSource = res.data.data.resultList;
            this.$refs.pstable.setTotalSize(res.data.data.total);
          }
        });
      }else if(this.queryType == this.$eventTags.user_fdyt){
        qlrInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
          if(res.data.flag){
            this.argument.dataSource = res.data.data.resultList;
            this.$refs.pstable.setTotalSize(res.data.data.total);
          }
        });
      }

    },

    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.infoShow.openInfoShow(this.argument,'用户信息');
    },
    useData(index,arg){
      this.visible = false;
      this.$emit("event",this.$GetEmitData(this.queryType,arg));
    },

  },

}
</script>

<style scoped>

</style>