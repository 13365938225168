/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class role_item extends Table{
    constructor() {
        super();
        this.name = "role_item";
        this.CNName = "角色信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("角色名称","name",ColumnType.String,true).setTableView(),
            new Column("禁用状态","roleState",ColumnType.String,true).setTableView()
                .setEnum(["0","1"],
                    ["禁用","启用"],
                    ["red","blue"]).setDefaultValue("1"),
            new Column("备注","remarks",ColumnType.TextField,true),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250)];
    }
}