<!--
* @program: tyh-oa 
* @author: ly
* @component:dataPresent 
* @description: 
* @create: 2021-03-26 11:57
-->
<template>
    <div ref="data_present" v-is="type" :viewModel="viewModel" :column="column" :record="record" @update="handleUpdate" />
</template>

<script>
    import dataTypes from "./dataTypes";
    export default {
        name: "dataPresent",
        mixins:[dataTypes],
        emits:["update"],
        props:{
            column:{required: true},
            record:{required:true},
            viewModel:{required:true}
        },
        methods:{
            init(){
                this.$refs.data_present.init();
            },
            handleUpdate(){
                this.$emit("update");
            }
        }
    }
</script>

<style scoped>

</style>