/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class xjsh_item extends Table{
    constructor() {
        super();
        this.name = "xjsh_item";
        this.CNName = "下级审核信息";
        this.columnArray = [
            new Column("子部门id","departmentId",ColumnType.String,false).setVisible(false),
            new Column("子审核记录id","auditChildId",ColumnType.String,false).setVisible(false),
            new Column("子部门名","departmentName",ColumnType.String,false).setTableView(false),
            new Column("审核人","auditUserName",ColumnType.String,false).setTableView(),
            new Column("审核状态","auditStatus",ColumnType.dictionary,true).setTableView().setVisible(false)
                .setDictionary("audit_status"),
            new Column("审核时间","auditTime",ColumnType.Date,false).setTableView(),
            new Column("审核意见","auditOpinion",ColumnType.TextField,false).setTableView(),
        ];

    }
}