/**
 * @program: 前台

 * @author: ly
 *
 * @create: 2020-08-20 15:10
 **/


import APIObject from "./APIObject";

/**
 * 随机生成UUID
 * @param {int}len   生成的UUID的长度
 * @param {int}radix 生成的UUID的进制
 * @returns {string}
 */
function uuid(len, radix) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random()*16;
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}
/**
 * 返回object中的属性个数
 * @param {Object} obj
 * @returns {int}
 */
function countProperties (obj) {
  let count = 0;
  for (let property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      count++;
    }
  }
  return count;
}


/**
 * 固定object的某个属性值
 * @param obj
 * @param prop
 * @param val
 */
function frozenProperty(obj,prop,val){
  if(typeof val != "undefined" || val !=null){
    delete obj[prop];
    Object.defineProperty(obj,prop,{configurable:false,value:val});
  }
  else{
    let value = obj[prop];
    delete obj[prop];
    Object.defineProperty(obj,prop,{configurable:false,value:value});
  }
}

class EmitData {
  constructor(type,arg) {
    this.type = type;
    this.arg = arg;
  }
}

function checkAuth(auth,type) {
  if(auth && auth.length>0){
    return auth.indexOf(type) !== -1;
  }
  else{
    return false;
  }
}

function GetEmitData(type,arg) {
    return new EmitData(type,arg)
}


/**
 * @return {string}
 */
function GetCurrentFileUrl(url) {
  let reg = new RegExp("C:/apache-tomcat-8.5.38-2020/webapps/tyhoa_file/","g");
  if(url){
    return url.replace(reg,"http://localhost:9090/");
  }
  else{
    return "";
  }
}
function getUrl(url){
  let api = new APIObject();
  return api.getUrl(url);
}

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}


export {uuid,countProperties,EmitData,checkAuth,GetEmitData,GetCurrentFileUrl,dateFormat,getUrl}
