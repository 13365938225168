/**
 * @program: tyh-oa
 * @author: ly
 * @description: menu 用于定义顶层菜单及其权限
 * @create: 2021-03-22 14:02
 **/
import {uuid} from "../utils/general";

export class BaseMenu{
    /**
     *
     * @param title 菜单名称
     * @param component 菜单对应的component的name
     * @param id 菜单id
     */
    constructor(title,component,id) {
        this.id = id?id:uuid(8,16);
        this.title = title; //标题
        this.enable = true;//是否出现
        this.authority = [];//权限
        this.children =[];
        this.component=component;
    }
    setChildren(children){
        this.children = children;
        return this;
    }
    addChild(child){
        if(this.children){
            this.children.push(child);
        }
        else{
            this.children = [child];
        }
        return this;
    }
    setTitle(title){
        this.title = title;
        return this;
    }
    setEnable(bool){
        this.enable = bool;
        return this;
    }
    setBelong(seg){
        this.belong = seg;
        return this;
    }
    addAuthority(auth){
        if(this.authority){
            this.authority.push(auth);
        }
        else{
            this.authority=[auth];
        }
        return this;
    }
    setAuth(auth){
        this.authority=auth;
        return this;
    }
    enableCurrentMenu(id,callback){
        if(this.id ===id){
            callback(this);
        }
        else{
            if(this.children && this.children.length>0){
                for(let i in this.children){
                    this.children[i].enableCurrentMenu(id,callback);
                }
            }
        }
    }
    setCallback(callback){
        this.callback = callback;
        return this;
    }
}


export let menu =new BaseMenu().setChildren([
    new BaseMenu("系统维护","sys").setChildren(
        [
            new BaseMenu("人员管理","user_main"),
            new BaseMenu("角色管理","role_main"),
            new BaseMenu("菜单管理","menu_main"),
            new BaseMenu("字典管理","dictionary_main"),
        ]
    ),

]);




/**
 * 解析出MenuObjects
 * @param menus  接收解析JSON完成后的容器，类型必须是BaseMenu
 * @param menuJSON  数据库传来的查询结果。属性有{treeName,component,treeId,children}
 */
export function menuGenerate(menus,menuJSON){ //递归函数
    for(let i in menuJSON){
        let {meta,component,menuId} = menuJSON[i];
        let title = meta.title
        let child = new BaseMenu(title,component,menuId);
        menus.addChild(child);
        menuGenerate(child,menuJSON[i].children);
    }
}