<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <div  class="search-bar">
        <a-input class="search-item ant-col-8" v-model:value="queryText" placeholder="请输入关键字" />
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
          <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
          <template #icon><Reset /></template>
        </a-button>
      </div>
      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_c" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="userRole(index,record)">
              <template #icon><Authorize title="授权角色"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>
  </div>

  <department_user ref="department_user" @event="refresh"/>

  <authorize-role-tk ref="authorizeRoleTk" @event="authorizeRole"/>

</template>

<script>
import Icons from "../../../../components/Icons";
import ArBaseTable from "../../../../components/table/ArBaseTable";
import department_user from "./department_user";

import department_user_item from "../config/department_user_item";
import user_api from "../../user/config/user_api";
import authorizeRoleTk from "../../role/common/point/authorizeRoleTk";


export default {
  name:"department_child_main",
  mixins:[Icons],
  inject:["arguments"],
  components:{
    ArBaseTable,department_user,authorizeRoleTk
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new department_user_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new department_user_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
        pid:null,
      },
      pageParam:{
        page: 1,
        limit: 12 ,
      },
      formData_depart:{},

      queryText:null,
    };
  },
  computed:{
    argument_c(){
      this.init();
      return  this.argument;
    },
  },
  methods:{
    init(){
      this.formData_depart = this.arguments.value.formData
      let parms = {
        departmentId:this.formData_depart.id,
        queryText:null,
      };
      this.argument.loading = true;
      user_api.queryPageByDepartment.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      user_api.queryPage.requestPOSTBothParam(this, {urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    refresh(){
      this.queryText = null;
      this.init();
    },
    doSearch(){
      let parms ={
        departmentId:this.formData_depart.id,
        queryText:this.queryText
      }
      user_api.queryPageByDepartment.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    addData(){
      this.argument.formData = {};
      this.$refs.department_user.openDrawer("新增信息","add_user",false,this.argument,this.formData_depart);
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.department_user.openDrawer("查看信息","show_user",true, this.argument,this.formData_depart);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.department_user.openDrawer("编辑信息","edit_user",false,this.argument,this.formData_depart);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      user_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    authorizeRole({type,arg}){

    },
    userRole(index,arg){
      this.$refs.authorizeRoleTk.openRoleTk(this.formData_depart,arg);
    },

  },
};
</script>
<style>

</style>