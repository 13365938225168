<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIndex 
* @description: 
* @create: 2021-04-15 17:05
-->
<template>
    <div style="text-align: center">
        {{realIndex}}
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeIndex",
        props:{
            index:{required:true},
            passedNum:{required:true},
            currentIndex:{required:true}
            },
        mixins:[typeBase],
        computed:{
            highLightClass(){
                if(this.index === this.currentIndex){
                    return "high-light"
                }
                else{
                    return ""
                }
            },
            realIndex(){
                if(this.index === this.currentIndex){
                    let idx = this.passedNum+this.index+1;
                    return "► "+idx;
                }
                else{
                    return this.passedNum+this.index+1;
                }
            }
        }
    }
</script>

<style scoped>
    .high-light{
        background: red;
    }
</style>