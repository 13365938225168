/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class sdc_item extends Table{
    constructor() {
        super();
        this.name = "sdc_item";
        this.CNName = "三到场信息";
        this.columnArray = [
            new Column("上传部门","departmentName",ColumnType.String,false).setTableView(),
            new Column("上传人姓名","createUserName",ColumnType.String,false).setTableView(),
            new Column("上传时间","createTime",ColumnType.Date,false).setTableView(),
            new Column("主键id","id",ColumnType.String,false),
            new Column("宅基地申请id","applyId",ColumnType.Date,false),
            new Column("类型","type",ColumnType.dictionary,false)
                .setDictionary("scene_examine_type"),
            new Column("情况说明","explain",ColumnType.String,false),
            new Column("上传位置","position",ColumnType.String,false),
            new Column("宗地红线","geom",ColumnType.String,false),

        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(150).setPosition("right")];
    }
}