<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar ant-row">
      <a-input class="search-item ant-col-4" v-model:value="queryText" placeholder="姓名/身份证" />
      <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
        <template #icon><Search /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
        <template #icon><Reset /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><Add /></template>
      </a-button>
      <a-button type="primary" style="margin-left: 20px;" @click="downloadTemplet">下载导入模板</a-button>
      <a-button type="primary" style="margin-left: 20px;background-color: #55b925;" @click="batchImport">批量导入数据</a-button>
    </div>
</template>

<script>
    import Icons from "../../../components/Icons";

    export default {
        name: "zgryToolBar",
        mixins:[Icons],
        data(){
          return{
            searchOptions:[
              {key:0,value:"禁用"},
              {key:1,value:"启用"},
            ],
            queryText:null,
          }
        },
        methods:{
          doSearch(){
              let data = this.$GetEmitData(this.$eventTags.search,{queryText:this.queryText});
              this.$emit("event",data);
          },
          refresh(){
              this.queryText = null;
              this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          },
          addData(){
            this.$emit("event",this.$GetEmitData(this.$eventTags.add));
          },
          downloadTemplet(){
            this.$emit("event",this.$GetEmitData(this.$eventTags.downloads));
          },
          batchImport(){
            this.$emit("event",this.$GetEmitData(this.$eventTags.batchImport));
          },
          getParms(){
            let parms = {
              queryText:this.queryText,
            }
            return parms;
          },

        }
    }
</script>

<style scoped>

</style>