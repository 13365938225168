<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar ant-row">
      <a-input class="search-item ant-col-4" v-model:value="queryText" placeholder="户主名称" />
      <a-input class="search-item ant-col-4" v-model:value="villageName" placeholder="所属村名" />
      <a-select
          v-model:value="auditStatus"
          placeholder="审核状态"
          class="search-item ant-col-4"
      >

        <a-select-option v-for="(item) in searchOptions_state" :key="item.key">
          {{ item.value }}
        </a-select-option>
      </a-select>

      <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
        <template #icon><Search /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
        <template #icon><Reset /></template>
      </a-button>
<!--      <a-button class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><Add /></template>
      </a-button>-->
      <a-button type="primary" style="margin-left: 20px;" @click="addDataZjd">宅基地申请</a-button>
      <a-button type="primary" style="margin-left: 20px;background-color: #ebb853;" @click="addData">房地一体申请</a-button>

    </div>
</template>

<script>
import Icons from "../../../../components/Icons";

import dic_api from "../../../sys/dictionary/config/dic_api";

export default {
  name: "zjdApplyToolBar",
  mixins:[Icons],
  data(){
    return{
      queryText:null,
      villageName:null,
      auditStatus:null,
      searchOptions_state:[],
    }
  },
  created() {
      this.init();
  },
  methods:{
    init(){
      dic_api.queryByNameCode.requestPOSTUrlParam(this,{nameOrCode:this.$eventDict.audit_status},res=>{
        if(res.data.flag){
          let data = res.data.data;
          if(data != null){
            let children = data.children;
            if(children.length > 0){
              for(let i=0;i<children.length;i++){
                let key_name = children[i].dictCode;
                if(key_name != '5.3' && key_name != '5.4' && key_name != '5.5' && key_name != '6.0'){
                  this.searchOptions_state.push({
                    key:key_name,
                    value:children[i].dictName
                  })
                }
              }
            }
          }
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    doSearch(){
      let parm = {
        queryText:this.queryText,
        villageName:this.villageName,
        auditStatus:this.auditStatus,
      }
      let data = this.$GetEmitData(this.$eventTags.search,parm);
      this.$emit("event",data);
    },
    refresh(){
      this.queryText = null;
      this.villageName = null;
      this.auditStatus = null;
      this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
    },
    addData(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.add));
    },
    addDataZjd(){
      this.$emit("event",this.$GetEmitData(this.$eventTags.add_zjd));
    },
    getParms(){
      let parms = {
        queryText:this.queryText,
        villageName:this.villageName,
        auditStatus:this.auditStatus
      }
      return parms;
    },
  }
}
</script>

<style scoped>

</style>