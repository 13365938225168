/**
 * @program: tyh-oa
 * @author: ly
 * @description: login
 * @create: 2021-04-15 11:08
 **/

import APIObject from "../../../assets/utils/APIObject";

const api={
    login:new APIObject("/api/login.do"),
    logout:new APIObject("/api/logout.do"),

    userMenuOpTree:new APIObject("/api/menu/queryUserMenuTree.do"),

    passWordUpdOwn:new APIObject("/api/userInfo/passWordUpdOwn.do"),// 系统用户管理-用户密码修改
};
export default api;