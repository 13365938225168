<!--
*@description：抽屉组件：编辑页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

    <a-drawer
        width="40%"
        :visible="visible_drawer"
        body-style="padding-bottom: 80px;margin-top: 6%"
        :closable="false"
        placement="right"
        :get-container="false"
        :wrap-style="{ position: 'absolute' }"
        :destroyOnClose="true"
    >

      <div class="drawer-header">  {{title}}  </div>


      <div  v-is="comp"  ref="deawer_comp"  style="height: 100%;overflow-y: auto;" />

      <div class="drawer-footer">
        <a-button style="margin-right:8px;" @click="onClose">
          取消
        </a-button>
        <a-button v-if="sub_flag" type="primary" @click="submit">
          提交
        </a-button>
      </div>
    </a-drawer>

</template>

<script>
    import {computed} from "vue";

    import show from "../point/show";
    import edit from "../point/edit";
    import add from "../point/add";

    import role_api from "../config/role_api";

    export default {
        name: "zjdApply_drawer",
        components:{
          show,edit,add
        },
        provide(){
          return{
            arguments:computed(()=>this.argument),
          }
        },
        data(){
            return{
              visible_drawer: false,
              title:null,
              comp:null,
              sub_flag:false,
              argument:null,
            }
        },
        methods:{
          openDrawer(title,component,sub_flag,argument){
            this.visible_drawer = true;
            this.title = title;
            this.comp = component;
            this.sub_flag = sub_flag;
            this.argument = argument;
          },
          submit(){
            switch (this.comp){
              case "add":{
                let  parms  = this.$refs.deawer_comp.getParms();
                role_api.saveOrUpd.requestPOST(this,parms,res=>{
                  if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
                  }
                  else{
                    this.$message.error(res.data.msg);
                  }
                })
                break;
              }
              case "edit":{
                let  parms  = this.$refs.deawer_comp.getParms();
                role_api.saveOrUpd.requestPOST(this,parms,res=>{
                  if(res.data.flag){
                    this.$message.success(res.data.msg);
                    this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
                  }
                  else{
                    this.$message.error(res.data.msg);
                  }
                })
                break;
              }
            }
          },
          onClose() {
            this.visible_drawer = false;
          },
        }
    }
</script>

