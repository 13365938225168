<!--
*@description：房地一体申请
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div >
    <a-steps :current="current" :showInit="showInit">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    <div class="steps-content" style="margin-top: 15px">
      <div v-show="current == 0">
        <a-form-item >
          <a-tag color="#2db7f5" @click="openFdyt">房地一体数据</a-tag>
        </a-form-item>

        <ar-form  ref="form_zjd" :argument="argument_zjd" :viewModel="false"/>
      </div>
      <div v-show="current == 1">

        <a-form-item >
          <a-tag color="#2db7f5"  @click="openUserTable">查询用户数据</a-tag>
        </a-form-item>

        <ar-form ref="form_qlr" :argument="argument_qlr" :viewModel="false"/>
      </div>
      <div v-show="current == 2">
        <a-button type="dashed" shape="circle" @click="openJtcyAdd"  style="margin-left: -94%;">
          <template #icon><Add title="添加家庭成员"/></template>
        </a-button>

        <ar-base-table ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 500,y:400}">
          <template v-slot:action="{index,record}">
            <div style="justify-content: center;;display: flex;">
              <a-button type="link" @click="showData(index,record)">
                <template #icon><Show title="查看"/></template>
              </a-button>
              <a-button type="link" @click="openJtcyEdit(index,record)">
                <template #icon><Edit title="编辑"/></template>
              </a-button>
              <a-popconfirm
                  class="action-button"
                  title="确定删除吗?"
                  okText="确定"
                  cancelText="取消"
                  @confirm="deleteData(index,record)"
              >
                <a-button type="link">
                  <template #icon><Delete title="删除" style="color: red"/></template>
                </a-button>
              </a-popconfirm>
            </div>
          </template>
        </ar-base-table>
      </div>

    </div>
    <div class="steps-action">
      <a-button v-show="current > 0" style="margin-left: 8px" @click="prev">
        上一步
      </a-button>
      <a-button v-show="current < steps.length - 1" type="primary" @click="next">
        下一步
      </a-button>

    </div>
  </div>

  <!-- 人员搜索：房地一体权利人；资格名录库 -->
  <user-table-tk ref="userTableTk" @event="useUserData"/>

  <!-- 家庭成员查看 -->
  <jtcy-show ref="jtcy_show"/>

  <!-- 房地一体数据 -->
  <fdyt-rk-table ref="fdytRkTable" @event="useFdytData"/>

  <!-- 家庭成员编辑 -->
  <jtcy-edit-tk ref="jtcyEditTk" @event="addJtcy"/>

</template>

<script>
import Icons from "../../../../components/Icons";
import ArForm from "../../../../components/form/ArForm";
import ArBaseTable from "../../../../components/table/ArBaseTable";

import zjdApply_item from "../../common/config/zjd/zjdApply_item";
import hzInfo_item from "../../common/config/user/hzInfo_item";
import zjdApply_api from "../config/zjdApply_api";
import jtcyShow from "../../../fdyt/common/point/qlr/jtcyShow";
import fdytRkTable from "../../common/point/fdyt/fdytRkTable";
import jtcyEditTk from "../../common/point/user/jtcyEditTk";
import userTableTk from "../../common/point/user/userTableTk";
import jtcy_item from "../../common/config/user/jtcy_item";

export default {
name: "zjdApply_edit",
mixins:[Icons],
inject:["arguments"],
components:{
  ArForm,ArBaseTable,jtcyShow,fdytRkTable,jtcyEditTk,userTableTk
},
data(){
  return{
    current: 0,
    steps: [
      {
        title: '宅基地申请信息',
      },
      {
        title: '户主信息',
      },
      {
        title: '家庭成员',
      },
    ],

    argument_zjd:{
      formColumns:new zjdApply_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    argument_qlr:{
      formColumns:new hzInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    argument:{
      dataSource:[],//提供table的DataSource
      tableColumns:new jtcy_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      loading:false,//table的loading状态
      currentIndex:null,
      formColumns:new jtcy_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
      formData:{},
    },
    pageParam:{
      page: 1,
      limit: 10,
    },
    villageId:null,
    villageName:null,
    edit_index:null,// 判断家庭成员修改：序号
  }
},
computed:{
  showInit(){
    this.init();
    return null;
  }
},
methods:{
  init(){
    this.argument.dataSource = [];
    zjdApply_api.get.requestPOSTUrlParam(this,{id:this.arguments.value.formData.id},res=>{
      if(res.data.flag){
        let info = res.data.data;
        this.villageId = info.zApply.villageId;
        this.villageName = info.zApply.villageName;

        this.argument_zjd.formData = info.zApply;
        this.argument_zjd.formData.villageId = info.zApply.villageName;

        this.argument_qlr.formData = info.zApply;
        this.argument_qlr.formData.name = info.zApply.householderName;

        this.argument.dataSource = info.familyMembers;
      }
      else{
        this.$message.error(res.data.msg);
      }
    })
  },
  next() {
    this.current++;
  },
  prev() {
    this.current--;
  },
  pageChange(arg){
    this.pageParam.page = arg.page;
    this.pageParam.limit = arg.limit;
    let parms = this.$refs.toolBar.getParms();
    this.argument.loading = true;
    zjdApply_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
      this.argument.loading =false;
      if(res.data.flag){
        this.argument.dataSource = res.data.data.resultList;
        this.$refs.pstable.setTotalSize(res.data.data.total);
      }
    });
  },
  showData(index,arg){
    this.argument.formData = arg;
    this.$refs.jtcy_show.openJtcyShow(this.argument);
  },
  openJtcyAdd(){
    this.edit_index = null;
    this.argument.formData = {};
    this.$refs.jtcyEditTk.openJtcyEdit(this.argument,true);
  },
  openJtcyEdit(index,arg){
    this.edit_index = index;
    this.argument.formData = arg;
    this.$refs.jtcyEditTk.openJtcyEdit(this.argument,true);
  },
  deleteData(index,arg){
    this.argument.dataSource.splice(index,1);
  },
  addJtcy({type,arg}){
    if(this.edit_index != null){
      this.argument.dataSource.splice(this.edit_index,1);
    }
    this.argument.dataSource.push(arg.formData);
  },
  openFdyt(){
    this.$refs.fdytRkTable.openFdytRkTable();
  },
  useFdytData({type,arg}){
    this.argument_zjd.formData = {
      fLandPlotsId:arg.id,
      currentHomesteadArea:arg.zdmj,
      address:arg.zl,
      east:arg.zdszd,
      west:arg.zdszx,
      south:arg.zdszn,
      north:arg.zdszb,
      geom:arg.geom,
    };
    this.$emit("event",this.$GetEmitData(this.$eventTags.add,this.argument_zjd.formData));
  },
  openUserTable(){
    this.$refs.userTableTk.openUserTable();
  },
  useUserData({type,arg}){
    switch (type){
      case this.$eventTags.user_zgmlk:{
        this.argument_qlr.formData = arg;
        this.argument.formData.qualificationId = arg.id;
        this.argument.formData.obligeeId = null;
        break;
      }
      case this.$eventTags.user_fdyt:{
        let age = this.$universalUtil.getAge(arg.zjh);
        this.argument_qlr.formData ={
          qualificationId:null,
          obligeeId:arg.id,
          villageId:arg.villageId,
          villageName:arg.villageName,
          name:arg.qlrmc,
          idCard:arg.zjh,
          sex:arg.xb,
          age:age,
          phone:arg.dh,
        }
        break;
      }
    }
  },
  getParms(){
    let data_zjd = this.argument_zjd.formData;
    if(this.villageName == data_zjd.villageId){
      data_zjd.villageId = this.villageId;
    }
    if(data_zjd.currentHomesteadHandleType == '1'){
      data_zjd.currentHomesteadHandleOther = null;
    }else if(data_zjd.currentHomesteadHandleType == '3'){
      data_zjd.currentHomesteadKeepArea = null;
    }else{
      data_zjd.currentHomesteadHandleOther = null;
      data_zjd.currentHomesteadKeepArea = null;
    }
    data_zjd.geom = null;
    let data_hz = this.argument_qlr.formData;
    this.zjd_hz(data_zjd,data_hz);
    let data_jtcy = this.argument.dataSource;
    let parms = {
      zApply:data_zjd,
      familyMembers:data_jtcy
    }
    return parms;
  },
  zjd_hz(zjd,hz){
    zjd.obligeeId = hz.obligeeId;
    zjd.qualificationId = hz.qualificationId;
    zjd.householderName = hz.name;
    zjd.sex = hz.sex;
    zjd.age = hz.age;
    zjd.phone = hz.phone;
    zjd.idCard = hz.idCard;
    zjd.householdRegister = hz.householdRegister;
    zjd.qlbl = hz.qlbl;
    zjd.gyfs = hz.gyfs;
    zjd.gyqk = hz.gyqk;
    zjd.applyType = '1';
  },
}
}
</script>

<style scoped>

</style>