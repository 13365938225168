<!--
*@description：房地一体宅基地主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <fdytzjd-tool-bar  ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>
            <a-button type="link" @click="showQlr(index,record)">
              <template #icon><Team title="权利人信息"/></template>
            </a-button>
            <a-button type="link" @click="showFile(index,record)">
              <template #icon><FileInfo title="文件信息"/></template>
            </a-button>
            <a-button type="link" @click="showZdt(index,record)">
              <template #icon><ParcelMap title="宗地地图"/></template>
            </a-button>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>

    <!-- 权利人信息 -->
    <qlr-table-tk  ref="qlrInfoBut"/>

    <!-- 文件信息 -->
    <file-info-tk  ref="fileInfoBut"/>

    <!--宗地图信息-->
    <map-tk  ref="mapTkBut"/>
  </div>

</template>

<script>
    import Icons from "../../../components/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import drawerComponent from "./components/drawerComponent";
    import fdytzjdToolBar from "./components/fdytzjdToolBar";

    import fdytzjd_api from "./config/fdytzjd_api";
    import fdytzjd_item from "./config/fdytzjd_item";
    import mapTk from "../../homeMap/common/map_tk";
    import fileInfoTk from "../../zjd/common/point/file/fileInfoTk";
    import qlrTableTk from "../common/point/qlr/qlrTableTk";

    export default {
      name: "fdytzjd_main",
      mixins:[Icons],
      components:{
        fdytzjdToolBar,ArBaseTable,drawerComponent,mapTk,fileInfoTk,qlrTableTk
      },
      data(){
          return{
            argument:{
              dataSource:null,//提供table的DataSource
              tableColumns:new fdytzjd_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
              loading:false,//table的loading状态
              currentIndex:null,
              formColumns:new fdytzjd_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
              formData:{},
            },
            pageParam:{
              page: 1,
              limit: 10,
            },
          }
      },
      created() {
        this.init()
      },

      methods:{
        init(){
          let parms = {};
          this.argument.loading = true;
          fdytzjd_api.queryForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
              if(res.data.flag){
                this.argument.dataSource = res.data.data.resultList;
                this.$refs.pstable.setTotalSize(res.data.data.total);
              }
          });

        },
        eventHandle({type,arg}){
            switch (type) {
                case this.$eventTags.search:{
                    this.argument.loading = true;
                    let parms ={
                      queryText:arg.queryText,
                      zl:arg.zl
                    }
                  fdytzjd_api.queryForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
                      this.argument.loading =false;
                      if(res.data.flag){
                        this.argument.dataSource = res.data.data.resultList;
                        this.$refs.pstable.setTotalSize(res.data.data.total);
                      }
                    });
                    break;
                }
                case this.$eventTags.refresh:{
                  this.$refs.local_tabs.onClose();
                  this.init();
                  break;
                }
            }
        },
        pageChange(arg){
          this.pageParam.page = arg.page;
          this.pageParam.limit = arg.limit;
          let parms = this.$refs.toolBar.getParms();
          this.argument.loading = true;
          fdytzjd_api.queryForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
        },
        showData(index,arg){
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
        },
        showQlr(index,arg){
          this.$refs.qlrInfoBut.openQlr(arg.id);
        },
        showFile(index,arg){
          this.$refs.fileInfoBut.openFile(arg.id);
        },
        showZdt(index,arg){
          this.$refs.mapTkBut.openMap(arg.geom);
        },
      },

    }
</script>

<style scoped>

</style>