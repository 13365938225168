<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <department-tool-bar ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-button type="link" @click="zzqyData(index,record)">
              <template #icon><Authorize title="职责区域"/></template>
            </a-button>

            <a-button type="link" @click="editUserData(index,record)">
              <template #icon><Team title="部门人员信息"/></template>
            </a-button>

            <a-button type="link" @click="dictChild(index,record)">
              <template #icon><DatabaseTwoTone title="部门子项"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs"  @event="eventHandle"/>

  </div>


  <!--职责区域授权 start-->
  <a-modal v-model:visible="visible_dept"
           :title="modalTitle_dept" width="35%" style="overflow-y: auto"
           :destroyOnClose="true">

    <div style="height: 500px;overflow-y: auto">
      <a-tree
          checkable
          :tree-data="treeData"
          :blockNode="true"
          :defaultExpandAll="true"
          v-model:checkedKeys="checkedKeys"
          @check="handleCheckDept"
      />
    </div>

    <template #footer>
      <a-button key="back" @click="visible_dept =false">取消</a-button>
      <a-button key="submit" type="primary" @click="submit" >提交</a-button>
    </template>
  </a-modal>
  <!--职责区域授权 end-->

</template>

<script>
import departmentToolBar from "./components/departmentToolBar";
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import drawerComponent from "./components/drawerComponent";

import department_api from "./config/department_api";
import department_item from "./config/department_item";


export default {
  name:"dict_main",
  mixins:[Icons],
  components:{
    departmentToolBar,ArBaseTable,drawerComponent
  },
  data(){
    return {
      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new department_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new department_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 10,
      },

      // 职责区域授权
      deptId:null,
      visible_dept:false,
      modalTitle_dept:null,
      treeData:[],
      checkedKeys:[],
      check_tree:[],
    };
  },

  created() {
    this.init();
  },
  methods:{
    init(){
      this.argument.loading = true;
      let parms = {};
      department_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          this.argument.dataSource =  res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    eventHandle({type,arg}){
      switch (type) {
        case this.$eventTags.search:{
          this.argument.loading = true;
          let parms ={
            type:arg.type,
            name:arg.name
          }
          department_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
          break;
        }
        case this.$eventTags.refresh:{
          this.$refs.local_tabs.onClose();
          this.init();
          break;
        }
        case this.$eventTags.add:{
          this.argument.formData = {};
          this.$refs.local_tabs.openDrawer("新增信息","add",true,this.argument);
          break;
        }

      }
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = this.$refs.toolBar.getParms();
      this.argument.loading = true;
      department_api.queryPage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer("编辑信息","edit",true,this.argument);
    },
    /** 职责区域授权 start ***/
    zzqyData(index,arg){
      this.checkedKeys = [];
      this.deptId = arg.id

      department_api.findVillage.requestPOSTUrlParam(this,{departmentId:arg.id},res=>{
        this.visible_dept = true;
        this.modalTitle_dept = arg.name+":职责区域授权"
        if(res.data.flag){
          let data = res.data.data;
          this.checkedKeys = [];
          console.log(data.tree)
          /**结果封装成树**/
          this.treeData = this.convertToTreedata(data.tree);

          /** 已选择的区域 **/
          let data_res = data.relatedIds;
          if(data_res != null && data_res.length > 0){
            for(let i=0;i<data_res.length;i++){
              let menuId = data_res[i]
              this.checkedKeys.push(menuId)
            }
          }
        }

      });
    },

    /**
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedata(data){
      var returnData = [];
      let j = 0;
      for (var key in data) {
        var tempObj = {
          title : key,
          value : j,
          key : j
        };
        let list_info = data[key];
        let children = [];
        if(list_info.length > 0){
          //遍历数据
          for(var i = 0; i < list_info.length; i++){
            children.push({
              title : list_info[i].name,
              value : list_info[i].id,
              key : list_info[i].id,
              children:[],
            });
          }
        }
        tempObj.children = children;
        //push到数据数组中
        returnData.push(tempObj);
        j++;
      }

      return returnData;
    },
    handleCheckDept(checkedKeys, e){
      const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
      this.check_tree = checkedKeysResult;
    },
    submit(){
      /*if(this.checkedKeys == null || this.checkedKeys.length <= 0){
        this.$message.error("请选择要授权的区域");
        return;
      }*/
      let data = {
        villageIds:this.check_tree,
        departmentId:this.deptId,
      }
      department_api.save_zzqy.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
    /** 职责区域授权 end ***/

    editUserData(index,arg){
      this.argument.formData = arg;
      this.$refs.local_tabs.openDrawer(arg.name+":人员信息","department_user_main",false,this.argument);
    },
    deleteData(index,arg){
      let data = {id:arg.id};
      department_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
    dictChild(index,arg){
      this.argument.formData = arg;
      this.argument.formData.dectId = arg.type;
      this.argument.formData.pid = arg.id;
      this.$refs.local_tabs.openDrawer(arg.name+":子项信息","department_child_main",false,this.argument);
    },
  },
};
</script>
<style>

</style>