<!--
*@description：抽屉组件：编辑页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

    <a-drawer
        width="40%"
        :visible="visible_drawer"
        body-style="padding-bottom: 80px;margin-top: 6%"
        :closable="false"
        :get-container="false"
        :wrap-style="{ position: 'absolute',transform: 'translateX(0px)' }"
        :destroyOnClose="true"
    >

      <div class="drawer-header">  {{title}}  </div>


      <div  v-is="comp"  ref="deawer_comp"  style="height: 100%;overflow-y: auto;" />

      <div class="drawer-footer">
        <a-button style="margin-right:8px;" @click="onClose">
          取消
        </a-button>
        <a-button v-if="sub_flag" type="primary" @click="submit">
          提交
        </a-button>
      </div>
    </a-drawer>

</template>

<script>
import {computed} from "vue";

import show from "../point/show";
import edit from "../point/edit";
import add from "../point/add";
import department_child_main from "../point/department_child_main";
import department_user_main from "../point/department_user_main";

import department_api from "../config/department_api";

export default {
  name: "department_drawer",
  components:{
    show,edit,add,department_child_main,department_user_main
  },
  provide(){
    return{
      arguments:computed(()=>this.argument),
    }
  },
  data(){
      return{
        visible_drawer: false,
        title:null,
        comp:null,
        sub_flag:false,
        argument:null,
      }
  },
  methods:{
    openDrawer(title,component,sub_flag,argument){
      this.visible_drawer = true;
      this.title = title;
      this.comp = component;
      this.sub_flag = sub_flag;
      this.argument = argument;
    },
    submit(){
      switch (this.comp){
        case "add":{
          let  parms  = this.$refs.deawer_comp.getParms();
          department_api.save.requestPOST(this,parms,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
        case "edit":{
          let  parms  = this.$refs.deawer_comp.getParms();
          department_api.upd.requestPOST(this,parms,res=>{
            if(res.data.flag){
              this.$message.success(res.data.msg);
              this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
            }
            else{
              this.$message.error(res.data.msg);
            }
          })
          break;
        }
      }
    },
    onClose() {
      this.visible_drawer = false;
    },
  }
}
</script>

