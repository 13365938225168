<!--
*@description：上传宗地红线
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
<a-modal v-model:visible="visible"
         :maskClosable="false"
         :title="modalTitle"  width="55%"
         :destroyOnClose="true"
>

  <div class="ant-row">
    <div class="ant-col-14">
        <map_info ref="mapInfo" />
    </div>
    <div class="ant-col-10">
      <a-form-item  >
        <div style="margin-top: 20%;margin-right: 40%">
          <a-upload
              v-model:file-list="fileList"
              name="file"
              :data='parms'
              :action="actionUrl"
              @change="handleChange"
              :showUploadList="false"
              :accept="accept"
              withCredentials
          >
            <a-button style="background-color: #096dd9;color: #fafafa;">
              <upload-outlined></upload-outlined>
              上传宗地红线
            </a-button>
          </a-upload>
        </div>
      </a-form-item>
    </div>
  </div>


  <template #footer>
    <a-button key="back" @click="visible =false">取消</a-button>
  </template>
</a-modal>
</template>

<script>
import Icons from "../../../../components/Icons";

import ArBaseTable from "../../../../components/table/ArBaseTable";
import zjdApply_api from "../config/zjdApply_api";
import map_info from "../../../homeMap/common/map_info";

export default {
name: "sc_zdhx",
mixins:[Icons],
components:{
  ArBaseTable,map_info
},
data(){
    return{
      /**弹框文件信息**/
      visible:false,
      modalTitle:null,

      accept:".zip",//Vimage/png,image/jpeg
      file:null,
      fileList:[],
      previewVisible:false,
      actionUrl:zjdApply_api.uploadGeom.url,
      baseImageUrl:"d:",
      spinning:false,

      parms:{
        id:null,
      },
      formData:null,

    }
},
methods:{
  /***
   * 打开文件编辑
   */
  openFile(argument){
    this.parms.id = argument.formData.id;
    this.visible = true;
    this.modalTitle = '宗地红线信息';
    setTimeout(()=>{
      this.$refs.mapInfo.initMap(argument.formData.geom);
    },200);
  },
  handleChange({file,fileList,event}){
    if(file.status === "uploading"){ //status: "uploading"
      this.spinning = true;
    }
    else if(file.status==="done"){//status: "done"
      let flag = file.response.flag
      if(flag){
        this.spinning = false;
        this.$message.success(file.response.msg);
        this.findInfo();
        this.$emit("event",this.$GetEmitData(this.$eventTags.refresh2));
      }else{
        this.$message.error(file.response.msg);
      }

    }
    else {
      this.spinning = false;
    }
  },
  findInfo(){
    zjdApply_api.get.requestPOSTUrlParam(this,{id:this.parms.id},res=>{
      if(res.data.flag){
        let info = res.data.data;
        this.$refs.mapInfo.wkt_show(info.zApply.geom);
      }
    });
  },
},
}
</script>

<style scoped>

</style>