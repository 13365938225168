/**
 * @description：字典类别
 * @author:rzl
 * @date:2022/1/4 11:09
 **/
function eventDict(){
    this.department_type = "department_type";                       // 部门类别
    this.audit_status = "audit_status";                             // 审核状态
    this.announcement_type = "announcement_type";                   // 公告类型
}

let tags = new eventDict();
export default tags;
