<!--
*@description：后台登录
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <a-layout style="height: 100%">
        <a-layout-header class="header">
            <oa-header />
        </a-layout-header>
        <a-layout-content class="main">
            <a-card title="登录" class="card">
                <a-form
                    :model="formData"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
                <a-form-item label="用户名" >
                    <a-input v-model:value="formData.phone" :maxlength="11"
                             v-on:keyup.enter="login"
                    >
                        <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                    </a-input>
                </a-form-item>
                <a-form-item label="密码" >
                    <a-input v-model:value="formData.password" type="password"
                             v-on:keyup.enter="login">
                        <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                    </a-input>
                </a-form-item>
            </a-form>
                <a-button
                        style="float: right"
                        type="primary"
                        html-type="submit"
                        v-on:keyup.enter="login"
                        @click="login">
                    登录
                </a-button>
            </a-card>
            <oa-footer />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import oaHeader from "./ht_header";
    import oaFooter from "./oaFooter";
    import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
    import api from "./common/config/login";
    import {menuGenerate} from "../assets/system/menu";
    export default {
        name: "loginPage",
        components:{
            oaHeader,oaFooter,UserOutlined,LockOutlined
        },
        data(){
            return{
                formData:{
                    phone: '',
                    password: '',
                },
                labelCol: { span: 6 },
                wrapperCol: { span: 16 },
            }
        },
        provide(){
            return{
                menuVisible:false
            }
        },
        methods:{
          created(){
            let phone = this.$cookies.get("phone");
            let password = this.$cookies.get("password");
            /*if(phone && password){
                 this.$router.push({ path: '/main_manager' })
             }*/
          },
            login(){
                if(this.checkData()){
                  this.$cookies.set("phone",this.formData.phone);
                  this.$cookies.set("password",this.formData.password);
                  this.$router.push({ path: '/main_manager' })
                }
            },
            checkData(){
                if(!this.formData.phone){
                    this.$message.error("请输入用户名");
                    return false;
                }
                if(!this.formData.password){
                    this.$message.error("请输入密码");
                    return false;
                }
                return true;
            }
        },

    }
</script>

<style scoped>
.card{
    width: 300px;
    position: absolute;
    right: 200px;
    top:40%;
    box-shadow: 3px 3px 4px 0px #c6c4c4;
}
</style>