/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class fdytzjd_item extends Table{
    constructor() {
        super();
        this.name = "fdytzjd_item";
        this.CNName = "房地一体宗地信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("所属村","villageId",ColumnType.String,false).setVisible(false),
            new Column("坐落","zl",ColumnType.String,true).setTableView(200),
            new Column("宗地代码","zddm",ColumnType.String,true).setTableView(),
            new Column("预编宗地代码","ybzddm",ColumnType.String,true),
            new Column("不动产单元号","bdcdyh",ColumnType.String,true).setTableView(),
            new Column("宗地特征码","zdtzm",ColumnType.dictionary,true).setTableView(150)
                .setDictionary("ZDTZM"),
            new Column("宗地面积","zdmj",ColumnType.String,true),
            new Column("面积单位","mjdw",ColumnType.dictionary,true)
                .setDictionary("MJDW"),
            new Column("用途","yt",ColumnType.dictionary,true)
                .setDictionary("YT"),
            new Column("等级","dj",ColumnType.dictionary,true)
                .setDictionary("DJ"),
            new Column("价格(万元)","jg",ColumnType.String,true),
            new Column("权利类型","qllx",ColumnType.dictionary,true)
                .setDictionary("QLLX"),
            new Column("权利性质","qlxz",ColumnType.dictionary,true)
                .setDictionary("QLXZ"),
            new Column("权利设定方式","qlsdfs",ColumnType.dictionary,true)
                .setDictionary("QLSDFS"),
            new Column("容积率","rjl",ColumnType.String,true),
            new Column("建筑密度","jzmd",ColumnType.String,true),
            new Column("建筑限高","jzxg",ColumnType.String,true),
            new Column("宗地四至东","zdszd",ColumnType.String,true),
            new Column("宗地四至西","zdszx",ColumnType.String,true),
            new Column("宗地四至北","zdszb",ColumnType.String,true),
            new Column("宗地四至南","zdszn",ColumnType.String,true),
            new Column("图幅号","tfh",ColumnType.String,true),
            new Column("地籍号","djh",ColumnType.String,true),
            new Column("备注","bz",ColumnType.String,true),
            new Column("区县代码","qxdm",ColumnType.String,true),
            new Column("批准面积","hddjmj",ColumnType.String,true),
            new Column("数据来源","sjly",ColumnType.dictionary,true)
                .setDictionary("SJLY"),
            new Column("是否完成宅基地登记","finishregister",ColumnType.String,true),

        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(150)];
    }
}