/**
 * @description：
 * @author:rzl
 * @date:2022/1/4 9:32
 **/
const common={
    /**
     * 一级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedata:function (data){
        var returnData = [];
        //遍历数据
        for(var i = 0; i < data.length; i++){
            var tempObj = {
                title : data[i].name,
                value : data[i].id,
                key : data[i].id
            };

            //push到数据数组中
            returnData.push(tempObj);
        }
        return returnData;
    },

    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas(data){
        var returnData = [];
        //遍历数据
        for(var i = 0; i < data.length; i++){
            var tempObj = {
                title : data[i].name,
                value : data[i].id,
                key : data[i].id
            };

            //判断是否存在子节点，如果存在则递归
            if(data[i].children != null && data[i].children.length > 0){
                tempObj.children = this.convertToTreedata(data[i].children);
            }
            //push到数据数组中
            returnData.push(tempObj);
        }
        return returnData;
    },
    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas2(data){
        var returnData = [];
        //遍历数据
        for(var i = 0; i < data.length; i++){
            var tempObj = {
                title : data[i].treeName,
                value : data[i].treeId,
                key : data[i].treeId
            };

            //判断是否存在子节点，如果存在则递归
            if(data[i].children != null && data[i].children.length > 0){
                tempObj.children = this.convertToTreedatas2(data[i].children);
            }
            //push到数据数组中
            returnData.push(tempObj);
        }
        return returnData;
    },

    /**
     * 多级
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @returns {[]}  返回结构
     */
    convertToTreedatas_dic(data,level){
        var returnData = [];
        //遍历数据
        for(var i = 0; i < data.length; i++){
            var tempObj = {
                id:data[i].id,
                label:data[i].dictName,
                level:level + 1,
            };

            //判断是否存在子节点，如果存在则递归
            if(data[i].children != null && data[i].children.length > 0){
                tempObj.children = this.convertToTreedatas_dic(data[i].children,level + 1);
            }
            //push到数据数组中
            returnData.push(tempObj);
        }
        return returnData;
    },


};
export default common;