<!--
* @program: tyh-oa 
* @author: ly
* @description: typeBooleanCheck 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel" style="text-align: center">
        <a-tag  :color="presentColor">{{presentValue}}</a-tag>
    </div>
    <div v-else>
        <a-checkbox v-if="editable" v-model:checked="record[column.dataIndex]" @change="columnTrigger"/>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeBooleanCheck",
        mixins:[typeBase],
        computed:{
            presentValue(){
                if(this.column.boolTags){
                    return this.record[this.column.dataIndex]?this.column.boolTags[1]:this.column.boolTags[0]
                }
                else{
                    return this.record[this.column.dataIndex]?"是":"否"
                }
            },
            presentColor(){
                return this.record[this.column.dataIndex]?this.colors[1]:this.colors[0]
            }
        },
        data(){
            return{
                colors:["purple","cyan"]
            }
        }
    }
</script>

<style scoped>

</style>