<!--
* @description：撤销弹框
* @author:rzl
* @date:2022/1/12 14:59
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-form-item label="撤销说明"  :label-col="labelCol" :wrapper-col="wrapperCol"  >
        <a-textarea v-model:value="rescindReason" style="height: 100px;"/>
      </a-form-item>
    </div>

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
      <a-popconfirm
          class="action-button"
          title="确定撤销吗?"
          okText="确定"
          cancelText="取消"
          @confirm="revokeData"
      >
        <a-button key="back" type="primary" style="width: 8%;">撤销</a-button>
      </a-popconfirm>

    </template>
  </a-modal>
</template>

<script>
import Icons from "../../../../components/Icons";
export default {
  name: "auditTk",
  mixins:[Icons],
  data(){
    return{
      visible:false,
      modalTitle:null,
      labelCol:{ span: 6 },
      wrapperCol:{ span: 16 },

      infoId:null,
      rescindReason:null,
    }
  },
  methods:{
    openRevoked(id){
      this.visible = true;
      this.modalTitle = "撤销说明";
      this.rescindReason = null;

      this.infoId = id;
    },
    closeRevoked(){
      this.visible = false;
    },
    revokeData(){
      if(this.$checkUtil.empty_str(this.rescindReason)){
        this.$message.error("请输入撤销说明！")
        return ;
      }

      let parms = {
        ids:this.infoId,
        rescindReason:this.rescindReason,
      }
      let data = this.$GetEmitData(this.$eventTags.revoked,parms);
      this.$emit("event",data);
    },
  },

}
</script>
