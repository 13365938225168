<!--
* @program: tyh-oa 
* @author: ly
* @description: typeString 
* @create: 2021-03-23 10:01
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-textarea
                v-if="editable"
                v-model:value="record[column.dataIndex]"
                style="height: 80px;"
                @input="columnTrigger"


        /><!-- auto-size-->
        <div style="text-align: center;" v-else >{{record[column.dataIndex]}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeString",
        mixins:[typeBase],

    }
</script>

<style scoped>

</style>