<!--
*@description：字典管理主界面
*@author:rzl
*@date:2021/6/18 13:45
-->
<template>
  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <div  class="search-bar">
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
          <template #icon><Reset /></template>
        </a-button>
      </div>
      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument_c" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="editData(index,record)">
              <template #icon><Edit title="编辑"/></template>
            </a-button>

            <a-popconfirm
                class="action-button"
                title="确定删除吗?"
                okText="确定"
                cancelText="取消"
                @confirm="deleteData(index,record)">

              <a-button type="link">
                <template #icon><Delete title="删除" style="color: red"/></template>
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>
  </div>

  <jtcy_edit ref="child_main" @event="refresh"/>

</template>

<script>
import Icons from "../../../components/Icons";
import ArBaseTable from "../../../components/table/ArBaseTable";
import jtcy_edit from "./jtcy_edit";

import zgmlk_api from "../config/zgmlk_api";
import zgmlk_jtcy_item from "../config/zgmlk_jtcy_item";


export default {
  name:"jtcy_main",
  mixins:[Icons],
  inject:["arguments"],
  components:{
    ArBaseTable,jtcy_edit
  },
  data(){
    return {
      argument:{
        dataSource:[],//提供table的DataSource
        tableColumns:new zgmlk_jtcy_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        loading:false,//table的loading状态
        currentIndex:null,
        formColumns:new zgmlk_jtcy_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
        pid:null,
      },
      pageParam:{
        page: 1,
        limit: 12 ,
      },
    };
  },
  computed:{
    argument_c(){
      this.init();
      return  this.argument;
    },
  },
  methods:{
    init(){
      this.argument.dataSource = [];
      this.argument.loading = true;
      this.argument.pid = this.arguments.value.formData.id;
      let parms = {
        pid:this.arguments.value.formData.id
      };
      zgmlk_api.queryPageForFamily.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        this.argument.loading = false;
        if(res.data.flag){
          let resList = res.data.data.resultList;
          if(resList.length > 0){
            for(let i=0;i<resList.length;i++){
              let info = resList[i];
              info.dectId = this.arguments.value.formData.dectId;
              this.argument.dataSource.push(info);
            }
          }

          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    pageChange(arg){
      let pages  = {
        page:arg.page,
        limit:arg.limit
      }
      let parms = {
        pid:this.argument.pid
      };
      this.argument.loading = true;
      zgmlk_api.queryPageForFamily.requestPOSTBothParam(this,{urlParam:parms,formParam:pages},res=>{
        this.argument.loading =false;
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    refresh(){
      this.init();
    },
    addData(){
      this.argument.formData = {};
      this.$refs.child_main.openDrawer("新增家庭成员信息","add_c",false,this.argument);
    },
    showData(index,arg){
      this.argument.formData = arg;
      this.$refs.child_main.openDrawer("查看家庭成员信息","show_c",true, this.argument);
    },
    editData(index,arg){
      this.argument.formData = arg;
      this.$refs.child_main.openDrawer("编辑家庭成员信息","edit_c",false,this.argument);
    },
    deleteData(index,arg){

      let data = {
        ids:arg.id
      };
      zgmlk_api.delete.requestPOSTUrlParam(this,data,res=>{
        if(res.data.flag){
          this.argument.dataSource.splice(index,1);
          this.$message.success(res.data.msg);
          this.init();
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
};
</script>
<style>

</style>