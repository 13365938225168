<!--
* @description：富文本框组件：编辑
* @author:rzl
* @date:2022/4/25 17:01
-->

<template>
  <div style="border: 1px solid #ccc; margin-top: 10px;z-index: 999">
    <!-- 工具栏 -->
    <div class="toolbarClass" id="toolbar-container" ></div>

    <!-- 编辑器 -->
    <div class="editorClass" id="editor-container"></div>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { createEditor, createToolbar, IEditorConfig, IDomEditor } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import file_api from "../../common/config/file_api";
import {getUrl} from "/src/assets/utils/general";

export default {
  name: "wangeditorEditComp",
  components: { Editor, Toolbar },
  data () {
    return {
      /** 编辑器**/
      editor: null,
      // default 默认模式 - 集成了 wangEditor 所有功能
      // simple 简洁模式 - 仅有部分常见功能，但更加简洁易用
      editor_mode: 'simple',

      /** 工具栏 **/
      toolbar:null,
      toolbar_mode: 'simple',
    }
  },
  mounted() {
    const editorConfig = {}
    editorConfig.placeholder = '请输入内容';
    editorConfig.MENU_CONF = {
      uploadImage :{
        // 后端上传地址，必填
        server: file_api.uploadRichTextImg,
        // form-data fieldName，后端接口参数名称，默认值wangeditor-uploaded-image
        fieldName: "file",
        // 1M，单个文件的最大体积限制，默认为 2M
        maxFileSize: 10 * 1024 * 1024,
        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],
        // 15 秒，超时时间，默认为 10 秒
        timeout: 15 * 1000,
        // 跨域是否传递 cookie ，默认为 false
        withCredentials: true,
        // 上传之前触发
        onBeforeUpload(file) {
          // file 选中的文件，格式如 { key: file }
          return file

          // 可以 return
          // 1. return file 或者 new 一个 file ，接下来将上传
          // 2. return false ，不上传这个 file
        },
        // 上传进度的回调函数
        onProgress(progress) {
          // progress 是 0-100 的数字

        },
        // 单个文件上传成功之后
        onSuccess(file, res) {
          //console.log(file, res)
        },
        // 单个文件上传失败
        onFailed(file, res) {
          alert(res.message)
        },
        // 上传错误，或者触发 timeout 超时
        onError(file, err, res) {
          alert(res.message)
        },
        // 自定义插入图片
        customInsert(res, insertFn) {
          // res 即服务端的返回结果
          let url = getUrl(res.data.url);
          let alt = res.data.alt;
          let href = res.data.href;
          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(url, alt, href)
        },
      },
    };

    editorConfig.onChange = (editor) => {
      // 当编辑器选区、内容变化时，即触发

    }

    // 创建编辑器
    this.editor = createEditor({
      selector: '#editor-container',
      config: editorConfig,
      mode: this.editor_mode
    })
    const toolbarConfig = {
      excludeKeys:['insertVideo', 'uploadVideo', 'insertImage']
    };
    // 创建工具栏
    this.toolbar = createToolbar({
      editor:this.editor,
      config: toolbarConfig,
      selector: '#toolbar-container',
      mode: this.toolbar_mode
    })
    //this.getMenuKey();
  },
  methods: {
    getMenuKey(){
      let list = this.editor.getAllMenuKeys();
      console.log(list)
    },
    getText(){
      return this.editor.getText();
    },
    getHtml(){
      return this.editor.getHtml();
    },
    setHtml(html){
      this.editor.dangerouslyInsertHtml(html);
    },
    /** 文本 **/
    insertTextHandler () {
      const editor = this.editor
      if (editor == null) return
      editor.insertText(' hello ')
    },
    /** 插入html **/
    printEditorHtml () {
      const editor = this.editor
      if (editor == null) return
    },
    /** 设置富文本框不可编辑 **/
    disableHandler () {
      const editor = this.editor
      if (editor == null) return
      editor.disable()
    }
  },
}
</script>

<style scoped>
.toolbarClass{
  border-bottom: 1px solid #ccc
}
.editorClass{
  height: 400px;
  overflow-y: hidden;
}
</style>