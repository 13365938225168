/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryTreeOp:new APIObject("/api/menu/queryTreeOp.do"),//查询-所有菜单树及操作码
    delete:new APIObject("/api/menu/delete.do"),// 删除-单条菜单
    queryInfoId:new APIObject("/api/menu/queryInfoId.do"),// 查询-查询单条菜单
    batchDelete:new APIObject("/api/menu/batchDelete.do"),// 删除-批量
    queryMenuByRole:new APIObject("/api/menu/queryMenuByRole.do"),//查询-根据角色id查询有权限的菜单
    save:new APIObject("/api/menu/save.do"),//新增菜单-单个
    queryUserMenuTree:new APIObject("/api/menu/queryUserMenuTree.do"),//查询-当前登录用户有权限的菜单
    queryTree:new APIObject("/api/menu/queryTree.do"),// 查询-所有菜单树
    queryPage:new APIObject("/api/menu/queryPage.do"),//查询-根据父菜单id查询子菜单（分页）
    update:new APIObject("/api/menu/update.do"),// 修改菜单-单条
    queryPageDTO:new APIObject("/api/menu/queryPageDTO.do"),// POST /api/menu/queryPageDTO.do
};

export default api;