<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:Icons 
* @description: 
* @create: 2021-08-17 10:10
-->
<template>
    <div>
      <SearchOutlined /><RedoOutlined /><PlusOutlined /><DeleteOutlined /><EditTwoTone /><FileSearchOutlined /><RollbackOutlined />

      <FileZipOutlined /><PushpinOutlined /><UploadOutlined /><ReadOutlined /><AuditOutlined />
      <CheckCircleOutlined /><ClusterOutlined /><HomeTwoTone /><TeamOutlined /><FolderOpenOutlined />
      <EnvironmentOutlined /><DatabaseTwoTone /><TagsOutlined /><DownloadOutlined /><PieChartOutlined />
      <CheckOutlined /><BorderBottomOutlined /><UpCircleOutlined /><UserOutlined /><EyeTwoTone/>
    </div>
</template>

<script>
    import {
      SearchOutlined,RedoOutlined,PlusOutlined,DeleteOutlined,EditTwoTone,FileSearchOutlined,
      CheckOutlined,RollbackOutlined,

      FileZipOutlined,PushpinOutlined,UploadOutlined,ReadOutlined,AuditOutlined,
      CheckCircleOutlined,ClusterOutlined,HomeTwoTone,TeamOutlined,FolderOpenOutlined,
      EnvironmentOutlined,DatabaseTwoTone,TagsOutlined,DownloadOutlined,PieChartOutlined,BorderBottomOutlined,
      UpCircleOutlined,UserOutlined,EyeTwoTone

    }  from "@ant-design/icons-vue";

    export default {
        name: "Icons",
        components:{
          Search:SearchOutlined,// 查询
          Reset:RedoOutlined,// 重置
          Add:PlusOutlined,// 新增
          Delete:DeleteOutlined,// 删除
          Edit:EditTwoTone,// 编辑
          Show:FileSearchOutlined,// 查看
          CheckOutlined:CheckOutlined,// 提交
          revoke:RollbackOutlined, // 撤销

          Documentation:FileZipOutlined,// 文档管理
          Accept:PushpinOutlined,// 受理
          UploadFile:UploadOutlined,// 上传文件
          Read:ReadOutlined,// 公示审核
          Audit:AuditOutlined,// 审核
          CheckCircle:CheckCircleOutlined,// 完结
          ClusterOutlined:ClusterOutlined,// 下级部门审核记录
          HomeTwoTone:HomeTwoTone,// 宅基地信息
          Team:TeamOutlined,// 权利人信息
          FileInfo:FolderOpenOutlined,// 文件信息
          ParcelMap:EnvironmentOutlined,// 宗地地图
          DatabaseTwoTone:DatabaseTwoTone,// 下级子项
          Authorize:TagsOutlined,// 授权
          Download:DownloadOutlined,// 下载
          PieChart:PieChartOutlined,// 合规分析
          zdhx:BorderBottomOutlined,// 宗地红线
          release:UpCircleOutlined,// 发布
          UserOutlined:UserOutlined,
          EyeTwoTone:EyeTwoTone,// 查看
        }
    }
</script>

<style scoped>

</style>