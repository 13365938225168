<!--
* @description：三到场详情
* @author:rzl
* @date:2022/4/27 14:34
-->

<template>
      <div class="ant-col-8" :id="infoStyle" style="height: 400px; width: 50%; ">
        <div :id="map_id" ref="rootmap" class="divTdtMap" style="height: 400px; width:100%; ">
        </div>
      </div>
      <div class="ant-col-14" :id="infoStyle" style="height: 400px; width: 45%;margin-left: 50px; ">
        <div class="ant-row">
          <a-form-item label="上传部门" >
            {{formData.departmentName}}
          </a-form-item>
        </div>
        <div class="ant-row">
          <a-form-item label="上传人姓名">
            {{formData.createUserName}}
          </a-form-item>
        </div>
        <div class="ant-row">
          <a-form-item label="上传时间">
            {{this.presentValue(formData.createTime)}}
          </a-form-item>
        </div>
        <div class="ant-row">
          <a-form-item label="说  明">
            {{formData.explain}}
          </a-form-item>
        </div>
        <div class="ant-row">
          <a-form-item label="图片" >
            <a-image-preview-group  v-for="(file,i) in file_list" :key="i">
              <div style="float: left;margin-left: 5px;">
                <a-image  style="height: 60px;" :src="file" />
              </div>
            </a-image-preview-group>
          </a-form-item>
        </div>
      </div>
</template>

<script>
import {fromLonLat, get as getProjection} from "ol/proj";
import {getTopLeft, getWidth} from "ol/extent";
import View from "ol/View";
import {Image, Tile as TileLayer, Vector as VectorLayer} from "ol/layer";
import {ImageWMS, Vector as VectorSource, WMTS} from "ol/source";
import tdt_api from "../../../../homeMap/config/tdt_api";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import Map from "ol/Map";
import {MousePosition, ScaleLine} from "ol/control";
import {createStringXY} from "ol/coordinate";
import WKT from "ol/format/WKT";

import map_info from "../../../../homeMap/common/map_info";
import moment from "moment";
export default {
  name: "sdcInfo",
  components:{
    map_info
  },
  data(){
    return{
      map_id:"map_1",
      map:null,// 地图容器
      geom:null,
      wktGeom:null,
      flag:false,
      infoStyle:'info_1',

      formData:{
        departmentName:null,
        createUserName:null,
        createTime:null,
        explain:null,
      },
      zjd_geom:null,
      file_list:[],
    }
  },
  methods:{
    showInfo(type,formData,zjd_geom,file_list){
      this.formData = formData;
      this.zjd_geom = zjd_geom;
      this.file_list = file_list;
      this.flag = true;
      this.infoStyle = 'info_2';
      /** map_id解决同个页面引入多个map情况**/
      this.map_id = 'map'+type;
      setTimeout(()=>{
        this.initMap(zjd_geom);
      },200)

    },
    /** 初始化地图 **/
    initMap(wktGeom){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }

      // 视图
      var view = new View({
        center: fromLonLat([106.7572,26.7782]),//106.6472,26.4332
        zoom:13
      });
      var TiandiMap_img = new TileLayer({
        name: "天地图影像图层",
        opacity:0.7,
        source:new WMTS({
          url:tdt_api.wmtsUrl_1,
          layer:'img',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });
      var TiandiMap_cia = new TileLayer({
        name: "天地图影像注记图层",
        source: new WMTS({
          url:tdt_api.wmtsUrl_2,
          layer:'cia',
          matrixSet:'c',
          format:'tiles',
          style:'default',
          projection:projection,
          tileGrid:new WMTSTileGrid({
            origin:getTopLeft(projectionExtent),
            resolutions:resolutions,
            matrixIds:matrixIds
          }),
          wrapX:true
        })
      });

      // 地图
      this.map = new Map({
        target:this.map_id,
        layers:[
          TiandiMap_img,
          TiandiMap_cia
        ],
        /**
         * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
         * */
        loadTilesWhileAnimating: true,//将这个设置为true，默认为false
        view:view
      });

      //添加比例尺控件
      var scaleLineControl = new ScaleLine({
        units: 'metric',
        target: 'scalebar',
        className: 'ol-scale-line'
      });
      this.map.addControl(scaleLineControl);
      this.layers_list();

      if(wktGeom != null){
        this.wkt_show(wktGeom);
      }

    },

    /** 添加图层 **/
    layers_list(){
      const userInfo = this.$store.getters.userInfo;
      // 区域权限id
      let area_id = userInfo.villageIds;
      var cql_cwh = '';// 村委会
      var cql_zjd = '';// 宅基地
      // console.log(area_id);
      if(area_id.length > 0){
        cql_cwh = ' id in ( ' ;
        cql_zjd = ' villageid in ( ' ;
        for(let i=0;i<area_id.length;i++){
          let areaId = area_id[i];
          if(i == 0){
            cql_cwh += '\''+areaId+'\'';
            cql_zjd += '\''+areaId+'\'';
          }else{
            cql_cwh += ','+ '\''+areaId+'\'';
            cql_zjd += ','+ '\''+areaId+'\'';
          }
        }
        cql_cwh += ' )';
        cql_zjd += ' )';
      }
      // cql = ' id in (\'1\',\'792ad3aa-5b19-4e96-a044-80aad5cf2535\',\'b2963bfd-4020-4127-bd0a-5aa45ad33d1a\')' ;
      /*this.leyer_town = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:town',// geoserver发布乡镇服务
            'STYLES': '',
            // 'CQL_FILTER': filter_parms,  // 图层属性参数绑定
          },
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_town);*/
      var longText = new Array(205).join("1234567890");
      this.leyer_village = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:village',// geoserver发布村服务
            'STYLES': '',
            'CQL_FILTER': cql_cwh,  // 图层属性参数绑定
          },
          makeTheUrlLong: longText,
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_village);
      this.leyer_zjd_land_plots = new Image({     //TileLayer、ImageLayer
        source: new ImageWMS({    //TileWMS、ImageWMS
          ratio: 1,
          params: {
            //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
            'VERSION': '1.1.0',
            'LAYERS': 'zhaijidi:zjd_land_plots',// geoserver发布宅基地线服务
            'STYLES': '',
            'CQL_FILTER': cql_zjd,  // 图层属性参数绑定
          },
          url: tdt_api.geoserver_url
        })
      })
      this.map.addLayer(this.leyer_zjd_land_plots);
    },

    /** wkt处理 **/
    wkt_show(wktGeom){
      this.wktGeom = wktGeom;

      var source = new VectorSource();
      // 解析 wkt字符串
      var feature = new WKT().readFeature(this.wktGeom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      });
      source.addFeature(feature);
      var vectorLayer = new VectorLayer({source: source});
      this.map.addLayer(vectorLayer);
      let view = this.map.getView();
      view.fit(feature.getGeometry(),{
        duration:1000,
        maxZoom:18,
      });
    },

    closeShow(){
      this.infoStyle = 'info_1';
      if(this.map != null){
        this.map = null;
      }
    },
    presentValue(date){
      if(date){
        return moment(date).locale('zh-cn').format("YYYY/MM/DD");
      }
      return "无";
    },
  },
}
</script>

<style scoped>
#info_1{
  height: 100%;
  width: 100%;
  display: none;
}
#info_2{
  height: 100%;
  width: 100%;
  display: block;
}
</style>