/**
 * @program: tyh-oa
 * @author: ly
 * @description: APIObject
 * @create: 2021-03-19 14:17
 **/
import axios from "axios";
axios.defaults.withCredentials = true;

class APIObject {
    constructor(url) {
        this.url = APIObject.baseUrl()  + url;
        this.tdtUrl = url;
    }
    static baseUrl(){
       // return "http://localhost:9090/";
        //return  "http://192.168.10.190:8083/ZJDHT/";
       return "../ZJDHT/";
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例  若传入则会提示catch
     */
    requestGET(_this,data,callback){
        axios.get(this.url,{params:data})
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                    location.reload();
                    return;
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */
    requestPOST(_this,data,callback){
        axios.post(this.url,data)
            .then(res=> {
                    if(res.data.code === 401){
                        _this.$message.error(res.data.msg);
                        location.reload();
                        return;
                    }
                    if (callback) {
                        callback(res)
                    } else {
                        if(res.data.flag){
                            _this.$message.success(res.data.msg);
                        }
                        else{
                            _this.$message.error(res.data.msg);
                        }
                    }
                }
                )
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }

    /**
     * POST情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */
    requestPOSTUrlParam(_this,data,callback){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios.post(url)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                    location.reload();
                    return;
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }

    requestPOSTBothParam(_this,{urlParam,formParam},callback){
        let url = this.url+"?";
        for(let i in urlParam){
            if(urlParam[i] !== null && urlParam[i] !==""){
                let str = i+"="+urlParam[i];
                url += str + "&";
            }
        }
        url = url.substr(0,url.length-1);
        axios.post(url,formParam)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.msg);
                    location.reload();
                    return;
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.flag){
                        _this.$message.success(res.data.msg);
                    }
                    else{
                        _this.$message.error(res.data.msg);
                    }
                }
            })
            .catch(error=>{
                if(_this){
                    _this.$message.error({content:error.toString(),key:"APIObject"});
                }})
    }
    fileDownload(_this,data,fileName){//下载文件使用
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        let hz = data.webPath.substr(data.webPath.indexOf("."),data.webPath.length-1);
        url = url.substr(0,url.length-1);
        axios({
            methods: 'get',
            url,
            responseType: 'blob'
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
                location.reload();
                return;
            }
            // 非IE下载
            if ('download' in document.createElement('a')) {
                let tagA = document.createElement('a');
                tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                tagA.download = fileName+''+hz ;// 下载后文件名
                tagA.style.display = 'none';
                document.body.appendChild(tagA);
                tagA.click();
                window.URL.revokeObjectURL(tagA.href);
                document.body.removeChild(tagA);
            }
            else{
                window.navigator.msSaveBlob(res.data,fileName);
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }
    fileDownload_id(_this,data,fileName){//下载文件使用
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios({
            methods: 'get',
            url,
            responseType: 'blob'
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
                location.reload();
                return;
            }
            // 非IE下载
            if ('download' in document.createElement('a')) {
                let tagA = document.createElement('a');
                tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                tagA.download = fileName+'.docx';// 下载后文件名
                tagA.style.display = 'none';
                document.body.appendChild(tagA);
                tagA.click();
                window.URL.revokeObjectURL(tagA.href);
                document.body.removeChild(tagA);
            }
            else{
                window.navigator.msSaveBlob(res.data,fileName);
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }

    /**
     * 获取图层属性信息
     * @param data
     * @param callback
     * @param _this
     */
    requestUrlParam(_this,data,callback){
        let url = this.tdtUrl
        url = url.substr(0,url.length-1);
        axios.post(url)
            .then(res=>{
                callback(res);
            })
            .catch(error=> {
                alert(error)
            });
    }

    fileDownloads(_this,fileName){//下载文件使用
        let url = this.url;
        axios({
            methods: 'get',
            url,
            responseType: 'blob',

           /* method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }*/
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.msg);
                location.reload();
                return;
            }
            // 非IE下载
            if ('download' in document.createElement('a')) {
                let tagA = document.createElement('a');
                tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                tagA.download = fileName+'.xlsx' ;// 下载后文件名
                tagA.style.display = 'none';
                document.body.appendChild(tagA);
                tagA.click();
                window.URL.revokeObjectURL(tagA.href);
                document.body.removeChild(tagA);
            }
            else{
                window.navigator.msSaveBlob(res.data,fileName);
            }
        }).catch(error=>{
            if(_this){
                _this.$message.error({content:error.toString(),key:"APIObject"});
            }});
    }

    file_download(data){
        let url = APIObject.baseUrl() + "/api/file/download.do"+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str;
        }
        window.location.href = url;
    }

    /**
     * 异步请求
     * @param data
     * @returns {Promise<any>}
     */
    async requestPOSTAsync(data){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        let res = await axios.post(url,data);
        return res.data;
    }

    getUrl(url){
        return APIObject.baseUrl() + url;
    }

}


export default APIObject;