<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar ant-row">
      <a-input class="search-item ant-col-4" v-model:value="queryText" placeholder="标题" />
      <a-select
          v-model:value="type"
          placeholder="公告类型"
          class="search-item ant-col-4">

        <a-select-option v-for="(item) in searchOptions" :key="item.key">
          {{ item.value }}
        </a-select-option>
      </a-select>
      <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
        <template #icon><Search /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
        <template #icon><Reset /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><Add /></template>
      </a-button>
    </div>
</template>

<script>
    import Icons from "../../../components/Icons";
    import dic_api from "../../sys/dictionary/config/dic_api";

    export default {
        name: "informationToolBar",
        mixins:[Icons],
        data(){
          return{
            searchOptions:[],
            queryText:null,
            type:null,
          }
        },
      created() {
        dic_api.queryByNameCode.requestPOSTUrlParam(this,{nameOrCode:this.$eventDict.announcement_type},res=>{
          if(res.data.flag){
            let data = res.data.data;
            if(data != null){
              let children = data.children;
              if(children.length > 0){
                for(let i=0;i<children.length;i++){
                  this.searchOptions.push({
                    key:children[i].dictCode,
                    value:children[i].dictName
                  })
                }
              }
            }
          }
          else{
            this.$message.error(res.data.msg)
          }
        })
      },
        methods:{
          doSearch(){
            let parms = {
              queryText:this.queryText,
              type:this.type,
            };
            let data = this.$GetEmitData(this.$eventTags.search,parms);
            this.$emit("event",data);
          },
          refresh(){
            this.queryText = null;
            this.type = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          },
          addData(){
            this.$emit("event",this.$GetEmitData(this.$eventTags.add));
          },
          getParms(){
            let parms = {
              queryText:this.queryText,
              type:this.type,
            }
            return parms;
          },

        }
    }
</script>

<style scoped>

</style>