/**
 * @description：
 * @author:rzl
 * @date:2022/4/27 14:44
 **/
import APIObject from "../../../../../assets/utils/APIObject";
const api={
    queryAllByApplyId:new APIObject("/api/zSceneExamine/queryAllByApplyId.do"),// 宅基地现场查验管理-查询宅基地申请的现场查验情况
    queryAllFileById:new APIObject("/api/zSceneExamine/queryAllFileById.do"),// 宅基地现场查验管理-查询现场照片
    get:new APIObject("/api/zSceneExamine/get.do"),// 宅基地现场查验管理-单个查询
};
export default api;