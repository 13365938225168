<!--
*@description：抽屉组件：编辑页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <a-drawer
      width="40%"
      :visible="visible_drawer"
      body-style="padding-bottom: 80px;margin-top: 6%"
      :closable="false"
      placement="right"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      :destroyOnClose="true"
  >

    <div class="drawer-header">  {{title}}  </div>


    <div  v-is="comp"  ref="deawer_comp" @event="assign" style="height: 100%;overflow-y: auto;" />

    <div class="drawer-footer" style="height: 70px;">
      <a-tag color="#2db7f5" style="float: left;" @click="showhgfx">合规分析</a-tag>
      <a-tag color="#2db7f5" style="float: left;" @click="showFile">文件信息</a-tag>
      <a-tag color="#2db7f5" style="float: left;" @click="showZddt">宅基地地图</a-tag>
      <a-tag color="#2db7f5" style="float: left;" @click="showShjl">审核进度</a-tag>
      <a-tag color="#2db7f5" style="float: left;" @click="showSdc">三到场</a-tag>
      <a-button style="margin-right: 8px;bottom: -20px;" @click="onClose">
        取消
      </a-button>
      <a-button v-if="sub_flag" type="primary" @click="submit" style="bottom: -20px;">
        提交
      </a-button>
    </div>
  </a-drawer>

  <!-- 文件信息 -->
  <file-info-tk  ref="fileInfoBut"/>

  <!--宗地图信息-->
  <map_tk ref="mapTkBut"/>

  <!--审核记录-->
  <form_timeline ref="timeLine"/>

  <!-- 合规分析 -->
  <hgfx-tk ref="hgfxTk" />

  <!-- 三到场 -->
  <sdc-tk ref="sdcTk"/>
</template>

<script>
import {computed} from "vue";
import fileInfoTk from "../../common/point/file/fileInfoTk";
import map_tk from "../../../homeMap/common/map_tk";
import form_timeline from "../../common/point/sh/form_timeline";
import zdrkShow from "../../common/point/fdyt/zdrkShow";
import hgfxTk from "../../common/point/hgfx/hgfxTk";
import sdcTk from "../../common/point/sdc/sdcTk";

import zjd_show from "../point/zjd_show";

export default {
name: "zjdInfo_drawer_zjd",
components:{
  zjd_show,fileInfoTk,map_tk,form_timeline,zdrkShow,hgfxTk,sdcTk
},
provide(){
  return{
    arguments:computed(()=>this.argument),
  }
},
data(){
    return{
      visible_drawer: false,
      title:null,
      comp:null,
      sub_flag:false,
      argument:null,
    }
},
methods:{
  openDrawer(title,component,sub_flag,argument){
    this.visible_drawer = true;
    this.title = title;
    this.comp = component;
    this.sub_flag = sub_flag;
    this.argument = argument;
  },
  submit(){
    switch (this.comp){
      case "zjd_add":{
        let  parms  = this.$refs.deawer_comp.getParms();
        zjdApply_api.saveWeb.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
        break;
      }
      case "zjd_edit":{
        let  parms  = this.$refs.deawer_comp.getParms();
        zjdApply_api.updWeb.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
        break;
      }
    }
  },
  assign({type,arg}){
    switch (type){
      case this.$eventTags.add:{
        this.argument.formData = arg;
        break;
      }
      case this.$eventTags.edit:{
        this.argument.formData = arg;
        break;
      }
    }

  },
  onClose() {
    this.visible_drawer = false;
  },
  showhgfx(){
    this.$refs.hgfxTk.openHgfxTk_wkt_dzd(this.argument.formData.geom);
  },
  showFile(){
    this.$refs.fileInfoBut.openFile(this.argument.formData.id);
  },
  showZddt(){
    this.$refs.mapTkBut.openMap(this.argument.formData.geom);
  },
  showShjl(){
    this.$refs.timeLine.open_timeLine(this.argument.formData.id);
  },
  showSdc(){
    this.$refs.sdcTk.openTk(this.argument.formData.id,this.argument.formData.geom);
  },
}
}
</script>

