<!--
* @program: tyh-oa 
* @author: ly
* @description: menuContent 每个菜单页面的基本样式
* @create: 2021-03-22 16:30
-->
<template>
    <a-layout style="height: 100%" >
        <a-layout-sider>
            <a-menu
                theme="dark"
                mode="inline"
                v-modal:openKeys="openKeys"
                @click="handleClick">
                <sub-group-menu v-for="group in menuLists" :groupMenus="group" :key="group.id" />
            </a-menu>
        </a-layout-sider>

        <a-layout-content>
            <div v-is="contentType" />
        </a-layout-content>
    </a-layout>
</template>

<script>
    import subGroupMenu from "./subGroupMenu";
    import {EmitData} from "../../assets/utils/general";
    import welcome from "../../view/welcome";
    import nonComponent from "../nonComponent";
    import {BaseMenu} from "../../assets/system/menu";
    import {menu} from "../../assets/system/menu"

    export default {
        name: "menuContent",
        components:{
            subGroupMenu,
            welcome,nonComponent
        },
        inject:["subMenus"],
        emits:{
            event:(data)=>{
                if(data instanceof EmitData){
                    return true;
                }
                else{
                    console.warn("menuContent event data type error!");
                    return false;
                }
            }
        },
        data(){
            return{
               // menuLists:menu.children,
                contentType:"welcome",
                openKeys:['c3785e00-6ae4-4d6b-b404-eef4c26538c4'],
            }
        },

        methods:{
            handleInit(menuLists){
                 this.menuLists = menuLists;
            },
            handleClick({ item, key, keyPath }){

                if(key == 'cfd9e8ed-ab0f-47a1-954a-b87e79fa53aa'){// 大屏
                  window.open('screen_main.html');

                }
                else{
                  let type = "nonComponent";
                  for(let i in this.menuLists){
                    for(let j in this.menuLists[i].children){
                      if(key === this.menuLists[i].children[j].id){
                        type =  this.menuLists[i].children[j].component;
                      }
                    }
                  }
                  this.contentType = type;
                }
            }
        },
        mounted() {
           // this.handleInit(this.subMenus);

        }
    }
</script>

<style scoped>

</style>