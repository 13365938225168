/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    upd:new APIObject("/api/department/upd.do"),// 系统部门管理-修改
    queryPage:new APIObject("/api/department/queryPage.do"),// 系统部门管理-分页查询
    get:new APIObject(" /api/department/get.do"),// 系统部门管理-单条查询
    save:new APIObject("/api/department/save.do"),// 系统部门管理-保存
    delete:new APIObject("/api/department/delete.do"),// 系统部门管理-单条删除
    queryAll:new APIObject("/api/department/queryAll.do"),// 系统部门管理-查询所有未删除的顶级部门及子部门

    findVillage:new APIObject("/api/departmentVillageRelation/findVillage.do"),// 查询-查询部门可关联的村及已关联村id
    save_zzqy:new APIObject("/api/departmentVillageRelation/save.do"),// 更新-更新部门-村关联（增删改合一）
    queryPageDTO:new APIObject("/api/department/queryPageDTO.do"),// 查询-分页查询部门信息
};

export default api;