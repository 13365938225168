/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../assets/utils/APIObject";

const api={
    queryPageForManage:new APIObject("/api/zAnnouncement/queryPageForManage.do"),// 宅基地公告信息管理- 管理部门分页查询（web端管理使用）
    get:new APIObject("/api/zAnnouncement/get.do"),// 宅基地公告信息管理-单个查询
    save:new APIObject("/api/zAnnouncement/save.do"),// 宅基地公告信息管理-新增
    upd:new APIObject("/api/zAnnouncement/upd.do"),// 宅基地公告信息管理-修改
    delete:new APIObject("/api/zAnnouncement/delete.do"),//宅基地公告信息管理-删除
    publish:new APIObject("/api/zAnnouncement/publish.do"),// 宅基地公告信息管理-发布
    recall:new APIObject("/api/zAnnouncement/recall.do"),// 宅基地公告信息管理-撤回
};

export default api;