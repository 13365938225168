<!--
* @description：生成文档弹框
* @author:rzl
* @date:2022/1/12 14:59
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="45%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-tabs v-model:activeKey="activeKey" tab-position="left">
        <a-tab-pane key="1" tab="宅基地申请表">
          <a-button type="primary" @click="download_wd_file('zjdsqb','宅基地申请表')" style="background-color: #376ba3;">宅基地申请表下载</a-button>
        </a-tab-pane>
        <a-tab-pane key="2" tab="宅基地审批表">
          <a-button type="primary" @click="download_wd_file('zjdspb','宅基地审批表')" style="background-color: #db4a4c;">宅基地审批表下载</a-button>
        </a-tab-pane>
        <a-tab-pane key="3" tab="建设规划许可证">
          <ar-form  ref="form_zjd" :argument="argument_ghxk" :viewModel="is_wj"/>
          <a-button v-if="!is_wj" type="primary" @click="submit_ghxk" style="background-color: #1679e5;">
            提交
          </a-button>
          <a-button type="primary" @click="download_wd_file('jsghxkz','建设规划许可证')" style="background-color: #5fa5cb;margin-left: 20px;">建设规划许可证下载</a-button>
        </a-tab-pane>
        <a-tab-pane key="4" tab="宅基地批准书">
          <ar-form  ref="form_zjd" :argument="argument_pzs" :viewModel="is_wj"/>
          <a-button v-if="!is_wj" type="primary" @click="submit_pzs" style="background-color: #1679e5;">
            提交
          </a-button>
          <a-button type="primary" @click="download_wd_file('zjdpzs','宅基地批准书')" style="background-color: #59b52c;margin-left: 20px;">宅基地批准书下载</a-button>
        </a-tab-pane>
        <a-tab-pane key="5" tab="宅基地验收意见表">
          <ar-form  ref="form_zjd" :argument="argument_ysyjb" :viewModel="is_wj"/>
          <a-button v-if="!is_wj" type="primary" @click="submit_ysyjb" style="background-color: #1679e5;">
            提交
          </a-button>
          <a-button type="primary" @click="download_wd_file('ysyjb','宅基地验收意见表')" style="background-color: #59b52c;margin-left: 20px;">宅基地验收意见表下载</a-button>
        </a-tab-pane>
      </a-tabs>

    </div>

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "../../../../../components/Icons";
import ArForm from "../../../../../components/form/ArForm";

import planLicence_item from "../../config/wd/planLicence_item";
import homesteadApprovalletter_item from "../../config/wd/homesteadApprovalletter_item";
import wd_api from "../../config/wd/wd_api";
import file_api from "../../../../common/config/file_api";
import zAcceptanceComments_item from "../../config/wd/zAcceptanceComments_item";

export default {
  name: "documentationTk",
  mixins:[Icons],
  components:{
    ArForm
  },
  data(){
    return{
      visible:false,
      modalTitle:null,
      labelCol:{ span: 6 },
      wrapperCol:{ span: 16 },
      activeKey:"1",

      formData_zjd:{},
      argument_ghxk:{
        formColumns:new planLicence_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData: {},
        flag:true,// true新增
      },

      argument_pzs:{
        formColumns:new homesteadApprovalletter_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData: {},
        flag:true,// true新增
      },
      argument_ysyjb:{
        formColumns:new zAcceptanceComments_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData: {},
        flag:true,// true新增
      },

      is_wj:false,// 是否完结

      // 各类文档生成情况
      formdata:{
        application:false,
        applicationUrl:'',

        auditTable:false,
        auditTableUrl:'',

        planLicence:false,
        planLicenceUrl:'',

        homesteadApprovalletter:false,
        homesteadApprovalletterUrl:'',

        acceptanceComments:false,
        acceptanceCommentsUrl:'',
      },
    }
  },
  methods:{
    /**
     * 打开弹框
     * @param info 宅基地申请信息
     */
    openDocumentationTk(info){
      this.visible = true;
      this.modalTitle = "文档信息";
      this.formData_zjd = info;
      this.activeKey = "1";

      if(info.auditStatus == '6.0'){
        this.is_wj = true;
      }

     // 查询生成文档情况
      this.getDocGenerate(info.id);

      // 查询规划许可、批准书、验收意见表表单填写情况
      this.query_wd_info(info.id);


    },
    closeTk(){
      this.visible = false;
    },
    getDocGenerate(id){
      let parms = {
        applyId:id,
      };
      wd_api.getDocGenerate.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.formdata = res.data.data;
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    query_wd_info(id){
      this.argument_ghxk.formData = {};
      this.argument_pzs.formData = {};
      this.argument_ysyjb.formData = {};
      let parms = {
        applyId:id,
      };
      wd_api.ghxk_getByApplyId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.argument_ghxk.formData = res.data.data;
          this.argument_ghxk.flag = false;
        }

      });

      wd_api.pzs_getByApplyId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.argument_pzs.formData = res.data.data;
          this.argument_pzs.flag = false;
        }
      });

      wd_api.ysyjb_getByApplyId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.argument_ysyjb.formData = res.data.data;
          this.argument_ysyjb.flag = false;
        }
      });
    },
    submit_ghxk(){
      let  parms  = this.argument_ghxk.formData;
      parms.applyId = this.formData_zjd.id;
      if(this.argument_ghxk.flag){
        wd_api.ghxk_save.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
            this.argument_ghxk.flag = false;
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }else{
        wd_api.ghxk_upd.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }

    },
    submit_pzs(){
      let  parms  = this.argument_pzs.formData;
      parms.applyId = this.formData_zjd.id;
      if(this.argument_pzs.flag){
        wd_api.pzs_save.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
            this.argument_pzs.flag = false;
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }else{
        wd_api.pzs_upd.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }

    },
    submit_ysyjb(){
      let  parms  = this.argument_ysyjb.formData;
      parms.applyId = this.formData_zjd.id;
      if(this.argument_ysyjb.flag){
        wd_api.ysyjb_save.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
            this.argument_ysyjb.flag = false;
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }else{
        wd_api.ysyjb_upd.requestPOST(this,parms,res=>{
          if(res.data.flag){
            this.$message.success(res.data.msg);
          }
          else{
            this.$message.error(res.data.msg);
          }
        })
      }

    },
    download_wd_file(type,name){
      switch (type){
        case "zjdsqb":{
          let flag = this.formdata.application;
          let fileUrl = '';
          if(flag){
            fileUrl = this.formdata.applicationUrl;
            this.downloadFile(name,fileUrl);
          }else{
            let parms = {
              applyId:this.formData_zjd.id,
            };

            wd_api.applicationGenerate.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                fileUrl = res.data.data.path;
                this.formdata.application = true;
                this.formdata.applicationUrl = res.data.data.path;
                this.downloadFile(name,fileUrl);
              }
              else{
                this.$message.error(res.data.msg);
              }
            })
          }
          break;
        }
        case "zjdspb":{
          let flag = this.formdata.auditTable;
          let fileUrl = '';
          if(flag){
            fileUrl = this.formdata.auditTableUrl;
            this.downloadFile(name,fileUrl);
          }else{
            let parms = {
              applyId:this.formData_zjd.id,
            };
            wd_api.auditTableGenerate.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                fileUrl = res.data.data.path;
                this.formdata.auditTable = false;
                this.formdata.auditTableUrl = res.data.data.path;
                this.downloadFile(name,fileUrl);
              }
              else{
                this.$message.error(res.data.msg);
              }
            })
          }

          break;
        }
        case "jsghxkz":{
          let flag = this.formdata.planLicence;
          let fileUrl = '';
          if(flag){
            fileUrl = this.formdata.planLicenceUrl;
            this.downloadFile(name,fileUrl);
          }else{
            let parms = {
              applyId:this.formData_zjd.id,
            };
            wd_api.planLicenceGenerate.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                fileUrl = res.data.data.path;
                this.formdata.planLicence = true;
                this.formdata.planLicenceUrl = res.data.data.path;
                this.downloadFile(name,fileUrl);
              }
              else{
                this.$message.warning("请先填写并提交规划许可证信息！");
              }
            })

          }

          break;
        }
        case "zjdpzs":{
          let flag = this.formdata.homesteadApprovalletter;
          let fileUrl = '';
          if(flag){
            fileUrl = this.formdata.homesteadApprovalletterUrl;
            this.downloadFile(name,fileUrl);
          }else{
            let parms = {
              applyId:this.formData_zjd.id,
            };
            wd_api.homesteadApprovalletterGenerate.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                fileUrl = res.data.data.path;
                this.formdata.homesteadApprovalletter = true;
                this.formdata.homesteadApprovalletterUrl = res.data.data.path;
                this.downloadFile(name,fileUrl);
              }
              else{
                this.$message.warning("请先填写并提交批准书信息！");
              }
            })

          }

          break;
        }
        case "ysyjb":{
          let flag = this.formdata.acceptanceComments;
          let fileUrl = '';
          if(flag){
            fileUrl = this.formdata.acceptanceCommentsUrl;
            this.downloadFile(name,fileUrl);
          }else{
            let parms = {
              applyId:this.formData_zjd.id,
            };
            wd_api.acceptanceCommentsGenerate.requestPOSTUrlParam(this,parms,res=>{
              if(res.data.flag){
                fileUrl = res.data.data.path;
                this.formdata.acceptanceComments = true;
                this.formdata.acceptanceCommentsUrl = res.data.data.path;
                this.downloadFile(name,fileUrl);
              }
              else{
                this.$message.warning("请先填写并提验收意见表信息！");
              }
            })

          }

          break;
        }
      }
    },
    downloadFile(fileName,fileUrl){
      if(this.$checkUtil.empty_str(fileName) || this.$checkUtil.empty_str(fileUrl)){
        return;
      }
      let data = {
        webPath:fileUrl
      };
      file_api.downloadByWebPath.fileDownload(this,data,fileName);
    },
  },

}
</script>
