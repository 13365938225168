<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
      <a-input class="search-item ant-col-4" v-model:value="title" placeholder="请输入关键字" />
      <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
          <template #icon><Search /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
          <template #icon><Reset /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="addData">
        <template #icon><Add /></template>
      </a-button>
    </div>
</template>

<script>
    import Icons from "../../../../components/Icons";
    export default {
      name: "menu_ToolBar",
      mixins:[Icons],
      data(){
        return{
          title:null,
        }
      },
      methods:{
        doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search,{title:this.title});
            this.$emit("event",data);
        },
        refresh(){
            this.title = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            title:this.title,
          }
          return parms;
        },
      }
    }
</script>

<style scoped>

</style>