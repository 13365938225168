/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryAll:new APIObject("/api/obligee/queryAll.do"),// 权利人管理-查询所有权利人
    getForManage:new APIObject("/api/obligee/getForManage.do"),// 权利人管理-管理部门单个查询
    queryPageForManage:new APIObject("/api/obligee/queryPageForManage.do"),// 权利人管理-管理部门分页查询
    saveWeb:new APIObject("/api/obligee/saveWeb.do"),// 权利人管理-web端新增
    deleteWeb:new APIObject("/api/obligee/deleteWeb.do"),// POST /api/obligee/deleteWeb.do权利人管理-web端单个删除
    updWeb:new APIObject("/api/obligee/updWeb.do"),// 权利人管理-web端修改

    area_findByUser:new APIObject("/api/village/findByUser.do"),// 系统村级行政区模块-查询用户的村级辖区

    queryPageForAudit:new APIObject("/api/obligeeApply/queryPageForAudit.do"),// 权利人关联申请管理-审核人分页查询
    get:new APIObject("/api/obligeeApply/get.do"),// 权利人关联申请管理-单个查询
    audit:new APIObject("/api/obligeeApply/audit.do"),// 权利人关联申请管理-审核申请
};

export default api;