<!--
* @program: tyh-oa 
* @author: ly
* @description: typeDate 
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center">{{presentValue}}</div>
    </div>
    <div v-else>
        <div v-if="editable">
          <a-date-picker v-if="dateFormat == 'YYYY/MM/DD'" style="width: 100%" v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
          <a-month-picker v-if="dateFormat == 'YYYY/MM'" style="width: 100%" v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
          <a-month-picker v-if="dateFormat == 'YYYY'" style="width: 100%" v-model:value="date" :format="dateFormat" @change="columnTrigger"/>
        </div>
        <div v-else>
            <div style="text-align: center">{{presentValue}}</div>
        </div>
    </div>
</template>

<script>

    import typeBase from "./typeBase";
    import moment from "moment"
    export default {
        name: "typeDate",
        mixins:[typeBase],
        watch:{
            date(){
                if(this.date instanceof moment){
                    this.record[this.column.dataIndex] = this.date.valueOf();
                }
                else{
                    this.record[this.column.dataIndex] = null
                }
            },
            record(){
                this.init();
                this.columnTrigger();
            }
        },
        data(){
            return{
                dateFormat: '',
                date:null,
            }
        },
        computed:{
            presentValue(){
                let date = this.record[this.column.dataIndex];
                if(date){
                    return moment(date).locale('zh-cn').format(this.dateFormat);
                }
                return "无";
            },
        },
        methods:{
            init(){
                this.date = this.record[this.column.dataIndex]?moment(this.record[this.column.dataIndex]):null;
                this.dateFormat = this.column.dateFormat == null ? 'YYYY/MM/DD' : this.column.dateFormat;
            }
        },
        mounted() {
           this.init();
        }
    }
</script>

<style scoped>

</style>