/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    batchstateModify:new APIObject("/api/role/batchstateModify.do"),//启用、禁用-批量
    queryAll:new APIObject("/api/role/queryAll.do"),//查询-全部不分页(根据name,roleState)
    stateModify:new APIObject("/api/role/stateModify.do"),//启用、禁用-单条
    queryOne:new APIObject("/api/role/queryOne.do"),//查询-单条(根据主键)
    findRoleByUser:new APIObject("/api/role/findRoleByUser.do"),//查询-全部不分页(根据userId查询用户所拥有的角色)
    delete:new APIObject("/api/role/delete.do"),//删除-单条
    batchSaveOrUpd:new APIObject("/api/role/batchSaveOrUpd.do"),//保存/修改-批量
    batchDelete:new APIObject("/api/role/batchDelete.do"),//删除-批量
    saveOrUpd:new APIObject("/api/role/saveOrUpd.do"),//保存/修改-单条
    queryPage:new APIObject("/api/role/queryPage.do"),//查询-分页(根据name,roleState)

    queryTree_menu:new APIObject("/api/menu/queryTree.do"),// 查询所有菜单树
    authorization_save:new APIObject("/api/roleMenuRelation/save.do"),// 新增-给一个角色授权多个菜单
    queryMenuByRole_menu:new APIObject("/api/menu/queryMenuByRole.do"),//查询-根据角色id查询有权限的菜单
};

export default api;