

/**
 * @program: tyh-oa
 * @author: ly
 * @decription: 字段结构
 * @create: 2020-08-25 16:38
 **/

import ColumnType from "./column_type";
import moment from "moment";
import {uuid} from "../../utils/general";

class Column{
    /**
     * @param title 表头显示
     * @param dataIndex 对应dataObject内的属性字段/segment
     * @param type  字段类型/用于区别customRender
     * @param editable 是否允许编辑
     * @param visible 是否可见
     * @param enums type 为Enum时候的枚举数据
     * @param enumTags 当Enum类型时候，用于代替Enum值的TAG，
     * 如：数据库中["0","1"]类型,但是显示的时候却显示["未归档","已归档"],需要与enums一一对应
     * @param width 字段宽度
     * @param defaultValue  字段默认值
     * @param tableView true的情况为table的时候所显示的字段
     * @param idReplaceObject 用于idReplace的字段属性
     * @param boolTags 用于标记true 和 false的情况下 显示的文字，["是","否"]
     * @param replaceObject 用于ObjectReplace的字段属性
     * @oaram sorter: (a, b) => a.age - b.age,
     * @param categoryTrigger  字段触发器 [trigger,trigger2] true:trigger  false:trigger2
     * @param group  有时候需要将字段分区展示，用于区分字段的标记  默认group = 0
     * @children 表头分组使用
     */
    constructor (title,dataIndex,type,editable) {
        this.ref = "ref_"+uuid(8,16);//唯一标签  需要强制初始化的时候使用
        this.title = title;
        this.dataIndex = dataIndex;
        this.slots = new Slots(type);
        this.editable = !!editable;
        this.visible = true;
        this.width = 200;
        this.defaultValue = null;
        this.idReplaceObject = null;
        this.iQlrUse = null;
        this.group = 0;
        if(type === ColumnType.Number){
            this.sorter = (a, b) => a[this.dataIndex] - b[this.dataIndex];
        }
        if(type === ColumnType.Date){
            this.sorter = (a ,b) =>{
                return  moment(a[this.dataIndex]).isAfter(b[this.dataIndex])
            }
        }
        if(type === ColumnType.Month){
            this.sorter = (a ,b) =>{
                return  moment(a[this.dataIndex]).isAfter(b[this.dataIndex])
            }
        }
        if(type === ColumnType.String){
            this.onFilter= (value, record) =>{
                return record[this.dataIndex].includes(value)
            };
        }
        if(type === ColumnType.Enum){
            this.onFilter= (value, record) =>{
                return record[this.dataIndex].includes(value)
            };
        }
    }
    setVisible(bool){
        this.visible = bool;
        return this;
    }
    setEnum(enums,tags,colors){
        this.slots = new Slots(ColumnType.Enum);
        this.onFilter= (value, record) =>{
            return record[this.dataIndex].includes(value)
        };
        this.enums = enums;
        this.enumTags = tags?tags:null;
        this.enumColors = colors?colors:null;
        return this;
    }
    setDefaultValue(value){
        this.defaultValue = value;
        return this;
    }
    setTableView(width){
        this.tableView = true;
        if(width){
            this.width = width;
        }
        return this;
    }
    setWidth(width){
        this.width = width;
        if(this.slots.getType() === ColumnType.Number){
            this.width +=40;
        }
        return this;
    }
    setTriggerOtherColumn(value,action,action2){
        if(!this.triggerOtherColumn){
            this.triggerOtherColumn = [];
        }
        this.triggerOtherColumn.push(new triggerOtherColumnObject(value,action,action2));
        return this;
    }
    setIdReplaceObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.IdReplace);
        return this;
    }
    setIdReplace2Object(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.IdReplace2);
        return this;
    }
    setObjectReplaceObject(objectName,seg,queryObject){
        this.replaceObject = new ObjectReplace(objectName,seg,queryObject);
        this.slots = new Slots(ColumnType.ObjectReplace);
        return this;
    }
    setBoolTags(tags){
        this.boolTags = tags;
        return this;
    }
    setChildren(children){
        this.children = children;
        return this;
    }
    setNumberRange(min,max){
        this.range = [min,max];
        return this;
    }
    setNumberFormatter(formatter,parser){
        this.formatter = formatter;
        this.parser = parser;
        return this;
    }
    setTitle(title){
        this.title = title;
        return this;
    }
    setSelectorObject(replaceSegment,options){
        this.selectorObject = new SelectorObject(replaceSegment,options);
        this.slots = new Slots(ColumnType.Selector);
        return this;
    }
    setCategoryTrigger(trigger,trigger2){
        this.categoryTrigger = [trigger,trigger2];
        return this;
    }
    setEditable(bool){
        this.editable = bool;
        return this;
    }
    setGroup(group){
        this.group = group;
        return this;
    }
    setPosition(pos){
        this.fixed=pos;
        return this;
    }

    setDateFormat(dateFormat){
        this.slots = new Slots(ColumnType.Date);
        this.dateFormat = dateFormat;
        return this;
    }

    // 设置字典配置
    setDictionary(dictType,colors){
        this.slots = new Slots(ColumnType.Dictionary);
        this.colors = colors?colors:null;
        this.dictType = dictType;
        return this;
    }

    // 设置字典下级配置
    setDictionary_c(dictPid,colors){
        this.slots = new Slots(ColumnType.Dictionary_c);
        this.colors = colors?colors:null;
        this.dictPid = dictPid;
        return this;
    }

    setIdReplaceXmgdObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.IdReplaceXmgd);
        return this;
    }

    setIQlrUse(replaceSegment,queryObject){
        this.iQlrUse = new QlrUseObject(replaceSegment,queryObject);
        this.slots = new Slots(ColumnType.iQlrUse);
        return this;
    }

}
export default Column;

class triggerOtherColumnObject {
    /**
     *
     * @param value 触发值 可以是返回true false的函数
     * @param action 触发条件为true时候的回调函数
     * @param action2 触发条件为false时候的回调函数
     */
    constructor(value,action,action2) {
        this.value = value;
        this.action = action;
        this.action2 = action2;
    }
}

class IdReplaceObject {
    /**
     *
     * @param replaceSegment 对应的字段
     * @param queryObject   编辑的时候使用的查询queryObject
     */
    constructor(replaceSegment,queryObject) {
        this.replaceSegment = replaceSegment;
        this.queryObject = queryObject;
    }
}

class QlrUseObject {
    /**
     *
     * @param replaceSegment 对应的字段
     * @param queryObject   编辑的时候使用的查询queryObject
     */
    constructor(replaceSegment,queryObject) {
        this.replaceSegment = replaceSegment;
        this.queryObject = queryObject;
    }
}

class SelectorObject {
    /**
     *
     * @param replaceSegment 替换的字段
     * @param options  选项
     */
    constructor(replaceSegment,options) {
        this.replaceSegment = replaceSegment;
        this.options = options;
    }
}
class ObjectReplace {
    /**
     *
     * @param objectName 后台返回的用于读取的属性字段objectName
     * @param seg         object中用于表示的的字段
     * @param queryObject 编辑的时候使用的查询queryObject
     */
    constructor(objectName,seg,queryObject) {
        this.objectName = objectName;
        this.seg = seg;
        this.queryObject = queryObject;
    }
}

class Slots {
    constructor(type) {
        this.customRender = type;
        this.title = "customTitle";
        if(type === ColumnType.String){
            this.filterDropdown= 'filterDropdown';
            this.filterIcon= 'filterIcon';
        }
    }
    getType(){
        return this.customRender;
    }

}
