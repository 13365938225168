/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryPage:new APIObject("/api/dict/queryPage.do"),// 查询-分页（名称、编码模糊查询）
    saveOrUpd:new APIObject("/api/dict/saveOrUpd.do"),// 保存/修改-单条
    delete:new APIObject("/api/dict/delete.do"),// 删除-单条
    getChildMap:new APIObject("/api/dict/getChildMap.do"),// 查询-根据字典名称或编码查询字典
    queryAll:new APIObject("/api/dict/queryAll.do"),// 查询-不分页（名称、编码模糊查询）
    batchDelete:new APIObject("/api/dict/batchDelete.do"),//删除-批量
    batchSaveOrUpd:new APIObject("/api/dict/batchSaveOrUpd.do"),// 保存/修改-批量
    queryOne:new APIObject("/api/dict/queryOne.do"),//查询-单条数据
    queryByNameCode:new APIObject("/api/dict/queryByNameCode.do"),// 查询-单条（名称或编码全匹配，同一名称或编码的只能有一条）
    queryALLChildById:new APIObject("/api/dict/queryALLChildById.do"),// 查询-查询字典的所有子字典
    queryPageDTO:new APIObject("/api/dict/queryPageDTO.do"),// 查询-分页查询字典信息
};

export default api;