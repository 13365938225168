/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryPageRescindForManage:new APIObject("/api/zApply/queryPageRescindForManage.do"),// 宅基地申请管理-管理部门分页查询（已撤销的)
    get:new APIObject("/api/zLandPlots/get.do"),// 查询-单条

    get_apply:new APIObject("/api/zApply/get.do"),// 宅基地申请管理-单个查询


};

export default api;