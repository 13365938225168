<!--
* @description：富文本框组件:查看
* @author:rzl
* @date:2022/4/25 17:01
-->

<template>
  <div style="border: 1px solid #ccc; margin-top: 10px;z-index: 999">
    <!-- 工具栏 -->
    <div class="toolbarClass" id="toolbar-container" ></div>

    <!-- 编辑器 -->
    <div class="editorClass" id="editor-container"></div>



  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { createEditor, createToolbar, IEditorConfig, IDomEditor } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import file_api from "../../common/config/file_api";
import {getUrl} from "/src/assets/utils/general";

export default {
  name: "wangeditorInfoComp",
  components: { Editor, Toolbar },
  data () {
    return {
      /** 编辑器**/
      editor: null,
      // default 默认模式 - 集成了 wangEditor 所有功能
      // simple 简洁模式 - 仅有部分常见功能，但更加简洁易用
      editor_mode: 'simple',

      /** 工具栏 **/
      toolbar:null,
      toolbar_mode: 'simple',
    }
  },
  mounted() {
    const editorConfig = {}
    // 创建编辑器
    this.editor = createEditor({
      selector: '#editor-container',
      config: editorConfig,
      mode: this.editor_mode
    })
    const toolbarConfig = {
      toolbarKeys:['fullScreen']
    };
    // 创建工具栏
    this.toolbar = createToolbar({
      editor:this.editor,
      config: toolbarConfig,
      selector: '#toolbar-container',
      mode: this.toolbar_mode
    })
    //this.getMenuKey();
  },
  methods: {
    getMenuKey(){
      let list = this.editor.getAllMenuKeys();
      console.log(list)
    },
    getText(){
      return this.editor.getText();
    },
    getHtml(){
      return this.editor.getHtml();
    },
    setHtml(html){
      this.editor.dangerouslyInsertHtml(html);
    },
    /** 文本 **/
    insertTextHandler () {
      const editor = this.editor
      if (editor == null) return
      editor.insertText(' hello ')
    },
    /** 插入html **/
    printEditorHtml () {
      const editor = this.editor
      if (editor == null) return
    },
    /** 设置富文本框不可编辑 **/
    disableHandler () {
      const editor = this.editor
      if (editor == null) return
      editor.disable()
    }
  },
}
</script>

<style scoped>
.toolbarClass{
  border-bottom: 1px solid #ccc
}
.editorClass{
  height: 400px;
  overflow-y: hidden;
}
</style>