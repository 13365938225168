<!--
* @program: tyh-oa 
* @author: ly
* @description: subGroupMenu 
* @create: 2021-03-22 16:56
-->
<template>
    <a-sub-menu  :key="groupMenus.id" >
        <template #title>
            {{groupMenus.title}}
        </template>
        <sub-menu v-for="menus in menuList" :menus="menus" :key="menus.id" />
    </a-sub-menu>
</template>

<script>
    import subMenu from "./subMenu";
    export default {
        name: "subGroupMenu",
        props:["groupMenus"],
        components:{
            subMenu
        },
        computed:{
            menuList(){
                return this.groupMenus.children.filter(menu=>{
                    return menu.enable ===true;
                });
            }
        }
    }
</script>

<style scoped>

</style>