<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar ant-row">
      <a-input class="search-item ant-col-4" v-model:value="queryText"  placeholder="证件号/权利人名称" />

      <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
        <template #icon><Search /></template>
      </a-button>
      <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
        <template #icon><Reset /></template>
      </a-button>

    </div>
</template>

<script>
    import Icons from "../../../../components/Icons";

    export default {
        name: "fdytqlrToolBar",
        mixins:[Icons],
        data(){
          return{
            queryText:null,
          }
        },
        methods:{
          doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search,{queryText:this.queryText});
              this.$emit("event",data);
          },
          refresh(){
            this.queryText = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
          },
          getParms(){
            let parms = {
              queryText:this.queryText,
            }
            return parms;
          },
        }
    }
</script>

<style scoped>

</style>