/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"
import qlrInfo_api from "./qlrInfo_api";
export default class qlrInfo_item extends Table{
    constructor() {
        super();
        this.name = "qlrInfo_item";
        this.CNName = "权利人信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("所属村","villageName",ColumnType.String,false).setTableView().setVisible(false),
            new Column("所属村","villageId",ColumnType.IdReplaceXmgd,true)
                .setIdReplaceXmgdObject("villageName",{api:qlrInfo_api.area_findByUser,tag:"villageName",
                    options:["villageId"],keyValue:["id","name"]}),
            new Column("权利人名称","qlrmc",ColumnType.String,true).setTableView(),
            new Column("证件种类","zjzl",ColumnType.dictionary,true).setTableView()
                .setDictionary("ZJZL"),
            new Column("证件号码","zjh",ColumnType.String,true).setTableView(),
            new Column("所属行业","sshy",ColumnType.dictionary,true)
                .setDictionary("SSHY"),
            new Column("户籍","hjszss",ColumnType.String,true),
            new Column("发证机关","fzjg",ColumnType.String,true),
            new Column("性别","xb",ColumnType.dictionary,true).setTableView()
                .setDictionary("XB"),
            new Column("电话","dh",ColumnType.String,true),
            new Column("地址","dz",ColumnType.String,true),
            new Column("邮编","yb",ColumnType.String,true),
            new Column("工作单位","gzdw",ColumnType.String,true),
            new Column("电子邮件","dzyj",ColumnType.String,true),
            new Column("权利人类型","qlrlx",ColumnType.dictionary,true).setTableView()
                .setDictionary("QLRLX"),

            new Column("备注","bz",ColumnType.String,true),

        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(150)];
    }
}