<!--
*@description：权利人搜索选择专用
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="changeShow"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                {{ option.name }}
            </a-select-option>
        </a-select>
        <div style="text-align: center" v-else >{{presentValue}}</div>
    </div>
</template>

<script>
import {computed} from "vue";
import typeBase from "../../../../components/dataPresentType/typeBase";
import zjdApply_api from "../../zjdApply/config/zjdApply_api";

export default {
  name: "iQlrUse",
  mixins:[typeBase],
  computed:{
      presentValue(){
          return this.record[this.column.iQlrUse.replaceSegment]?this.record[this.column.iQlrUse.replaceSegment]:"无"
      },
      //能够选择的选项
      selectOptions(){
          return this.defaultOptions.concat(this.options)
      }
  },
  data(){
      return{
        //默认选项
        defaultOptions:[{
                id:this.record[this.column.dataIndex],
                name:this.record[this.column.iQlrUse.replaceSegment]}],
        //查询到的选项
        options:[],
        queryObject:computed(()=>this.column.iQlrUse.queryObject),
        pageParam:{
          page: 1,
          limit: 10,
        },
      }
  },
  methods:{
    handleSearch(key){
          if(key){
              let options = {};
              options[this.queryObject.tag] = key;
              let item_options = this.queryObject.options;
              if(item_options.length > 0){
                  for(let i=0;i<item_options.length;i++){
                    let op_name = item_options[i];
                    options[op_name] = this.record[op_name];
                  }
              }

              this.queryObject.api.requestPOSTUrlParam(this,options,res=>{
                  if(res.data.flag){
                    let data = res.data.data;
                    let option_data = [];
                     if(data.length > 0){
                        for(let i=0;i<data.length;i++){
                            let info = data[i];
                            let parm = {
                              id:info[this.queryObject.keyValue[0]],
                              name:info[this.queryObject.keyValue[1]]
                            };
                            option_data.push(parm);
                        }
                     }
                      this.options = option_data;
                  }
                  else{
                      this.options =[];
                  }
              },this)
          }
      },
    changeShow(key){
      let parms = {
        id:key
      };
      zjdApply_api.getForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        if(res.data.flag){
          let data = res.data.data;
          console.log(this.record[this.column.iQlrUse.replaceSegment])

        }
      })
    },
  }
}
</script>

<style scoped>

</style>