<!--
* @program: HXQTDZSRemake 
* @author: ly
* @component:ArBaseTable 
* @description: 一般用于分页查询，需要处理页面切换的响应事件，不需要字段过滤器
* @emit事件：pageChange
* childrenColumnName="'children10'" 禁止table出现展开状态
* @create: 2021-10-09 16:25
-->
<template>
    <a-table
            bordered
            :childrenColumnName="'children10'"
            :dataSource="source"
            :columns="cols" rowKey="id"
            :pagination="pagination"
            :loading="loading"
            :rowClassName = "rowClass"
            :custom-row="customRow"
    >
        <template #actions="{index,column,record}">
            <slot name="action" :record="record" :index="getRealIndex(index)" :column="column"/>
        </template>
        <template #Index="{column,record,index}">
            <type-index :currentIndex="currentIndex"  :index="index" :passedNum="passedNum" :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #Selector="{column,record}">
            <type-selector :viewModel="viewModel" :column="column" :record="record"/>
        </template>
        <template #String="{column,record}">
            <type-string :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Number="{column,record}">
            <type-number :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Enum="{column,record}">
            <type-enum :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Date="{column,record}">
            <type-date :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Month="{column,record}">
            <type-month :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #BooleanCheck="{column,record}">
            <type-boolean-check :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Boolean="{column,record}">
            <type-boolean :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace="{column,record}">
            <type-id-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #IdReplace2="{column,record}">
            <type-id-replace2 :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Category="{column,record}">
            <type-category :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PreViewFile="{column,record}">
            <type-pre-view-file :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #Avatar="{column,record}">
            <type-avatar :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #CategoryAssets="{column,record}">
            <type-category-assets :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #ObjectReplace="{column,record}">
            <type-object-replace :viewModel="viewModel" :column="column" :record="record" />
        </template>
        <template #PositionLevel="{column,record}">
            <type-position-level :viewModel="viewModel" :column="column" :record="record" />
        </template>


      <template #Dictionary="{column,record}">
        <type-dictionary :viewModel="viewModel" :column="column" :record="record" />
      </template>
      <template #Dictionary_c="{column,record}">
        <type-dictionary_c :viewModel="viewModel" :column="column" :record="record" />
      </template>

    </a-table>
</template>

<script>
    import dataTypes from "../dataPresentType/dataTypes";
    export default {
        name: "ArBaseTable",
        props:{
            /**
             argument:{
                    dataSource:null,//提供table的DataSource
                    tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                    loading:false,//table的loading状态
                }
             */
            argument:{required:true},
            viewModel:{required:true},
            pageSize:{required:true},
        },
        mixins:[dataTypes],
        emits:["showRecord"],
        watch:{
            argument(){
                this.init()
            }
        },
        provide(){
            return{
                provideTrigger:this.provideTrigger,
                presentType:"table"
            }
        },
        computed:{
            passedNum(){
                return this.pagination.defaultPageSize*(this.currentPage-1);
            },
            source(){
                return this.argument.dataSource;
            },
            cols(){
                return this.argument.tableColumns.filter(column=>{return column.tableView})
            },
            loading(){
                return this.argument.loading;
            },
        },
        data(){
            return {
                currentPage:1,
                pagination:{
                    size:"small",
                    hideOnSinglePage: false,
                    defaultPageSize: this.pageSize,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: () => {}
                },
                selectedRowKeys: [],
                selectedRows:[],
                searchText:null,
                searchedColumn:null,
                currentIndex:null,
                realIndex:null
            }
        },
        methods:{
            init(){
                this.pagination.defaultPageSize = this.pageSize?this.pageSize:15
            },
            provideTrigger(action){
                if(action){
                    //action(this.source,this.argument.tableColumns);
                }
            },
            getRealIndex(index){
                let realIndex = this.passedNum + index ;
                return realIndex > this.source.length ?index:realIndex;
            },
            setTotalSize(num){
                this.pagination.total = num;
            },
            setCurrentPage(num){
                this.pagination.current = num;
            },
            getPageSize(){
                return this.pagination.defaultPageSize;
            },
            setPageSize(size){
                this.pagination.defaultPageSize = size;
            },
            getSelectedRows(){
                return this.selectedRows;
            },
            getSelectedRowKeys(){
                return this.selectedRowKeys
            },
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight-row"
                }
                else{
                    return ""
                }
            },
            customRow(record,index){
                let _this = this;
                return{
                    onClick(){
                        _this.currentIndex = index;
                    },
                    onDblclick(){
                        _this.$emit("showRecord",index,record);
                    }
                }
            }
        },
        mounted() {
            this.init();
            this.pagination.onChange = (page)=>{
                this.currentPage = page;
                let data = { //结构与接口规定有关
                    page:page,
                    pageSize:this.pagination.defaultPageSize,
                    limit:this.pagination.defaultPageSize,
                };
                this.pagination.current = page;
                this.$emit("pageChange",data);
                this.currentIndex = null;
            }
        },
    }
</script>

<style scoped>

</style>