<!--
*@description：字典子项组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
  <div v-if="viewModel" style="text-align: center">
    <a-tag  :color="color">{{record[column.dataIndex]}}</a-tag>
    <!--        <div v-else >{{getTags(record[column.dataIndex])}} </div>-->
  </div>
  <div v-else>
    <a-select v-if="editable"  v-model:value="record[column.dataIndex]" style="width: 100%" @change="columnTrigger">
      <a-select-option v-for="option in options" :value="option.key" :key="option.key">{{getTags(option.key)}}</a-select-option>
    </a-select>
    <div v-else style="text-align: center">
      {{getTags(record[column.dataIndex])}}
    </div>
  </div>
</template>

<script>
import typeBase from "../../../components/dataPresentType/typeBase";
import dictColor from "../js/color";
import dic_api from "../../sys/dictionary/config/dic_api";

export default {
  name: "typeDictionary_c",
  mixins:[typeBase],
  computed:{
    selectOptions(){
      return this.defaultOptions.concat(this.options)
    }
  },
  data(){
    return{
      color:null,
      options:[],
    }
  },
  created() {
    let pidType = this.record[this.column.dictPid];
    let dicInfo = this.$cookies.get("dic_department_type");
    let children = [];
    if(dicInfo != null && dicInfo.children.length > 0){
      children = dicInfo.children;
    }else{
      dic_api.queryByNameCode.requestPOSTUrlParam(this,{nameOrCode:this.$eventDict.department_type},res=>{
        if(res.data.flag){
          let data = res.data.data;
          if(data != null){
            this.$cookies.set("dic_department_type",data);
            children = data.children;

          }
        }
        else{
          this.$message.error(res.data.msg)
        }
      })
    }

    if(children.length > 0){
      for(let i=0;i<children.length;i++){
        if(children[i].dictCode == '2.0'){
          let c = children[i].children;
          if(c.length > 0){
            for(let j=0;j<c.length;j++){
              this.options.push({
                key:c[j].dictCode,
                value:c[j].dictName
              })
            }
          }
        }

      }
    }


  },
  methods:{
    getTags(option){
      let value = "";
      let index = 0;
      if(this.options){
        let flag = true;
        for(let i=0;i<this.options.length;i++){
          let op = this.options[i];
          if(op.key == option){
            value = op.value;
            flag = false;
            index = i;
          }
        }

        if(value == '' && flag){
          value = "暂无数据";
        }
      }
      else{
        value =  option?option:"暂无数据";
      }
      if(this.column.colors){
        this.color = this.column.colors[index]?this.column.colors[index]:dictColor[index];
      }
      return value;
    },
  }
}
</script>

<style scoped>

</style>