<!--
* @description：合规分析弹框
* @author:rzl
* @date:2022/1/12 14:59
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="65%"
           :destroyOnClose="true"
  >

    <div style="height: 100%; width: 100%; ">
      <a-tabs v-model:activeKey="activeKey" tab-position="left">

        <a-tab-pane key="1" tab="基本农田分析" forceRender="true">
          <div class="ant-row" >
            <div class="ant-col-10">
              <a-image :src="formData.basicFarmlandPic"  style="width: 90%;height:300px;"/>
            </div>
            <div class="ant-col-14">
               <div v-if="flag_jbnt" style="color: red;margin-top: 20%;">
                    宅基地总面积：{{formData.areaTotal}}（平方米），未占用基本农田。
               </div>
                <div v-else>
                  <div ref="chartsJbnt" style="height:300px; "></div>
                </div>
            </div>
          </div>
          <div class="ant-row" style="margin-top: 10px;">
            <ArBaseTable ref="pstable"  :argument="argument_jbnt" :view-model="true" style="width: 100%;" :scroll="{ x: 600,y:450}">

            </ArBaseTable>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2" tab="土规分析" forceRender="true">
          <div class="ant-row" >
            <div class="ant-col-10">
              <a-image :src="formData.landPlanPic"  style="width: 90%;height:300px;"/>
            </div>
            <div class="ant-col-14">
              <div v-if="flag_tg" style="color: red;margin-top: 20%;">
                宅基地总面积：{{formData.areaTotal}}（平方米），未占土规。
              </div>
              <div v-else>
                <div ref="chartsTg" style="height:300px; "></div>
              </div>
            </div>
          </div>
          <div class="ant-row" style="margin-top: 10px;">
            <ArBaseTable ref="pstable"  :argument="argument_tg" :view-model="true" style="width: 100%;"  :scroll="{ x: 600,y:450}">

            </ArBaseTable>
          </div>
        </a-tab-pane>

        <a-tab-pane key="3" tab="林规分析" forceRender="true">
          <div class="ant-row" >
            <div class="ant-col-10">
              <a-image :src="formData.forestryPlanPic"  style="width: 90%;height:300px;"/>
            </div>
            <div class="ant-col-14">
              <div v-if="flag_lg" style="color: red;margin-top: 20%;">
                宅基地总面积：{{formData.areaTotal}}（平方米），未占用林规。
              </div>
              <div v-else>
                <div ref="chartsLg" style="height:300px; "></div>
              </div>
            </div>
          </div>
          <div class="ant-row" style="margin-top: 10px;">
            <ArBaseTable ref="pstable"  :argument="argument_lg" :view-model="true" style="width: 100%;" :scroll="{ x: 600,y:450}">

            </ArBaseTable>
          </div>
        </a-tab-pane>

      </a-tabs>
    </div>

    <template #footer>
      <a-button type="primary" v-if="flag_show_down" @click="download_file()" style="background-color: #59b52c;">分析结果下载</a-button>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

</template>

<script>
import Icons from "../../../../../components/Icons";
import * as echarts from "echarts";
import ArForm from "../../../../../components/form/ArForm";
import ArBaseTable from "../../../../../components/table/ArBaseTable";
import hgfx1_item from "../../config/hgfx/hgfx1_item";
import hgfx2_item from "../../config/hgfx/hgfx2_item";
import hgfx_api from "../../config/hgfx/hgfx_api";


export default {
  name: "documentationTk",
  mixins:[Icons],
  components:{
    ArForm,ArBaseTable
  },
  data(){
    return{
      visible:false,
      modalTitle:null,
      labelCol:{ span: 6 },
      wrapperCol:{ span: 16 },
      activeKey:"1",

      formData:{},// 分析结果

      /** 基本农田分析 **/
      argument_jbnt:{
        dataSource:null,//提供table的DataSource
        tableColumns:new hgfx1_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      myChart_jbnt:null,
      flag_jbnt:true,

      /** 土规分析 **/
      argument_tg:{
        dataSource:null,//提供table的DataSource
        tableColumns:new hgfx1_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      myChart_tg:null,
      flag_tg:true,

      /** 林规分析 **/
      argument_lg:{
        dataSource:null,//提供table的DataSource
        tableColumns:new hgfx2_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
      },
      myChart_lg:null,
      flag_lg:true,

      flag_show_down:true,

      flag_fdyt:'1',// 1:房地一体；2：宅基地申请;3:wkt
      fdyt_id:null,// 房地一体id
      apply_id:null,// 宅基地申请id
      wkt:null,
      wkt_type:null,
    }
  },
  methods:{
    closeTk(){
      this.visible = false;
    },
    /********** 传入房地一体 start *************/
    /**
     * 打开弹框
     * @param fdyt_id 房地一体id
     */
    openHgfxTk(fdyt_id){
      if(this.$checkUtil.empty_str(fdyt_id)){
        this.$message.info("未上传宗地红线！");
        return true;
      }
      this.fdyt_id = fdyt_id;
      this.flag_fdyt = '1';
      this.flag_show_down = true;
      this.queryHgfx_fdyt();

    },
    queryHgfx_fdyt(){
      this.formData = {};
      this.argument_jbnt.dataSource = null;
      this.argument_tg.dataSource = null;
      this.argument_lg.dataSource = null;

      let parms = {
        fLandPlotsId:this.fdyt_id,
      };
      hgfx_api.planAnalyseByFLandPlotsId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.formData = res.data.data;
          this.argument_jbnt.dataSource = res.data.data.basicFarmlandCategoryList;
          this.argument_tg.dataSource = res.data.data.landPlanCategoryList;
          this.argument_lg.dataSource = res.data.data.forestryPlanCategoryList;
          this.echarts_jbnt(res.data.data.basicFarmlandCategoryList);
          this.echarts_Tg(res.data.data.landPlanCategoryList);
          this.echarts_Lg(res.data.data.forestryPlanCategoryList);

          this.visible = true;
          this.modalTitle = "合规分析信息";
        }
        else{
          this.closeTk();
          this.$message.error(res.data.msg);
        }
      })
    },
    /********* 传入房地一体 end **************/

    /********** 传入宅基地申请 start *********/
    /**
     * 打开弹框
     * @param apply_id  宅基地申请id
     */
    openHgfxTk_apply(apply_id){
      if(this.$checkUtil.empty_str(apply_id)){
        this.$message.info("数据为空！");
        return true;
      }
      this.apply_id = apply_id;
      this.flag_fdyt = '2';
      this.flag_show_down = true;
      this.queryHgfx_apply();
    },
    queryHgfx_apply(){
      this.formData = {};
      this.argument_jbnt.dataSource = null;
      this.argument_tg.dataSource = null;
      this.argument_lg.dataSource = null;

      let parms = {
        applyId:this.apply_id,
      };
      hgfx_api.planAnalyseByApplyId.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.formData = res.data.data;
          this.argument_jbnt.dataSource = res.data.data.basicFarmlandCategoryList;
          this.argument_tg.dataSource = res.data.data.landPlanCategoryList;
          this.argument_lg.dataSource = res.data.data.forestryPlanCategoryList;
          this.echarts_jbnt(res.data.data.basicFarmlandCategoryList);
          this.echarts_Tg(res.data.data.landPlanCategoryList);
          this.echarts_Lg(res.data.data.forestryPlanCategoryList);
          this.visible = true;
          this.modalTitle = "合规分析信息";
        }
        else{
          this.closeTk();
          this.$message.error(res.data.msg);
        }
      })
    },
    /********** 传入宅基地申请 start ***********/

    /********** 传入WKT start *********/
    /**
     * 打开弹框
     * @param wkt
     * @param type :fdyt_land_plots--房地一体宗地，
     * zjd_apply--宅基地申请，zjd_land_plots--宅基地宗地
     */
    openHgfxTk_wkt(wkt,type){
      this.visible = true;
      this.modalTitle = "合规分析信息";
      this.flag_fdyt = '3';
      this.wkt = wkt;
      this.wkt_type = type;
      this.flag_show_down = false;
      this.queryHgfx_wkt();
    },
    queryHgfx_wkt(){
      this.formData = {};
      this.argument_jbnt.dataSource = null;
      this.argument_tg.dataSource = null;
      this.argument_lg.dataSource = null;

      let parms = {
        wkt:this.wkt,
        type:this.wkt_type,
      };
      hgfx_api.planAnalyseHomeByWkt.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.formData = res.data.data;
          this.argument_jbnt.dataSource = res.data.data.basicFarmlandCategoryList;
          this.argument_tg.dataSource = res.data.data.landPlanCategoryList;
          this.argument_lg.dataSource = res.data.data.forestryPlanCategoryList;
          this.echarts_jbnt(res.data.data.basicFarmlandCategoryList);
          this.echarts_Tg(res.data.data.landPlanCategoryList);
          this.echarts_Lg(res.data.data.forestryPlanCategoryList);
        }
        else{
          this.closeTk();
          this.$message.error(res.data.msg);
        }
      })
    },
    /********** 传入WKT end ***********/


    /********** 传入单宗地WKT start *********/
    /**
     * 打开弹框：单宗地WKT分析
     * @param wkt
     */
    openHgfxTk_wkt_dzd(wkt){
      this.visible = true;
      this.modalTitle = "合规分析信息";
      this.flag_fdyt = '3';
      this.wkt = wkt;
      this.flag_show_down = false;
      this.queryHgfx_wkt_dzd();
    },
    queryHgfx_wkt_dzd(){
      this.formData = {};
      this.argument_jbnt.dataSource = null;
      this.argument_tg.dataSource = null;
      this.argument_lg.dataSource = null;

      let parms = {
        wkt:this.wkt,
      };
      hgfx_api.planAnalyseByWkt.requestPOSTUrlParam(this,parms,res=>{
        if(res.data.flag){
          this.formData = res.data.data;
          this.argument_jbnt.dataSource = res.data.data.basicFarmlandCategoryList;
          this.argument_tg.dataSource = res.data.data.landPlanCategoryList;
          this.argument_lg.dataSource = res.data.data.forestryPlanCategoryList;
          this.echarts_jbnt(res.data.data.basicFarmlandCategoryList);
          this.echarts_Tg(res.data.data.landPlanCategoryList);
          this.echarts_Lg(res.data.data.forestryPlanCategoryList);
        }
        else{
          this.closeTk();
          this.$message.error(res.data.msg);
        }
      })
    },
    /********** 传入单宗地WKT end ***********/

    echarts_jbnt(data){
      if(this.myChart_jbnt != null){
        this.myChart_jbnt = null;
      }
      if(data.length > 0){
        this.flag_jbnt = false;
        setTimeout(()=>{
          this.myChart_jbnt = echarts.init(this.$refs.chartsJbnt);
          let legend_data = ["宅基地"];
          let ser_data = [];
          for(let i=0;i<data.length;i++){
            let info = data[i];
            legend_data.push(info.name);
            ser_data.push(
                {
                  value: info.area,
                  name: info.name
                }
            )
          }
          let option = {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
              data: legend_data,
            },
            series: [
              {
                name: '宅基地信息：',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, '30%'],
                label: {
                  position: 'center',
                  fontSize: 18
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: this.formData.areaTotal, name: '宅基地面积'}
                ]
              },
              {
                name: '基本农田分析',
                type: 'pie',
                radius: ['45%', '60%'],
                data: ser_data
              }
            ]
          };
          this.myChart_jbnt.setOption(option);
        },500);
      }else{
        this.flag_jbnt = true;
      }

    },
    echarts_Tg(data){
      if(this.myChart_tg != null){
        this.myChart_tg = null;
      }
      if(data.length > 0){
        this.flag_tg = false;
        setTimeout(()=>{
          this.myChart_tg = echarts.init(this.$refs.chartsTg);

          let legend_data = ["宅基地"];
          let ser_data = [];
          for(let i=0;i<data.length;i++){
            let info = data[i];
            legend_data.push(info.name);
            ser_data.push(
                {
                  value: info.area,
                  name: info.name
                }
            )
          }

          let option = {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
              data: legend_data,
            },
            series: [
              {
                name: '宅基地信息：',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, '30%'],
                label: {
                  position: 'center',
                  fontSize: 18
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: this.formData.areaTotal, name: '宅基地面积'}
                ]
              },
              {
                name: '基本农田分析',
                type: 'pie',
                radius: ['45%', '60%'],
                data: ser_data
              }
            ]
          };
          this.myChart_tg.setOption(option);
        },500);

      }else{
        this.flag_tg = true;
      }
    },
    echarts_Lg(data){
      if(this.myChart_lg != null){
        this.myChart_lg = null;
      }
      if(data.length > 0){
        this.flag_lg = false;
        setTimeout(()=>{
          this.myChart_lg = echarts.init(this.$refs.chartsLg);

          let legend_data = ["宅基地"];
          let ser_data = [];
          for(let i=0;i<data.length;i++){
            let info = data[i];
            legend_data.push(info.name);
            ser_data.push(
                {
                  value: info.area,
                  name: info.name
                }
            )
          }
          let option = {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
              data: legend_data,
            },
            series: [
              {
                name: '宅基地信息：',
                type: 'pie',
                selectedMode: 'single',
                radius: [0, '30%'],
                label: {
                  position: 'center',
                  fontSize: 18
                },
                labelLine: {
                  show: false
                },
                data: [
                  { value: this.formData.areaTotal, name: '宅基地面积'}
                ]
              },
              {
                name: '基本农田分析',
                type: 'pie',
                radius: ['45%', '60%'],
                data: ser_data
              }
            ]
          };
          this.myChart_lg.setOption(option);
        },500);
      }else{
        this.flag_lg = true;
      }
    },
    download_file(){
      if(this.flag_fdyt == '1'){
        let data = {
          fLandPlotsId:this.fdyt_id,
        };
        hgfx_api.downloadPlanAnalyseByFLandPlotsId.fileDownload_id(this,data,'合规分析报告');
      }else if(this.flag_fdyt == '2'){
        let data = {
          applyId:this.apply_id
        };
        hgfx_api.downloadPlanAnalyseByApplyId.fileDownload_id(this,data,'合规分析报告');
      }else if(this.flag_fdyt == '3'){
        let data = {
          wkt:this.wkt,
        };
        hgfx_api.downloadPlanAnalyseByApplyId.fileDownload_id(this,data,'合规分析报告');
      }

    },
  },
}
</script>
