<!--
* @program: tyh-oa 
* @author: ly
* @description: menuGroup 
* @create: 2021-03-22 14:21
-->
<template>
    <a-menu-item :key="menus.id">
        {{menus.title}}
    </a-menu-item>
</template>

<script>
    export default {
        name: "menuGroup",
        props:["menus"],
    }
</script>

<style scoped>

</style>