/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../../assets/utils/APIObject";

const api={
    /** 生成文档 **/
    auditTableGenerate:new APIObject("/api/zDocGenerate/auditTableGenerate.do"),// 宅基地文档生成模块-宅基地审核表生成
    homesteadApprovalletterGenerate:new APIObject("/api/zDocGenerate/homesteadApprovalletterGenerate.do"),// 宅基地文档生成模块-宅基地批准书生成
    planLicenceGenerate:new APIObject("/api/zDocGenerate/planLicenceGenerate.do"),// 宅基地文档生成模块-宅基地规划许可证生成
    applicationGenerate:new APIObject("/api/zDocGenerate/applicationGenerate.do"),// 宅基地文档生成模块-宅基地申请表生成
    getDocGenerate:new APIObject("/api/zDocGenerate/getDocGenerate.do"),// 宅基地文档生成模块-查询宅基地文档生成情况
    acceptanceCommentsGenerate:new APIObject("/api/zDocGenerate/acceptanceCommentsGenerate.do"),// 宅基地文档生成模块-宅基地验收意见表生成

    /** 规划许可 **/
    ghxk_save:new APIObject("/api/zPlanLicence/save.do"),// 宅基地规划许可证信息管理模块-添加宅基地规划许可证信息
    ghxk_getByApplyId:new APIObject("/api/zPlanLicence/getByApplyId.do"),// 宅基地规划许可证信息管理模块-查询宅基地申请的规划许可证信息
    ghxk_upd:new APIObject("/api/zPlanLicence/upd.do"),// 宅基地规划许可证信息管理模块-修改宅基地规划许可证信息


    /** 批准书 **/
    pzs_upd:new APIObject("/api/zHomesteadApprovalleter/upd.do"),// 宅基地批准书信息管理模块-修改宅基地批准书信息
    pzs_getByApplyId:new APIObject("/api/zHomesteadApprovalleter/getByApplyId.do"),// 宅基地批准书信息管理模块-查询宅基地申请的批准书信息
    pzs_save:new APIObject("/api/zHomesteadApprovalleter/save.do"),// 宅基地批准书信息管理模块-添加宅基地批准书信息

    /** 验收意见表 **/
    ysyjb_getByApplyId:new APIObject("/api/zAcceptanceComments/getByApplyId.do"),//宅基地验收意见管理模块-查询宅基地申请的验收意见
    ysyjb_delete:new APIObject("/api/zAcceptanceComments/delete.do"), //宅基地验收意见管理模块-单个删除
    ysyjb_save:new APIObject("/api/zAcceptanceComments/save.do"),// 宅基地验收意见管理模块-添加宅基地验收意见
    ysyjb_upd:new APIObject("/api/zAcceptanceComments/upd.do"),//宅基地验收意见管理模块-修改宅基地验收意见

};

export default api;