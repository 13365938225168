/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    updWeb:new APIObject("/api/zApply/updWeb.do"),//web端修改
    saveWeb:new APIObject("/api/zApply/saveWeb.do"),//web端新增
    queryPageForManage:new APIObject("/api/zApply/queryPageForManage.do"),// 宅基地申请管理-管理部门分页查询
    get:new APIObject("/api/zApply/get.do"),// 宅基地申请管理-单个查询
    accept:new APIObject("/api/zApply/accept.do"),//宅基地申请管理-受理
    approve:new APIObject("/api/zApply/approve.do"),//宅基地申请管理-审核通过
    reject:new APIObject("/api/zApply/reject.do"),// 宅基地申请管理-审核不通过
    finish:new APIObject("/api/zApply/finish.do"),// 宅基地申请管理-完结申请


    queryAll_qlr:new APIObject("/api/obligee/queryAll.do"),// 权利人管理-查询所有权利人
    getForManage:new APIObject("/api/obligee/getForManage.do"),// 权利人管理-管理部门单个查询


    queryAuditLine:new APIObject("/api/zAudit/queryAuditLine.do"),//审核记录管理-查询宅基地申请审核时间线
    getLine:new APIObject("/api/zAudit/get.do"),//审核记录管理-单个查询

    queryAllAuditChildDepartment:new APIObject("/api/zAuditChild/queryAllAuditChildDepartment.do"),// 子部门审核记录管理-查询宅基地申请的子部门审核情况
    get_xjsh:new APIObject("/api/zAuditChild/get.do"),// 子部门审核记录管理-单个查询

};

export default api;