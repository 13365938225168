/**
 * 房地一体用户信息
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../../assets/tables/parts/table";
import Column from "../../../../../assets/tables/parts/column";
import ColumnType from "../../../../../assets/tables/parts/column_type"

export default class jtcy_item extends Table{
    constructor() {
        super();
        this.name = "jtcy_item";
        this.CNName = "家庭成员信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("所属村id","villageId",ColumnType.String,false).setVisible(false),
            new Column("所属村名","villageName",ColumnType.String,false).setVisible(false),
            new Column("姓名","name",ColumnType.String,true).setTableView(100),
            new Column("身份证号","idCard",ColumnType.String,true).setTableView(),
            new Column("性别","sex",ColumnType.dictionary,true).setTableView(80)
                .setDictionary("XB"),
            new Column("年龄","age",ColumnType.Number,true),
            new Column("联系电话","phone",ColumnType.Number,true),
            new Column("户口所在地","householdRegister",ColumnType.String,true),
           new Column("与户主关系","familyRelation",ColumnType.dictionary,true)
                .setDictionary("household_relation"),

            new Column("权利比例","qlbl",ColumnType.String,true),
            new Column("共有方式","gyfs",ColumnType.dictionary,true)
                .setDictionary("GYFS"),
            new Column("共有情况","gyqk",ColumnType.String,true),

        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(100)];
    }
}