<!--
* @description：房地一体查询入库数据
* @author:rzl
* @date:2022/1/18 16:45
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="60%"
           :destroyOnClose="true"
  >

    <!-- table start -->
    <a-input class="search-item" v-model:value="queryText" style="width:300px" placeholder="宗地代码/权利人名称/证件号" />
    <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
      <template #icon><Search /></template>
    </a-button>
    <ar-base-table ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange" :scroll="{ x: 600,y:650}">
      <template v-slot:action="{index,record}">
        <div style="justify-content: center;;display: flex;">
          <a-button type="link" @click="showData(index,record)">
            <template #icon><Show title="查看"/></template>
          </a-button>
          <a-button type="link" @click="showQlr(index,record)">
            <template #icon><Team title="权利人信息"/></template>
          </a-button>
          <a-button type="link" @click="showFile(index,record)">
            <template #icon><FileInfo title="文件信息"/></template>
          </a-button>
          <a-button type="link" @click="showZddt(index,record)">
            <template #icon><ParcelMap title="宗地地图"/></template>
          </a-button>
          <a-button type="link" @click="hgfx(index,record)">
            <template #icon><PieChart title="合规分析"/></template>
          </a-button>
          <a-button type="link" @click="useData(index,record)">
            <template #icon><CheckCircle title="使用数据申请"/></template>
          </a-button>
        </div>
      </template>
    </ar-base-table>
    <!-- table end -->

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>

  <!-- 查看 -->
  <zdrk-show ref="show_fdyt"/>

  <!-- 文件信息 -->
  <file-info-tk  ref="fileInfoBut"/>

  <!--宗地图信息-->
  <map_tk ref="mapTkBut"/>

  <!-- 权利人信息 -->
  <qlr-table-tk  ref="qlrInfoBut"/>

  <!-- 合规分析 -->
  <hgfx-tk ref="hgfxTk" />
</template>

<script>
import Icons from "../../../../../components/Icons";
import ArBaseTable from "../../../../../components/table/ArBaseTable";

import fdytzjd_zd_item from "../../../zjdApply/config/fdytzjd_zd_item";
import fdytzjd_api from "../../../../fdyt/fdytzjd/config/fdytzjd_api";
import fileInfoTk from "../file/fileInfoTk";
import map_tk from "../../../../homeMap/common/map_tk";
import ZdrkShow from "./zdrkShow";
import qlrTableTk from "../../../../fdyt/common/point/qlr/qlrTableTk";
import hgfxTk from "../hgfx/hgfxTk";
import zjdApply_api from "../../../zjdApply/config/zjdApply_api";

export default {
  name: "fdytRkTable",
  mixins:[Icons],
  components:{
    ArBaseTable,fileInfoTk,map_tk,ZdrkShow,qlrTableTk,hgfxTk
  },
  data(){
    return{
      visible:null,
      modalTitle:null,

      argument:{
        dataSource:null,//提供table的DataSource
        tableColumns:new fdytzjd_zd_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
        formColumns:new fdytzjd_zd_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      pageParam:{
        page: 1,
        limit: 12 ,
      },
      queryText:null,


    }
  },
  methods:{
    /**
     * 打开房地一体数据查询
     */
    openFdytRkTable(){
      this.visible = true;
      this.modalTitle = "房地一体数据";
    },
    doSearch(){
      let parms = {
        queryText:this.queryText
      };
      fdytzjd_api.queryForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    pageChange(arg){
      this.pageParam.page = arg.page;
      this.pageParam.limit = arg.limit;
      let parms = {
        queryText:this.queryText
      };
      fdytzjd_api.queryForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
        if(res.data.flag){
          this.argument.dataSource = res.data.data.resultList;
          this.$refs.pstable.setTotalSize(res.data.data.total);
        }
      });
    },
    showData(index,arg){
      this.$refs.show_fdyt.openZdrk(arg.id);
    },
    showQlr(index,arg){
      this.$refs.qlrInfoBut.openQlr(arg.id);
    },
    showFile(index,arg){
      this.$refs.fileInfoBut.openFile(arg.id);
    },
    showZddt(index,arg){
      this.$refs.mapTkBut.openMap(arg.geom);
    },
    hgfx(index,arg){
      this.$refs.hgfxTk.openHgfxTk(arg.id);
    },
    useData(index,arg){
      this.visible = false;
      this.$emit("event",this.$GetEmitData(this.$eventTags.add,arg));
    },

  },

}
</script>

<style scoped>

</style>