<!--
* @program: tyh-oa 
* @author: ly
* @description: typeNumber 
* @create: 2021-03-23 10:01
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <div v-if="editable" style="display: flex">
            <a-input-number
                    :min="min"
                    :max="max"
                    :formatter="formatter"
                    :parser = "parser"
                    style="width:100%" v-model:value="record[column.dataIndex]"
                    @change="columnTrigger"
            />
            <a-tooltip :key="'#fff'" title="数值计算器">
                <a-button type="link" @click="calcShow">
                    <template #icon><MobileOutlined /></template>
                </a-button>
            </a-tooltip>
        </div>
        <div style="text-align: center" v-else >{{presentValue}}</div>
    </div>
    <a-modal v-model:visible="cVisible"
             destroyOnClose
             :footer="null"
             title="数值计算器">
        <div style="display: flex">
            <a-textarea
                    v-model:value="execString"
                    auto-size
                    @change ="calc"
                    placeholder="例如：100*5.8"
            />
            <div style="margin:5px;width: 200px">={{calcValue}}</div>
        </div>
        <a-button size="small" type="dash" style="margin: 20px" @click="inputValue">
            填入计算结果
        </a-button>
    </a-modal>
</template>

<script>
    import typeBase from "./typeBase";
    import {MobileOutlined} from "@ant-design/icons-vue"
    export default {
        name: "typeNumber",
        mixins:[typeBase],
        components:{MobileOutlined},
        computed:{
            presentValue(){
                return this.formatter(this.record[this.column.dataIndex]?this.record[this.column.dataIndex]:0);
            }
        },
        data(){
            return{
                cVisible:false,
                execString:null,
                calcValue:null,
                min:0,
                max:99999999999,
                formatter:value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
                parser:value => value.replace(/\$\s?|( *)/g, '')
            }
        },
        methods:{
            calc(){
                try{
                    this.calcValue = eval(this.execString)
                }
                catch (e) {
                    this.calcValue = "错误的表达式"
                }
            },
            inputValue(){
                this.record[this.column.dataIndex] = this.calcValue;
                this.cVisible = false
            },
            calcShow(){
                this.cVisible = true;
                this.execString = null;
                this.calcValue = null
            }
        },
        created() {
            if(this.column.range){
                this.min = this.column.range[0];
                this.max = this.column.range[1];
            }
            if(this.column.formatter){
                this.formatter = this.column.formatter;
            }
            if(this.column.parser){
                this.parser = this.column.parser;
            }
        }
    }
</script>

<style scoped>

</style>