<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
        <div style="background-color: #1890ff;padding: 6px;color: #fafafa;font-size: 16px;margin-right: 20px;">{{pidName}}</div>
        <a-input class="search-item ant-col-4" v-model:value="dictName" placeholder="请输入字典名称" />
        <a-input class="search-item ant-col-4" v-model:value="dictCode" placeholder="请输入字典编码" />
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
    </div>
</template>

<script>
    import Icons from "../../../../components/Icons";
    export default {
      name: "dict_ToolBar",
      mixins:[Icons],
      data(){
        return{
          dictName:null,
          dictCode:null,
          pidName:'字典类别',
        }
      },
      methods:{
        doSearch(){
            let data = this.$GetEmitData(this.$eventTags.search,{dictName:this.dictName,dictCode:this.dictCode});
            this.$emit("event",data);
        },
        refresh(){
            this.dictName = null;
            this.dictCode = null;
            this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            dictName:this.dictName,
            dictCode:this.dictCode,
          }
          return parms;
        },
        set_pidName(pidName){
          this.pidName = pidName;
        },
      }
    }
</script>

<style scoped>

</style>