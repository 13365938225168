/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryPageForManage:new APIObject("/api/zApply/queryPageForManage.do"),// 宅基地申请管理-管理部门分页查询
    get:new APIObject("/api/fLandPlots/get.do"),// 宅基地申请管理-单个查询

    queryAllForManage:new APIObject("/api/fLandPlots/queryAllForManage.do"),//管理部门查询宗地信息-查询权利人下属房地一体宗地

};

export default api;