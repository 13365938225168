<!--
* @description：授权角色弹框
* @author:rzl
* @date:2022/3/15 10:02
-->

<template>
  <!--角色授权 start-->
  <a-modal v-model:visible="visible_role"
           :title="modalTitle_role" width="35%" style="overflow-y: auto"
           :destroyOnClose="true">

    <div style="height: 500px;overflow-y: auto">
      <a-tree
          checkable
          :tree-data="treeData"
          :blockNode="true"
          :defaultExpandAll="true"
          v-model:checkedKeys="checkedKeys"
      />
    </div>


    <template #footer>
      <a-button key="back" @click="visible_role =false">取消</a-button>
      <a-button key="submit" type="primary" @click="submit" >提交</a-button>
    </template>
  </a-modal>
  <!--角色授权 end-->
</template>

<script>
import user_api from "../../../user/config/user_api";

export default {
  name: "authorizeRoleTk",
  data(){
    return{
      visible_role:false,
      modalTitle_role:null,

      treeData:[],
      checkedKeys:[],
      check_tree:[],

      departInfo:null,// 部门信息
      user:null,// 人员信息
    }
  },
  methods:{
    openRoleTk(departInfo,user){
      this.departInfo = departInfo;
      this.user = user;

      /** 获取部门下拥有的角色 **/
      user_api.queryAll_role.requestPOST(this,null,res=>{
        if(res.data.flag){
          let data = res.data.data;
          /**结果封装成树**/
          this.treeData = this.$common.convertToTreedata(data);
          this.userRole();
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    userRole(){
      this.checkedKeys = [];

      user_api.queryAll_role_user.requestPOSTUrlParam(this,{userId:this.user.id},res=>{
        if(res.data.flag){
          let data = res.data.data;
          if(data != null && data.length > 0){
            for(let i=0;i<data.length;i++){
              this.checkedKeys.push(data[i].roleId)
            }
          }
        }
        this.visible_role = true;
        this.modalTitle_role = this.user.name + ":授权角色"

      });
    },
    submit(){
      if(this.checkedKeys == null || this.checkedKeys.length == 0){
        this.$message.error("请选择要授权的角色");
        return;
      }
      let userRoleRelationVOList = [];
      let checked_role = this.checkedKeys;
      for(let i=0;i<checked_role.length;i++){
        let data = {
          roleId:checked_role[i],
          userId:this.user.id,
        }

        userRoleRelationVOList.push(data)
      }

      let parms = {
        userRoleRelationVOList:userRoleRelationVOList
      }
      user_api.batchBinding_role.requestPOST(this,parms,res=>{
        if(res.data.flag){
          this.visible_role = false;
          this.$message.success(res.data.msg);
        }
        else{
          this.$message.error(res.data.msg);
        }
      });
    },
  }
}
</script>

<style scoped>

</style>