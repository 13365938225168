<!--
* @description：信息查看页面
* @author:rzl
* @date:2022/1/14 9:29
-->

<template>
  <a-modal v-model:visible="visible"
           :maskClosable="false"
           :title="modalTitle"  width="35%"
           :destroyOnClose="true"
  >

    <ar-form ref="bill_form" :argument="argument" :viewModel="true" style="overflow-y: auto;height: 500px" />

    <template #footer>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import ArForm from "../../../../components/form/ArForm";
import fdytzjd_api from "../../../fdyt/fdytzjd/config/fdytzjd_api";
import fdytzjd_item from "../../../fdyt/fdytzjd/config/fdytzjd_item";

export default {
  name: "infoShow",
  components:{
    ArForm
  },
  data(){
    return{
      visible:null,
      modalTitle:null,
      argument:null,
    }
  },
  methods:{
    openInfoShow(argument,title){
      this.argument = argument;
      this.visible = true;
      this.modalTitle = title;
    }
},

}
</script>

<style scoped>

</style>