<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIdReplace2
* @description: 
* @create: 2021-04-16 10:27
-->
<template>
    <div v-if="viewModel">
        <div style="text-align: center" >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
                @change="columnTrigger"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                <a-tooltip :title="option.name">
                    <a-button style="padding: 0" type="text">
                        {{ option.name }}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
        <div style="text-align: center" v-else >{{presentValue}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {computed} from "vue";
    export default {
        name: "typeIdReplace2",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.idReplaceObject.replaceSegment]?this.record[this.column.idReplaceObject.replaceSegment]:"无"
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            }
        },
        data(){
            return{
                //默认选项
                defaultOptions:[{
                        id:this.record[this.column.dataIndex],
                        name:this.record[this.column.idReplaceObject.replaceSegment]}],
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.idReplaceObject.queryObject)
            }
        },
        methods:{
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    this.queryObject.options[this.queryObject.tag] = key;
                    this.queryObject.api.requestPOST(this,this.queryObject.options,res=>{
                        if(res.data.flag){
                            this.options = res.data.data;
                        }
                        else{
                            this.options =[];
                        }
                    })
                }
            },
            handleSelect(value){
                this.record[this.column.idReplaceObject.replaceSegment] = this.selectOptions.filter(option=>{return option.id === value})[0].name;
            }
        }
    }
</script>

<style scoped>
.dropdown{
    color:red;
    font-size: 10px;
}
</style>