<!--
*@description：房地一体权利人主页面
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>

  <div class="main-css">
    <div style="margin: 5px 2px 5px 5px;height: 100%;overflow: auto;">
      <!-- 搜索栏 start -->
      <fdytqlr-tool-bar  ref="toolBar" @event="eventHandle" />
      <!-- 搜索栏 end -->

      <!-- table start -->
      <ArBaseTable ref="pstable" :page-size="pageParam.limit" :argument="argument" :view-model="true" @pageChange="pageChange">
        <template v-slot:action="{index,record}">
          <div style="justify-content: center;;display: flex;">
            <a-button type="link" @click="showData(index,record)">
              <template #icon><Show title="查看"/></template>
            </a-button>

            <a-button type="link" @click="showZjdInfo(index,record)">
              <template #icon><HomeTwoTone title="房地一体信息"/></template>
            </a-button>
          </div>
        </template>
      </ArBaseTable>
      <!-- table end -->
    </div>

    <!-- 编辑页面 -->
    <drawer-component ref="local_tabs" @event="eventHandle"/>
  </div>

</template>

<script>
    import Icons from "../../../components/Icons";
    import ArBaseTable from "../../../components/table/ArBaseTable";
    import drawerComponent from "./components/drawerComponent";
    import fdytqlrToolBar from "./components/fdytqlrToolBar";

    import qlrInfo_api from "./config/qlrInfo_api";
    import qlrInfo_item from "./config/qlrInfo_item";

    export default {
      name: "fdytqlr_main",
      mixins:[Icons],
      components:{
        fdytqlrToolBar,ArBaseTable,drawerComponent
      },
      data(){
          return{
            argument:{
              dataSource:null,//提供table的DataSource
              tableColumns:new qlrInfo_item().getTableColumns(), //提供table的字-段集 this.table.columnArray.filter(column=>{return column.tableView})
              loading:false,//table的loading状态
              currentIndex:null,
              formColumns:new qlrInfo_item().getFormColumns(), //提供Form的字段集 this.table.getFormColumns()
              formData:{},
            },
            pageParam:{
              page: 1,
              limit: 10,
            },

          }
      },
      created() {
        this.init()
      },

      methods:{
        init(){
          let parms = {};
          this.argument.loading = true;
          qlrInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });

        },
        eventHandle({type,arg}){
            switch (type) {
                case this.$eventTags.search:{
                    this.argument.loading = true;
                    let parms ={
                      queryText:arg.queryText,
                    }
                  qlrInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
                    this.argument.loading =false;
                    if(res.data.flag){
                      this.argument.dataSource = res.data.data.resultList;
                      this.$refs.pstable.setTotalSize(res.data.data.total);
                    }
                  });
                    break;
                }
                case this.$eventTags.refresh:{
                  this.$refs.local_tabs.onClose();
                  this.init();
                  break;
                }
            }
        },
        pageChange(arg){
          this.pageParam.page = arg.page;
          this.pageParam.limit = arg.limit;
          let parms = this.$refs.toolBar.getParms();
          this.argument.loading = true;
          qlrInfo_api.queryPageForManage.requestPOSTBothParam(this,{urlParam:parms,formParam:this.pageParam},res=>{
            this.argument.loading =false;
            if(res.data.flag){
              this.argument.dataSource = res.data.data.resultList;
              this.$refs.pstable.setTotalSize(res.data.data.total);
            }
          });
        },
        showData(index,arg){
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("查看信息","show",false, this.argument);
        },
        showZjdInfo(index,arg){
          this.argument.formData = arg;
          this.$refs.local_tabs.openDrawer("宅基地信息","zjdInfo_table",false,this.argument);
        },
      },

    }
</script>

<style scoped>

</style>