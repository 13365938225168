<!--
*@description：toolBar组件
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div  class="search-bar">
      <a-select
          v-model:value="type"
          placeholder="部门类型"
          class="search-item ant-col-4"
      >

        <a-select-option v-for="(item) in searchOptions" :key="item.key">
          {{ item.value }}
        </a-select-option>
      </a-select>
      <a-input class="search-item ant-col-4" v-model:value="name" placeholder="请输入部门名称" />
        <a-button class="search-item" type="dashed" shape="circle" @click="doSearch">
            <template #icon><Search /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="refresh">
            <template #icon><Reset /></template>
        </a-button>
        <a-button class="search-item" type="dashed" shape="circle" @click="addData">
          <template #icon><Add /></template>
        </a-button>
    </div>
</template>

<script>
    import Icons from "../../../../components/Icons";
    import dic_api from "../../dictionary/config/dic_api";

    export default {
      name: "department_ToolBar",
      mixins:[Icons],
      data(){
        return{
          searchOptions:[],
          name:null,
          type:null,
        }
      },
      created() {
        dic_api.queryByNameCode.requestPOSTUrlParam(this,{nameOrCode:this.$eventDict.department_type},res=>{
          if(res.data.flag){
            let data = res.data.data;
            if(data != null){
              let children = data.children;
              if(children.length > 0){
                for(let i=0;i<children.length;i++){
                  this.searchOptions.push({
                    key:children[i].dictCode,
                    value:children[i].dictName
                  })
                }
              }
            }
          }
          else{
            this.$message.error(res.data.msg)
          }
        })
      },
      methods:{
        doSearch(){
          let data = this.$GetEmitData(this.$eventTags.search,{type:this.type,name:this.name});
          this.$emit("event",data);
        },
        refresh(){
          this.type = null;
          this.name = null;
          this.$emit("event",this.$GetEmitData(this.$eventTags.refresh));
        },
        addData(){
          this.$emit("event",this.$GetEmitData(this.$eventTags.add));
        },
        getParms(){
          let parms = {
            type:this.type,
            name:this.name,
          }
          return parms;
        },
      }
    }
</script>

<style scoped>

</style>