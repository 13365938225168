<!--
* @description：遮罩层：全屏
* @author:rzl
* @date:2022/5/6 10:09
-->

<template>
  <div v-if="visible" class="loading">
    <a-space>
      <a-spin size="large" />
    </a-space>
  </div>
</template>

<script>
export default {
  name: "maskLayer",
  data(){
    return{
      visible:false,
    }
  },
  methods:{
    openShow(){
      this.visible = true;
    },
    closeShow(){
      this.visible = false;
    },
  },
}
</script>

<style scoped>
.loading{
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.5);
  display:flex;
  justify-content:center;
  align-items:center;
}
</style>