/**
 * @description：
 * @author:rzl
 * @date:2021/5/25 11:03
 **/

import APIObject from "../../../../assets/utils/APIObject";

const api={
    queryPageForManage:new APIObject("/api/zLandPlots/queryPageForManage.do"),// 宅基地申请管理-管理部门分页查询
    get:new APIObject("/api/zLandPlots/get.do"),// 查询-单条

    get_apply:new APIObject("/api/zApply/get.do"),// 宅基地申请管理-单个查询
    queryPageFinishForManage:new APIObject("/api/zApply/queryPageFinishForManage.do"),// 宅基地申请管理-管理部门分页查询（已完结的）

};

export default api;