/**
 * @description:字段配置
 * @author:rzl
 * @date:2021/5/25 11:03
 **/
import Table from "../../../../assets/tables/parts/table";
import Column from "../../../../assets/tables/parts/column";
import ColumnType from "../../../../assets/tables/parts/column_type"

export default class department_item extends Table{
    constructor() {
        super();
        this.name = "department_item";
        this.CNName = "部门信息";
        this.columnArray = [
            new Column("系统编号","id",ColumnType.String,false).setVisible(false),
            new Column("部门名称","name",ColumnType.String,true).setTableView(),
            new Column("部门类型","type",ColumnType.Dictionary,true).setTableView()
                .setDictionary("department_type"),
            new Column("备注","remarks",ColumnType.String,true).setTableView(),

        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250)];
    }
}